export default {
	data() {
		return {	}
	}, 
	methods: {
		


		/* ++++ API CALLBACK: REGISTRATION ++++ */
		cb_userHasBeenRegistered(resp){
			this.displaySpinner(false);
			this.closeAllModales();
			/* User has send his data. It must receive a SMS and I should display a modale */
			/* Storing the CRMParticipationOwner id */
			this.fieldsValues.CRMParticipationOwnerId = resp.data.CRMParticipationOwner.id; // ! only when registering. Log does not bring it.
			this.ownerID = resp.data.CRMParticipationOwner.id;
			this.userInfos.id = resp.data.CRMParticipationOwner.id;
			this.log(`The registration id is: ${this.fieldsValues.CRMParticipationOwnerId}`, 'wip');
			this.userJustRegistered = true;
			this.operations.registrationDataSent_request = true;
			/* Showing the modale for code entry */
			this.requireUserToGiveCode();
		},
		/* ---- API CALLBACK: REGISTRATION ---- */
		cb_userHasNotBeenRegistered(err){
			this.displaySpinner(false);
			this.closeAllModales();
			this.log(`Registration API error`, "alert");
			this.genericErrorManager(err);
		},
		/* ==== API CALL: REGISTRATION ==== */
		userTryToRegister(){
			this.displaySpinner(true);
			localStorage.setItem("registered", false);
			let data = this.collectRegistreringData();
			this.postToAxios(this.callConfigs.postRegistration, data);
		},










		/* ++++ API CALLBACK: SEND PASSWORD REMINDER MAIL ++++ */
		cb_passwordReplacerMailWasSent(){
			this.goto('confirmLinkSent');
			this.displaySpinner(false);
		},
		/* ---- API CALLBACK: SEND PASSWORD REMINDER MAIL ---- */
		cb_passwordReplacerMailWasNotSent(err){
			// Even if there is an error, we display the "ok" page.
			this.log(err, 'alert');
			this.goto('confirmLinkSent');
			this.displaySpinner(false);
		},
		/* ==== API CALL: SEND PASSWORD REMINDER MAIL ==== */
		sendReminderMail(type){
			this.displaySpinner(true);
			let data = { "email": this.fieldsValues.email };
			if (type === 'password'){
				this.postToAxios(this.callConfigs.postSendMailReplacePassword, data);
			}
		},

		/* When user can't remember his password at the login stage */
		userAskToResetPassword(){
			this.log("userAskToResetPassword", 'low');
			this.sendReminderMail('password');
		},

		storeCryptedIdInLocalStorage(cryptedId){
			this.log("Storage of cryptedId", 'low');
			this.cryptedId = cryptedId;
			// Store crypted data + Time To Live
			let now = new Date();
			let ttl = 30 * 24 * 60 * 60 * 1000;
			let item = {
				id: cryptedId,
				expire: now.getTime() + ttl
			};

			localStorage.setItem("cid", JSON.stringify(item));
			this.log(`Stored cryptedId: ${this.cryptedId}`, 'low');
		},









		/* ++++ API CALLBACK: REQUEST CODE TO BE SENT ++++ */
		cb_codeRequestSent(){
			this.displaySpinner(false);
			this.requireUserToGiveCode();
		},
		/* ---- API CALLBACK: REQUEST CODE TO BE SENT ---- */
		cb_codeRequestNotSent(err){
			this.displaySpinner(false);
			this.genericErrorManager(err);
		},
		/* ==== API CALL: REQUEST CODE TO BE SENT ==== */
		sendRequestForValidationCode(type="default"){
			this.log(`sendRequestForValidationCode for type ${type}`, 'function');
			this.displaySpinner(true);
			this.requestToSendCodeBy("sms", type); // type = default
		},









		/* ++++ API CALLBACK: REQUEST POSTAL CODE LIST ++++ */
		cb_formGetThePostalCodeList(data){
			this.fullListOfPostalCode = data;
		},
		/* ---- API CALLBACK: REQUEST POSTAL CODE LIST ---- */
		cb_formDontGetThePostalCodeList(err){
			this.log("Cannot retrieve postal code list", 'alert');
			this.log(err);
		},
		/* ==== API CALL: REQUEST POSTAL CODE LIST ==== */
		gatherAllWowiPostalCode(){
			this.getFromAxios(this.callConfigs.getPostalCodeDropdownList);
		},







		/* ++++ API CALL: GET UNSUBSCRIBE REF. LIST ++++ */
		getUnsubscribeReferencesListPassed(data){
			this.log(data);
			this.unsubscribeRefsList = data;
		},

		/* ---- API CALL: GET UNSUBSCRIBE REF. LIST ---- */
		getUnsubscribeReferencesListFailed(err){
			this.log(err);
		},

		/* ==== API CALL: GET UNSUBSCRIBE REF. LIST ==== */
		getUnsubscribeReferences(){
			this.getFromAxios(this.callConfigs.getUnsubscribeReferencesList);
		},





		/* ++++ API CALLBACK: REQUEST COUPON DETAILS ++++ */
		cb_couponDetailsAreFound(data){
			this.test_couponDetails(data);
			this.wowicards = this.convertApiCouponsToFormCoupons(data);
			this.updateWowiCardListWithLocalStorageData();
			this.defaultSortWowicards();
			localStorage.removeItem('registered');
			this.log(this.wowicards);
		},
		/* ---- API CALLBACK: REQUEST COUPON DETAILS ---- */
		cb_couponDetailsAreNotFound(error){
			this.log(error);
			// * this.genericErrorManager(err);
			this.iGotThisErrorFromActionApi(error, this.$t('errors.retailerListNotFound'));
		},
		/* ==== API CALL: REQUEST COUPON DETAILS ==== */
		requestCouponsDetails(){
			this.log("Requesting coupon details", 'low');
			this.getFromAxios(this.callConfigs.getCouponsDetails);
		},







/* WIP ========== START WIP 164 ========== */

		/* ++++ API CALLBACK: REQUEST PRIME DETAILS ++++ */
		cb_primesDetailsAreFound(data){
			this.log(data);
			// Creation d'un primesCardsList()


			/*
			this.test_couponDetails(data);
			*/
			/*
			this.wowicards = this.convertApiPrimesToFormPrime(data);
			this.updateWowiCardListWithLocalStorageData();
			this.defaultSortWowicards();
			localStorage.removeItem('registered');
			this.log(this.wowicards);
			*/
		},
		/* ---- API CALLBACK: REQUEST PRIME DETAILS ---- */
		cb_primesDetailsAreNotFound(error){
			this.log(error);
			this.iGotThisErrorFromActionApi(error, this.$t('errors.undefinedErrorPleaseRetry'));
		},
		/* ==== API CALL: REQUEST PRIME DETAILS ==== */
		requestPrimesDetails(){
			this.log("Requesting primes details", 'wip');
			this.getFromAxios(this.callConfigs.getPrimesDetails);
		},

/* WIP ========== END WIP 164 ========== */


/* WIP ========== START WIP 194 ========== */

		cb_orderedItemFound(data){
			this.log(data);
			this.orderedItemList = data;
		},

		cb_orderedItemNotFound(error){
			this.log(error);
			this.iGotThisErrorFromActionApi(error, this.$t('errors.undefinedErrorPleaseRetry'));
		},

		requestOrderedItemList(){
			this.log("Requesting Ordered Items List", 'wip');
			this.getFromAxios(this.callConfigs.getOrderedItemList);
		},

/* WIP ========== END WIP 194 ========== */









		/* ++++ API CALL: DELETE CURRENT USER ++++ */
		cb_userDeleted(data){
			this.log(data);
			this.log("User was deleted", 'success');
			this.log("Unlogging, cleaning behind", 'low');
			this.userWantToLogout();
		},
		/* ---- API CALL: DELETE CURRENT USER ---- */
		cb_userNotDeleted(err){
			this.log(err);
		},
		/* ==== API CALL: DELETE CURRENT USER ==== */
		deleteCurrentUser(){
			this.log("Deleting current user", 'low');
			this.getFromAxios(this.callConfigs.getUserDeleted, this.userInfos.id);
		},



		/* ++++ API CALL: GET PARTICIPATION TYPE REFERENCE ++++ */
		cb_participationTypeRefFound(data){
			this.participRefs = data;
			this.log("Refs stored in participRefs", 'low');
		},
		/* ---- API CALL: GET PARTICIPATION TYPE REFERENCE ---- */
		cb_participationTypeRefNotFound(err){
			this.log(err);
		},
		/* ==== API CALL: GET PARTICIPATION TYPE REFERENCE ==== */
		getParticipationTypeReference(){
			this.getFromAxios(this.callConfigs.getParticipationTypeRef);
		},


		/* ++++ API CALLBACK: GET RESERVED CASHBACKS DETAILS ++++ */
		cb_crmReservedCashbacksInfosFound(data){
			this.log(data, 'table');
			this.log("cb_crmReservedCashbacksInfosFound", 'todo');
			this.listOfReservedCashbacks = data;
			this.methodGetListOfAlreadyReservedCashbacksIsDone = true;
			this.checkIfRefreshDataMethodsAreBothDone();
		},
		/* ---- API CALLBACK: GET RESERVED CASHBACKS DETAILS ---- */
		cb_crmReservedCashbacksInfosNotFound(error){
			console.log(error);
		},
		/* ==== API CALLBACK: GET RESERVED CASHBACKS DETAILS ==== */
		getReservedCashbackInfo(){
			this.getFromAxios(this.callConfigs.getReservedCashbacks, this.cryptedId);
		},


		/* ++++ API CALLBACK: GET CONSUMED CASHBACKS DETAILS ++++ */
		cb_crmConsumedCashbacksInfosFound(data){
			this.log(data, 'table');
			this.log("cb_crmConsumedCashbacksInfosFound", 'todo');
			this.listOfConsumedCashbacks = data;
			this.methodGetListOfAlreadyConsumedCashbacksIsDone = true;
			this.checkIfRefreshDataMethodsAreBothDone();
		},
		/* ---- API CALLBACK: GET CONSUMED CASHBACKS DETAILS ---- */
		cb_crmConsumedCashbacksInfosNotFound(error){
			console.log(error);
		},
		/* ==== API CALLBACK: GET CONSUMED CASHBACKS DETAILS ==== */
		getConsumedCashbackInfo(){
			this.getFromAxios(this.callConfigs.getConsumedCashbacks, this.cryptedId);
		},



		/* ++++ API CALLBACK: GET ALL CASHBACKS DETAILS ++++ */
		cb_crmAllCashbacksInfosFound(data){
			/* User has not yet consumed or reserved cashbacks */
			if(!data.consumed){ data.consumed = []; }
			if(!data.reserved){ data.reserved = []; }
			this.listOfAllCashbacksStatus = data;
			this.listOfConsumedCashbacks = data.consumed;
			this.listOfReservedCashbacks = data.reserved;
			/* update flags */			
			this.cashbackDataAreReceived = true;
			this.methodGetListOfAlreadyAllCashbacksIsDone = true;
			this.methodGetListOfAlreadyReservedCashbacksIsDone = true;
			this.methodGetListOfAlreadyConsumedCashbacksIsDone = true;
			/* Continue… */
			this.checkIfRefreshDataMethodsAreBothDone();
		},
		/* ---- API CALLBACK: GET ALL CASHBACKS DETAILS ---- */
		cb_crmAllCashbacksInfosNotFound(error){
			console.log(error);
		},
		/* ==== API CALLBACK: GET ALL CASHBACKS DETAILS ==== */
		getAllCashbacksInfo(){
			this.getFromAxios(this.callConfigs.getAllCashbacks, this.cryptedId);
		},












		/* ++++ API CALLBACK: GET ALL CASHBACKS DETAILS, BEFORE A PARTICIPATION ++++ */
		cb_crmAllCashbacksInfosFoundBeforeParticipation(data){
			/* User has not yet consumed or reserved cashbacks */
			if(!data.consumed){ data.consumed = []; }
			if(!data.reserved){ data.reserved = []; }
			/* update form data about cashback use */
			this.listOfAllCashbacksStatus = data;
			this.listOfConsumedCashbacks = data.consumed;
			this.listOfReservedCashbacks = data.reserved;
			/* update flags */			
			this.cashbackDataBeforeParticipationAreReceived = true;
			/* Continue… */
			this.checkIfDataBeforParticipationAreBothArrived();
		},
		/* ---- API CALLBACK: GET ALL CASHBACKS DETAILS, BEFORE A PARTICIPATION ---- */
		cb_crmAllCashbacksInfosNotFoundBeforeParticipation(error){
			console.log(error);
		},
		/* ==== API CALLBACK: GET ALL CASHBACKS DETAILS, BEFORE A PARTICIPATION ==== */
		getUsedCashbacksBeforeParticipation(){
			this.getFromAxios(this.callConfigs.getAllCashbacksBeforeParticipation, this.cryptedId);
		},



		/* ++++ API CALL: GET PARTICIPATION TYPE REFERENCE, BEFORE A PARTICIPATION ++++ */
		cb_participationTypeRefFoundBeforeParticipation(data){
			this.participRefs = data;
			this.log("Refs stored in participRefs", 'low');
			/* update flags */			
			this.participRefsBeforeParticipationAreReceived = true;
			/* Continue… */
			this.checkIfDataBeforParticipationAreBothArrived();
		},
		/* ---- API CALL: GET PARTICIPATION TYPE REFERENCE, BEFORE A PARTICIPATION ---- */
		cb_participationTypeRefNotFoundBeforeParticipation(err){
			this.log(err);
		},
		/* ==== API CALL: GET PARTICIPATION TYPE REFERENCE, BEFORE A PARTICIPATION ==== */
		getParticipationTypeReferenceBeforeParticipation(){
			this.getFromAxios(this.callConfigs.getParticipationTypeRefBeforeParticipation);
		},









		/* ++++ API CALLBACK: GET OWNER DETAILS ++++ */
		cb_crmOwnerDetailsAreFound(data){
			this.displaySpinner(false); 

			// Check if user has status 30
			if (parseInt(data.status) === 30){
				this.log("User is banned", 'red');
				this.userHaveStatus30();
			}else{
				this.log("User is not banned", 'green');
				this.storeReceivedUserDataInStandardVariableName(data);
				this.updatingFormWithStoredUserData();
			// special case: reset password
			// in case i reset the password, i don't have "both" methods.
				this.methodGetOwnerDetailsIsDone = true;
				this.checkIfRefreshDataMethodsAreBothDone();	
			}

		},
		/* ---- API CALLBACK: GET OWNER DETAILS ---- */
		cb_crmOwnerDetailsAreNotFound(err){
			this.log("cb_crmOwnerDetailsAreNotFound", 'function');
			// ! why does nothing works here if i send a wrong crmPartOwnerKey via the url while resetting password?
			// ! see in the apicall manager for "get"
			this.displaySpinner(false); 
			this.log(err, "alert");
			if(err.response.status === 404){
				// Owner not found (probably deleted).
				// this.displayModaleMessages("Attention", [this.$t('errors.userDoNotExist')], "alert");
				// this.clearLocalStorage();
				this.cleanAndForceReloadOnce();
			}else if(err.response.status === 422){
				this.displayModaleMessages("Attention", [this.$t('errors.wrongUserKey')], "alert");
			}

		},
		/* ==== API CALLBACK: GET OWNER DETAILS ==== */
		getOwnerDetails(){
			this.log("getOwnerDetails", 'function');

			if(this.cryptedId){
				this.displaySpinner(true);
				this.log(`getOwnerDetails will do a request with value ${this.cryptedId}`, "low");
				try{
					this.getFromAxios(this.callConfigs.getCRMOwnerDetails, this.cryptedId);
				}catch(e){
					console.log(e);
				}
			}else{
				this.log(`getOwnerDetails received a falsy value for cryptedId: "${this.cryptedId}".`, "alert");
			}
		},

		cleanAndForceReloadOnce(){
			this.log("Clean and force reload", 'low');
			this.userWantToLogout();
		},


		/* ++++ API CALLBACK: GET CONSUMED COUPONS CARDS LIST ++++ */
		cb_formGetTheConsumedCouponsList(data){
			this.consumedCards = [];
			if (data?.length > 0){
				data.forEach(item =>{
					let num = parseInt(item.idleadcoupon);
					this.consumedCards.push(num);
				});
			}
			
			this.log("Consumed coupon cards list was updated: ", "low");
			this.log(this.consumedCards , "table");

			this.removeActiveStatusOfConsumedCards();
			this.methodGetListOfAlreadyConsumedCouponsIsDone = true;
			this.checkIfRefreshDataMethodsAreBothDone();
		},
			/* ---- API CALLBACK: GET CONSUMED CARDS LIST ---- */
		cb_formDontGetTheConsumedCouponsList(){
			this.log("cb_formDontGetTheConsumedCouponsList", "todo");
		},
			/* ==== API CALL: GET CONSUMED CARDS LIST ==== */
		getListOfAlreadyConsumedCoupons(){
			this.log("getListOfAlreadyConsumedCoupons", "function");
			if(this.cryptedId){
				this.getFromAxios(this.callConfigs.getAlreadyConsumedCoupons, this.cryptedId);
			}		
		},








		/* ++++ API CALLBACK: GET CONSUMED PRIME CARDS LIST ++++ */
		cb_formGetTheConsumedPrimesList(data){
			this.consumedCards = [];


			if (data?.length > 0){
				data.forEach(item =>{
					let num = parseInt(item.idpurchaseorderitem);
					this.consumedCards.push(num);
				});
			}

			
			this.log("Consumed prime cards list was updated: ", "low");
			this.log(this.consumedCards , "table");

			this.removeActiveStatusOfConsumedCards();
			this.methodGetListOfAlreadyConsumedPrimesIsDone = true;
			this.checkIfRefreshDataMethodsAreBothDone();
		},
			/* ---- API CALLBACK: GET CONSUMED PRIME CARDS LIST ---- */
		cb_formDontGetTheConsumedPrimesList(){
			this.log("cb_formDontGetTheConsumedPrimesList", "todo");
		},
			/* ==== API CALL: GET CONSUMED PRIME CARDS LIST ==== */
		getListOfAlreadyConsumedPrimes(){
			this.log("getListOfAlreadyConsumedPrimes", "function");
			if(this.cryptedId){
				this.getFromAxios(this.callConfigs.getAlreadyConsumedPrimes, this.cryptedId);
			}		
		},







		/* ++++ API CALLBACK: SEND CODE FOR VALIDATION ++++ */
		cb_codeFromUserIsAccepted(resp){
			this.log(`cb_codeFromUserIsAccepted`, 'function');
			this.log(resp);
			this.log(resp.data);
			this.userAlreadySentThisNumberOfValidationCode = 0;
			this.lastValidCodeSent = this.lastCodeSent;
			this.closeAllModales();
			
			this.continuePreviousOperation(resp.data);

			// again, to be sure
			if(this.userAlreadySentThisNumberOfValidationCode >= 3){
				this.isResendCodeButtonVisible = true;
			}
		},
		/* ---- API CALLBACK: SEND CODE FOR VALIDATION ---- */
		cb_codeFromUserIsNotAccepted(err){
			this.displaySpinner(false);
			this.log(`cb_codeFromUserIsNotAccepted`, 'alert');
			this.codeErrorIsVisible = true;
			this.log(err);
		},
		/* ==== API CALLBACK: SEND CODE FOR VALIDATION ==== */
		userTryToSendACode(){
			this.displaySpinner(true);
			this.userAlreadySentThisNumberOfValidationCode += 1;
			if(this.userAlreadySentThisNumberOfValidationCode >= 3){
				this.isResendCodeButtonVisible = true;
			}
			let data = this.collectCodeToSendData();
			this.log("Code info collected", "info");
			this.log(data);
			this.lastCodeSent = data.code;
			this.postToAxios(this.callConfigs.postSMSCode, data);
			/* For next steps, see cb_codeFromUserIsAccepted and cb_codeFromUserIsNotAccepted */
		},





		siteCouponWithPrintRequest(resp){
			this.operations.printCoupons_request = false;
			this.log(resp.data.couponLink);
			this.receivedLink = resp.data.couponLink;
			this.goto('iframe');
		},

		siteCouponWithMailRequest(){
			this.operations.mailCoupons_request = false;
			pixie('event', 'Recevoir_coupon');
			fbq('track', 'Lead');
			this.goto('confirmation');
		},


		/* ++++ API CALLBACK: WOWI PARTICIPATION ++++ */
		wowiParticipationSuccessful(resp){
			// ECOUPONS
			if(this.website === 'ecoupons'){
				this.log("This is a ecoupons website", 'low');
				if(this.operations.printCoupons_request){
					this.log("…with print request", 'low');
					this.siteCouponWithPrintRequest(resp);
				}
				if(this.operations.mailCoupons_request){
					this.log("…with mail request", 'low');
					this.siteCouponWithMailRequest();
				}
			}
			
			// CASHBACKS
			if(this.website === 'cashbacks'){
				this.log("This is a cashback website", 'low');
				if(this.participationCode === "PART" || this.participationCode === "REPART"){
					this.goto('confirmCashback');
				}
				if(this.participationCode === "RESERVAT"){
					this.goto('confirmReservation');
				}
			}

			// PRIMES
			if(this.website === 'primes'){
				this.log("This is a prime website", 'alert');
				this.operations.primes_request = false;
				this.goto('confirmPrime');
			}

			this.displaySpinner(false);
		},
		/* ---- API CALLBACK: WOWI PARTICIPATION ---- */
		wowiParticipationFailed(err){
			// precaution ?
			this.operations.mailCoupons_request = false;
			this.operations.printCoupons_request = false;
			// 
			this.displaySpinner(false);
			this.log(err, "alert");
			/* * this.genericErrorManager(err); */
			this.iGotThisErrorFromActionApi(err, this.$t('errors.participationNotSent'));
		},
		/* ==== API CALLBACK: WOWI PARTICIPATION COUPONS ==== */
		/* Todo factoriser : => requestToSendParticipation */
		requestToSendCouponsFinallyAccepted(){
			this.displaySpinner(true);
			this.postToAxios(this.callConfigs.postWowiParticipation, this.getFreshDataToSend());
		},
		requestToSendPrimesFinallyAccepted(){
			this.displaySpinner(true);
			this.operations.sendingPrimesInProgress = false;
			this.codeForParticipation = "PROMOCODES"; // doublon for security
			this.postToAxios(this.callConfigs.postWowiParticipation, this.getFreshDataToSend());
		},




		requestToSendPrimesAccepted(){
			/* Dubbed "checkIfUserHasSignedTheGeneralConditionsAgain" */
			if( this.userInfos.generalCondition === 1){
				this.log("Consent to CG has been given", 'green');
				this.requestToSendPrimesFinallyAccepted();
			}else{
				this.log("Consent to CG is not given yet", 'red');
				this.goto('newConsent');
			}
		},

		requestToSendCouponsAccepted(){
			/* Dubbed "checkIfUserHasSignedTheGeneralConditionsAgain" */
			if( this.userInfos.generalCondition === 1){
				this.log("Consent to CG has been given", 'green');
				this.requestToSendCouponsFinallyAccepted();
			}else{
				this.log("Consent to CG is not given yet", 'red');
				this.goto('newConsent');
			}
		},

		requestToSendFormParticipationAccepted(){
			if( this.userInfos.generalCondition === 1){
				this.log("Consent to CG has been given", 'green');
				this.requestToSendFormParticipationFinallyAccepted();
			}else{
				this.log("Consent to CG is not given yet", 'red');
				this.goto('newConsent');
			}
		},

		requestToSendFormParticipationFinallyAccepted(){
			this.log("APICallbacksCRM > requestToSendFormParticipationAccepted()", 'function');
			this.displaySpinner(true); // If it wasn't the case yet…
			
			// IF reservation, I can send the participation right now…
			if(this.participationCode === "RESERVAT"){
				this.log("I'm reserving", 'low');
				this.readyToFinallySendParticipation();
			}

			// if null, PART
			if (!this.participationCode){
				this.participationCode = "PART";
			}

			// IF order, I must ask more info first.
			if(this.participationCode !== "RESERVAT"){
				this.log(`I'm not reserving, I'm ${this.participationCode}icipating`, 'low');

				// CASHBACKS
				if(this.website === 'cashbacks'){
					if(this.operations.sendingCashbackInProgress){
						this.log("sendingCashbackInProgress is true", 'green');
						this.log("Going directly to the composition and api call…", 'red');
					// L'utilisateur a déjà rempli le formulaire
						this.operations.sendingCashbackInProgress = false;
						this.readyToFinallySendParticipation();
					}else{
						this.log("sendingCashbackInProgress is false", 'low');
						this.log("Current active operations: ", 'low');
						this.log(this.operations, 'table');
					// L'utilisateur n'a pas encore entré toutes les données

					// ! This is the necessary problematic redirection. What if I don't do it?
						this.log("Is there a missing condition? Or isn't it here, as it is missing?", 'red');
						this.goto('summaryCashback');
					}
				}

				// PRIMES
				if(this.website === 'primes'){
					this.log("Website is prime, so special treatment");
					if(this.operations.sendingPrimesInProgress){
						this.log("operation sending Prime in Progress", 'green');
						this.operations.sendingPrimesInProgress = false;
						this.readyToFinallySendParticipation();
					}else{
						this.log("no operation sending Prime in Progress", 'red');
						this.log("No data collected yet", 'low');
						this.userTryToSendHisPrimes();
					}
				}


			}
			
		},

		readyToFinallySendParticipation(){
			this.log("readyToFinallySendParticipation", 'function');
			let data = this.composeCrmParticipation();
			this.log("data: ", 'wip');
			this.log(data);
			/* List to display in the confirmation page */
			if(this.participationCode === "RESERVAT"){
				this.justReserved = data.ProofOfPurchaseDetailConsumer; // stored for vue display
			}
			this.postToAxios(this.callConfigs.postWowiParticipation, data);
		},




		getFreshDataToSend(){
			let data = this.composeCrmParticipation();

			if (this.userJustRegistered){
				data = this.renderCodeNonRequired(data);
				this.userJustRegistered = false;
			}
			return data;
		},

		renderCodeNonRequired(data){
			data.code = null;
			data.isCodeRequired = 0;
			this.userJustRegistered = false;
			return data;
		},








		/* ++++ API CALLBACK: UPDATE CREDENTIALS ++++ */
		cb_credentialsHaveBeenUpdated(){
			this.log(`The cryptedId from localStorage got in cb_credentialsHaveBeenUpdated() is: ${this.cryptedId}`, 'wip');

			if(!this.cryptedId){

				// Looking in the url for some infos…
				let urlParams = new URLSearchParams(window.location.search);

				// crmPartOwnKey dans l'url
				if (urlParams.has('ope') && urlParams.has('crmPartOwnKey')){
					
					this.crmPartOwnKey = encodeURIComponent(urlParams.get('crmPartOwnKey'));
					this.cryptedId = this.crmPartOwnKey;

					this.log(`The new criptedId is: ${this.cryptedId}`, 'wip');

				}
			}

			this.displaySpinner(false);
			this.closeAllModales();
			/* --------------- */
			this.reloadOffersPage = false;	
			this.reloadProfilePage = true;
			// ! …but there should be a redirection to general profile page.

			this.operations.refreshUserData_request = true;
			this.continuePreviousOperation();
		},
		/* ---- API CALLBACK: UPDATE CREDENTIALS ---- */
		cb_credentialsHaveNotBeenUpdated(err){
			this.displaySpinner(false);
			this.log(err);
			this.genericErrorManager(err);
		},
		/* ==== API CALLBACK: UPDATE CREDENTIALS ==== */
		updateCredentials(){
			this.displaySpinner(true);
			this.operations.updateCredentials_request = true;
			let cred = this.collectCredentials();
			this.log(`mail : ${cred.email}, phoneNumber1: ${cred.phoneNumber1}, PWD: ${cred.PWD}`, 'wip');
			this.log(cred, 'table');
			if(!!cred.email || !!cred.phoneNumber1 || !!cred.PWD){
				// If sensitive data, ask code first.
				this.theCodeMustBeSentWithNextRequest = true;
				this.log("Sensitive fields that does require code validation", 'low');
				if(cred.phoneNumber1){
					this.requestToSendCodeBy('mail');
				}else{
					this.sendRequestForValidationCode('credentials-changed');
				}
			}else{
				// No sensitive data, I can immediately process the collect.
				this.log("User try to change innocuous fields that does not require code validation", 'low');
				this.collectCredentialAndSaveThem();
			}		
		},
		/* ==== API CALLBACK: UPDATE CREDENTIALS ==== */
		collectCredentialAndSaveThem(){
			this.operations.updateCredentials_request = false;
			let data = this.collectCredentials();

			if(data.email){
				data.email = data.email.toLowerCase();
			}

			if(this.theCodeMustBeSentWithNextRequest){
				data.code = this.lastCodeSent;
				this.theCodeMustBeSentWithNextRequest = false; // reset flag
			}
			this.putIntoAxios(this.callConfigs.putCredentials, data);
		},
		/* ==== API CALLBACK: UPDATE CREDENTIALS ==== */
		storeNewPassword(){
			let data = {
				idCRMParticipationOwner: this.userInfos.id,
				PWD:  this.fieldsValues.password,
				code: this.fieldsValues.code
			};
			this.putIntoAxios(this.callConfigs.putCredentials, data);
		},










		/* ++++ API CALLBACK: LOGIN ++++ */
		cb_loginAndPassAreRecognized(resp){
			this.log("All is fine — My tax inspector", 'green');
			/* Returned value contains the crypted id of the partOwner */
			let cryptedId = resp.data;
			this.log(`Callback returned cryptedId: ${cryptedId}`, 'low');
			this.storeCryptedIdInLocalStorage(cryptedId);
			// If pending coupon operations
			// TODO I should check too if cashback operation where in progress !? I think it works elsewhere…
			if( this.operations.mailCoupons_request || this.operations.printCoupons_request ){
				this.log("User logged with coupons operation in progress.", 'low');
				this.userLoggedWithCoupons = true;
			}else{
				this.log("User logged without coupons operation in progress.", 'low');
				this.log("Should I look for cashback operations?", 'todo');
			}
			this.operations.login_request = true;
			this.continuePreviousOperation();
		},
		/* ---- API CALLBACK: LOGIN ---- */
		cb_loginAndPassAreNotRecognized(err){
			this.log("cb_loginAndPassAreNotRecognized", 'function');

			// this.log(err);
			// this.log(err.response);
			this.log(`Error ${err.response.status}: ${err.response.statusText}, ${err.response.data}`, 'error');

			if (err.response.data === "Profile blocked"){
				this.log("User blocked", 'red');
				this.closeAllModales();
				this.userHaveStatus30();
			}else{
				this.log("Your error is in another castle", 'red');
				this.displaySpinner(false);
				this.loginErrorIsVisible = true;
			}


		},
		/* ==== API CALL: LOGIN ==== */
		userTryToLogin(){
			this.log("userTryToLogin()", 'function');
			this.reloadOffersPage = true; // for later use
			this.displaySpinner(true);
			let data = this.collectLoginData();
			data.email = data.email.toLowerCase();
			this.postToAxios(this.callConfigs.postLoginAndPass, data);
			/* For next step, sees cb_loginAndPassAreRecognized() or cb_loginAndPassAreNotRecognized() */
		},









		/* ++++ API CALLBACK: USER LOGGED by FORM ++++ */
		cb_userIsLoggedByForm(resp){
			let cryptedId = resp.data;
			this.log(`Returned CryptedId by formLogUser(): ${cryptedId}`, 'low');
			this.storeCryptedIdInLocalStorage(cryptedId);
			this.log(`Stored cryptedId: ${this.cryptedId}`, 'green');
			this.continuePreviousOperation();
		},
		/* ---- API CALLBACK: USER LOGGED by FORM ---- */
		cb_userIsNotLoggedByForm(err){
			this.log("A problem occured in cb_userIsNotLoggedByForm()", "alert");
			this.log(err);
		},
		/* ==== API CALL: USER LOGGED by FORM ==== */
		formLogUser(){
			this.log("formLogUser", 'function');
			let data = this.collectLoginData();
			this.postToAxios(this.callConfigs.postLoginAndPassByForm, data);
		},








		/* ++++ API CALLBACK: RECEIVE CODE BY MAIL/SMS ++++ */
		cb_codeWasSentByMean(){
			this.log("cb_codeWasSentByMean", 'todo');
			this.requireUserToGiveCode();
			this.displaySpinner(false);
		},
		/* ---- API CALLBACK: RECEIVE CODE BY MAIL/SMS ---- */
		cb_codeWasNotSentByMean(err){
			this.log("cb_codeWasNotSentByMean", 'todo');
			this.displaySpinner(false);
			this.genericErrorManager(err);
		},
		/* ==== API CALL: RECEIVE CODE BY MAIL/SMS ==== */
		requestToSendCodeBy(mean, operation="default"){

			// mean can be "mail" or "sms"
			this.displaySpinner(true);
			let data = {
				"idCRMParticipationOwner": this.userInfos.id,
				"operation": operation,
				"communicationType": mean
			};
			this.log(`The collected info to request a code:`, "title");
			this.log(data, 'table');
			this.postToAxios(this.callConfigs.postSendCodeByMean, data);
		},



		/* ++++ API CALLBACK: INFO ABOUT DEVICE ++++ */
		cb_theDeviceAnswerIsOk(resp){
			// device is known, or not, I can send the participation without code
			this.log(resp.data);
			if(resp.data){
				this.log("Device is known, no code required", 'low');
				this.continuePreviousOperation(); 
			}else{
				this.log("Device is not known, I must ask for a confirmation code", 'low');
				this.sendRequestForValidationCode('email-and-print');
			}			
		},
		/* ---- API CALLBACK: INFO ABOUT DEVICE ---- */
		cb_theDeviceAnswerIsNotOk(err){
			this.log("cb_theDeviceAnswerIsNotOk", 'alert');
			this.displaySpinner(false);
			this.genericErrorManager(err);
		},
		/* ==== API CALL: INFO ABOUT DEVICE ==== */
		requestInfoAboutDevice(){
			this.log(`requestInfoAboutDevice()`, 'function');
			let data = {
				"idCRMParticipationOwner": this.userInfos.id,
			}
			this.postToAxios(this.callConfigs.postIsDeviceKnown, data);
		},

	}
}