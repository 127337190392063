import TestGenericMethods from "./TestGenericMethods";

export default {

	mixins: [
		TestGenericMethods,
		],

	data() {return {	}},

	methods: {

		fakingWrongDataInAllowedCountries(data){
			/* Uncomment a line in checkCouponDetailsValidity() to run this */
			this.log("Fake data", 'alert');
			data[0].code = null;
			data[0].countrytypereference.id = null;
			delete data[1].description;
			return data;
		},

		checkAllowedCountriesValidity(data){
			/* ! Uncomment this one to test with transformed data */
			// data = this.fakingWrongDataInAllowedCountries(data);

			let isCountryListValid = false;
			let comment = 'no comment';

			/* Does data exist? */
			if(!data){
				isCountryListValid = false;
				comment = `"Allowed Countries" route doesn't send any data`;
			}
			/* If it exist… */
			if(data){
				// Does ONE coupon have all its keys? and values? and sub-keys and sub-values ?
				let testOfEachCoupon = [];
				data.forEach(country => {
					this.log(`Testing country ${country.id} - ${country.description}`, 'low');
					let areCountryBaseKeysValid = this.isAllBaseRequiredKeysPresent(country, ['id', 'code', 'description', 'countrytypereference']);
					let areCoutryTypeReferenceKeysValid = this.isAllBaseRequiredKeysPresent(country.countrytypereference, ['code', 'id']);
					testOfEachCoupon.push(areCountryBaseKeysValid && areCoutryTypeReferenceKeysValid);
				});
				if(testOfEachCoupon.includes(false)){
					isCountryListValid = false;
					comment = `There was problem with key(s) or value(s) in "Allowed Countries" check.`;
				}else{
					isCountryListValid = true;
					comment = `All country tested are OK`;
				}
			}
			return {'isCountryListValid': isCountryListValid, 'comment': comment}
		},

		test_allowedCountries(data){
			if ( this.isCustomConsoleAllowed ){
				this.log("Testing allowed countries", 'testOpen');
				let testResult = this.checkAllowedCountriesValidity(data);
				this.log("", 'testClose');
				this.log(testResult.comment, testResult.isCountryListValid ? 'green':'error' );
			}
		}
	}
}
