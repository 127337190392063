export default {
	data () {
		return {
			isAllMainRequestDone: false,
			codeForParticipation: null,
		}
	},
	methods: {

		 /* ! The operation that require an API request must not call continuePreviousOperation(). The callback success must. */

		// CONTINUE IDENTIFICATION (log, registration) OPERATIONS
		continue_LoginRequest(){
			this.log("continue_LoginRequest", 'step');
			this.operations.login_request = false;
			this.reloadOffersPage = true;
			this.operations.refreshUserData_request = true;
			this.log(`cryptedId is: ${this.cryptedId}`, 'low');
			localStorage.setItem("registered", true); // must be here too, because you don't register every time you log in
			this.userIsLogged = true; // to allow logged UI
			this.menuOpen = false;
			this.turnThisModaleVisibility('login', false);
			this.continuePreviousOperation();
		},

		continue_RegistrationDataSent(){
			this.log("continue_RegistrationDataSent", 'step');
			this.userIsLogged = true;
			this.operations.registrationDataSent_request = false;
			this.operations.confirmRegistration_request = true;
			this.formLogUser(); // API => delay
		},

		continue_ConfirmRegistrationRequest(){
			this.log("continue_ConfirmRegistrationRequest", 'step');
			this.log("User just registered himself", 'low');
			this.operations.confirmRegistration_request = false;
			this.operations.login_request = true;
			localStorage.setItem("registered", true);
			/* ! Pixie */
			pixie('event', 'Inscription');
			fbq('track', 'CompleteRegistration');
			/* ! End Pixie */
			this.continuePreviousOperation();
		},
		continue_ResetPasswordRequest(){
			this.log("continue_ResetPasswordRequest", 'step');
			this.log("User typed his password, got the code modale, sent the code", 'low');
			this.operations.resetPassword_request = false;
			this.operations.login_request = true;
			this.storeNewPassword();
		},
		continue_UpdateCredentials(){
			this.log("continue_UpdateCredentials", 'step');
			// For sensible data, i must get the code too.
			this.collectCredentialAndSaveThem();
		},
		continue_RefreshUserData(){
			this.log("continue_RefreshUserData", 'step');
			this.operations.refreshUserData_request = false;

			this.getOwnerDetails();
			this.log(`This website is ${this.website}`, 'low');
			if( this.website === 'ecoupons' ){
				this.getListOfAlreadyConsumedCoupons(); // => stored in this.consumedCards
			}
			if( this.website === 'cashbacks' ){
				this.getAllCashbacksInfo();
			}
			if( this.website === 'primes' ){
				this.getListOfAlreadyConsumedPrimes();
			}
			this.checkIfRefreshDataMethodsAreBothDone();
		},

		// CONTINUE COUPON OPERATIONS
		continue_MailCoupons(){
			this.log("continue_MailCoupons", 'step');
			this.participationCode = "HCDCPRINT";
			this.requestToSendCouponsAccepted();
		},
		continue_PrintCoupons(){
			this.log("continue_PrintCoupons", 'step');
			this.participationCode = "HCDCPRINT";
			this.requestToSendCouponsAccepted();
		},

		// CONTINUE CASHBACK OPERATIONS
		continue_CashbackReservation(){
			this.log("continue_CashbackReservation", 'step');
			this.requestToSendFormParticipationAccepted();	
		},
		continue_CashbackOrder(){
			/* * Obsolete */
			this.log("If this show, this 'obsolete' function is still called somewhere", 'alert');
			// this.goto('summaryCashback');
		},




		// STARTING POINT: CONTINUEPREVIOUSOPERATION
		displayOperationsStatus(){
			let operationsStatus = {};
			for (let key in this.operations){
				operationsStatus[key] = this.operations[key];
			}
			this.log(operationsStatus, 'table');
		},


		continuePreviousOperation(data){
			this.log("continuePreviousOperation", 'function');
			this.displayOperationsStatus();
			if(data){
				this.log("I received some data:", 'wip');
				this.log(data);
			}
			if(this.operations.registrationDataSent_request){
				this.log("registrationDataSent_request", 'req');
				this.continue_RegistrationDataSent();
			}
			else if(this.operations.confirmRegistration_request){
				this.log("confirmRegistration_request", 'req');
				this.continue_ConfirmRegistrationRequest();
			}
			else if(this.operations.resetPassword_request){
				this.log("resetPassword_request", 'req');
				this.continue_ResetPasswordRequest();
			}
			else if(this.operations.login_request){
				this.log("login_request", 'req');
				this.continue_LoginRequest();
			}
			else if(this.operations.updateCredentials_request){
				this.log("updateCredentials_request", 'req');
				this.continue_UpdateCredentials();
			}
			else if(this.operations.refreshUserData_request){
				this.log("Update user info and refresh lists", 'req');
				this.continue_RefreshUserData();
			}
			else{
				this.log("No current 'wowi' request detected", 'info');
				this.log("Ready if there is coupons to print", 'low');
				this.isAllMainRequestDone = true;
			}

			// ! THERE COULD BE OPERATION IN PROGRESS WHILE I REACH HERE.
			// ! I MUST FINISH ALL PREVIOUS (API) TASK BEFORE RUNNING THIS

			if ( this.isAllMainRequestDone ){
				this.log("All wowi quest done => check website", 'wip');
				this.isAllMainRequestDone = false;
				this.whatIsCurrentWebsite();
			}else{
				this.log("There is still main quests pending…", 'warning');
			}
		},

		whatIsCurrentWebsite(){
			this.log("whatIsCurrentWebsite", 'function');
			if (this.website === 'ecoupons'){
				this.log("Continuing ecoupons operations", 'low');
				this.continueCouponsOperations();
			}
			if (this.website === 'cashbacks'){
				this.log("Continuing cashbacks operations", 'low');
				this.continueCashbacksOperations();
			}
			if (this.website === 'primes'){
				this.log("Continuing primes operations", 'low');
				this.continuePrimeOperations();
			}
			if (this.website === 'portail'){
				this.log("Continuing portail operations", 'low');
				this.continuePortailOperations();
			}
		},




		continuePortailOperations(){
			this.log("continuePortailOperations", 'function');
			if( this.operations.userChangingPassword_request ){
				this.log("userChangingPassword_request ?", 'sep');
				this.displaySpinner(false);
				this.goto('changePassword');
			}else{
				this.goto('wowi');
			}
		},

		continuePrimeOperations(){
			this.log("continuePrimeOperations", 'function');
			this.log(`state of operations.primes_request: ${this.operations.primes_request}`, 'wip');
			
			if( this.operations.userChangingPassword_request ){
				this.log("userChangingPassword_request ?", 'sep');
				this.displaySpinner(false);
				this.goto('changePassword');

			}else if(this.operations.primes_request){
				this.log("User asked for a promocode", 'wip');
				this.participationCode = "PROMOCODES";

				if(this.operations.userAskedPrimeOrder){
					this.log("User has asked Prime Order", 'wip');
					this.operations.userAskedPrimeOrder = false;
					this.loggedUserTryToReceivePrimes();

				}else{
					this.log("User has not asked Prime Order or it is already DONE", 'wip');
					// So, send participation
					this.requestToSendFormParticipationAccepted();
				}

				// lancer la procédure pour demander un code
			}else{
				this.goto('wowi');
			}
			
		},


		// SPECIAL CASHBACK
		continueCashbacksOperations(){

			this.log("continueCashbacksOperations", 'function');


			if( this.operations.userChangingPassword_request ){
				this.log("userChangingPassword_request ?", 'sep');
				this.displaySpinner(false);
				this.goto('changePassword');

			}else if( this.operations.userAskedCashbacksReservation ){
				this.log("Condition this.operations.userAskedCashbacksReservation", 'low');
				this.operations.userAskedCashbacksReservation = false;
				this.codeForParticipation = "RESERVAT";
				this.prepareCashbackParticipation();
				// * this.continue_CashbackReservation();
			} else if( this.operations.userAskedCashbacksOrder ){
				this.log("Condition this.operations.userAskedCashbacksOrder", 'low');
				this.operations.userAskedCashbacksOrder = false;
				this.codeForParticipation = this.reparticipation ? "REPART" : "PART";
				this.prepareCashbackParticipation();
				// * this.continue_CashbackOrder();
			} else if( this.operations.sendingCashbackInProgress ){
				this.log("Condition this.operations.sendingCashbackInProgress", 'low');
				this.codeForParticipation = this.reparticipation ? "REPART" : "PART";
				this.prepareCashbackParticipation();
			} else {
				// User is not sending a participation
				if(this.reloadOffersPage){
					this.goto('wowi');
				}else if(this.reloadProfilePage){
					this.goto('profile');
				}else{
					this.log("Special case", "wip");
					// ???
					this.goto('wowi');
				}
				this.displaySpinner(false);
			}
		},

		// SPECIAL COUPONS
		continueCouponsOperations(){
			this.log("continueCouponsOperations", 'function');
			if( this.userLoggedWithCoupons ){
				this.log("userLoggedWithCoupons ?", 'sep');
				this.userLoggedWithCoupons = false;
				this.loggedUserTryToPrintCoupons();
			}else if( this.operations.userChangingPassword_request ){
				this.log("userChangingPassword_request ?", 'sep');
				this.displaySpinner(false);
				this.goto('changePassword');
			}else if(this.operations.printCoupons_request){
				this.log("printCoupons_request", 'req');
				this.continue_PrintCoupons();
			}else if(this.operations.mailCoupons_request){
				this.log("mailCoupons_request", 'req');
				this.continue_MailCoupons();
			}else if(this.operations.endingContinue_request){
				this.log("END of CONTINUES…");
				this.operations.endingContinue_request = false;
				this.goto('wowi');
				this.displaySpinner(false);
			}else {
				this.goto('wowi');
				this.displaySpinner(false);
			}
		},



	},
}
