export default {
	data(){
		return {}
	},

	computed: {},

	methods: {

		/* place Xandr Universal Pixel Initialization in HEAD */
		placeXandrUniversalPixelInHead(){
			let script = document.createElement('script');
			script.innerHTML = `!function(e,i){if(!e.pixie){var n=e.pixie=function(e,i,a){n.actionQueue.push({action:e,actionValue:i,params:a})};n.actionQueue=[];var a=i.createElement("script");a.async=!0,a.src="//acdn.adnxs.com/dmp/up/pixie.js";var t=i.getElementsByTagName("head")[0];t.insertBefore(a,t.firstChild)}}(window,document);
			pixie('init', '2fff47f7-9eb1-4752-b70c-8aa8345974a2');`; 
			let noscript = document.createElement('noscript');
			noscript.innerHTML = `<img src="https://ib.adnxs.com/pixie?pi=2fff47f7-9eb1-4752-b70c-8aa8345974a2&e=PageView&script=0" width="1" height="1" style="display:none"/>`;
			document.head.appendChild(script);
			document.body.prepend(noscript);
		},

		/* place Xandr Universal Pixel Initialization in BODY */
		/* * not used
		placeXandrUniversalPixelInBody(){
			let script = document.createElement('script');
			script.innerHTML = `!function(e,i){if(!e.pixie){var n=e.pixie=function(e,i,a){n.actionQueue.push({action:e,actionValue:i,params:a})};n.actionQueue=[];var a=i.createElement("script");a.async=!0,a.src="//acdn.adnxs.com/dmp/up/pixie.js";var t=i.getElementsByTagName("head")[0];t.insertBefore(a,t.firstChild)}}(window,document);
			pixie('init', 'e6d92a57-7460-4489-8c5a-696d4e6582a2');`; 
			let noscript = document.createElement('noscript');
			noscript.innerHTML = `<img src="https://ib.adnxs.com/pixie?pi=e6d92a57-7460-4489-8c5a-696d4e6582a2&e=LandingPage&script=0" width="1" height="1" style="display:none"/>`;
			document.body.prepend(noscript);
			document.body.appendChild(script);
		},
		*/

	},

	beforeMount(){
		this.placeXandrUniversalPixelInHead();
		// this.placeXandrUniversalPixelInBody();
	},
}