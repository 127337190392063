export default {

	data() {return {	}},

	methods: {

		isAllBaseRequiredKeysPresent(obj, keyList){
			let thisObjectHasItsKeys = true;
			keyList.forEach(key => {
				if(Object.prototype.hasOwnProperty.call(obj, key) && obj[key] !== null && obj[key] !== undefined){
					// everything is probably fine
				}else{
					thisObjectHasItsKeys = false;
					this.log(`Problem: ${Object.prototype.hasOwnProperty.call(obj, key) ? `Key "${key}" is present but with value: ${obj[key]}` : `Key "${key}" is missing`}.`, 'red');
				}
			});
			return thisObjectHasItsKeys;
		},

	}
}