<template>
	<div class="gdpr-banner" :class="{visible : amIVisible}">

		<div class="gdpr-title">{{$t('gdpr.title')}}</div>

		<div v-if="$i18n.locale ==='fr_BE' || $i18n.locale ==='fr_LU'">
			<p>Les informations à caractère personnel recueillies sur le présent formulaire font l’objet d’un traitement informatique par <span class="client">{{client}}</span>, responsable de traitement, situé <span class="client">{{clientAdress}}</span>, afin d’assumer le traitement de cette opération promotionnelle.
			</p>

			<p>Ces données sont destinées aux services marketing de {{client}} et sont conservées pendant une durée d’un an à compter de la clôture de l’opération. </p>

			<p>Conformément au RGPD, vous disposez d’un droit d’accès, d’interrogation, de rectification, d’effacement, à la portabilité, et à la limitation du traitement des informations qui vous concernent et d’un droit d’opposition à la prospection en écrivant à <span v-if="contactEmail?.length > 0"><a :href="'mailto:' + contactEmail">{{contactEmail }}</a></span><span v-else class="client">{{client}}, {{clientAdress}}</span>.</p>
			
			<p>Vous pouvez également introduire une réclamation auprès de la CPVP.</p>
		</div>



		<div v-if="$i18n.locale ==='nl_BE' || $i18n.locale ==='nl_NL'">
			<p>De verzamelde informatie wordt elektronisch verwerkt door <span class="client">{{client}}</span>, de verwerkingsverantwoordelijke, gevestigd te <span class="client">{{clientAdress}}</span>, teneinde de terugbetaling van zijn commerciële aanbiedingen te realiseren.
			</p>

			<p>De verzamelde gegevens zijn bestemd voor de marketingafdeling van {{client}}. Wij bewaren uw gegevens gedurende één jaar, te rekenen vanaf het afsluiten van de actie.</p>

			<p>Overeenkomstig de AVG (GDPR) beschikt u over een recht van toegang, van inspraak, van rectificatie, van verwijdering, van overdraagbaarheid , van beperking van verwerking inzake de informatie die op u betrekking heeft en een recht van verzet tegen prospectie door u te wenden tot: <span v-if="contactEmail?.length > 0"><a :href="'mailto:' + contactEmail">{{contactEmail }}</a></span><span v-else class="client">{{client}}, {{clientAdress}}</span>.</p> 

			<p>U beschikt eveneens over het recht om een klacht in te dienen bij de CBPL.</p>
		</div>

		<div v-if="$i18n.locale ==='en_BE' || $i18n.locale ==='en_UK'">

			<p>
				Personal data collected are processed by <span class="client">{{client}}</span>, Data controller located at <span class="client">{{clientAdress}}</span>.
			</p>

			<p>
				These data are intended to the marketing service of {{client}} and are kept for a duration of one year after the closing of this operation.
			</p>

			<p>
				In accordance with GDPR, you have the right to access, modify, erase and limit treatment of your datas by writing to <span v-if="contactEmail?.length > 0"><a :href="'mailto:' + contactEmail">{{contactEmail }}</a></span><span v-else class="client">{{client}}, {{clientAdress}}</span>, with a copy of your ID card containing your signature.</p>

				<p>You can also introduce a claim to the Data protection autorities.</p>
			</div>


			<div class="gdpr-button" @click="closeMe">
				OK
			</div>
		</div>
	</template>

	<!-- ================================================================================== -->

	<script>
		export default {
			name:"gdpr",

			props : {
				client : {
					type: String,
					required: true
				},
				clientAdress : {
					type: String,
					required: true
				},
				contactName : {
					type: String,
					required: false
				},
				contactFname : {
					type: String,
					required: false
				},
				contactEmail : {
					type: String,
					required: false
				},
				contactPhone : {
					type: String,
					required: false
				}
			},

			data(){
				return {
					amIVisible : true
				};
			},
			computed: {
				formattedContactPhone(){
					return this.contactPhone.split(' ').join('');
				}
			},
			created(){
				let gdprflag = sessionStorage.getItem("gdprflag");
				console.log("GDPR was previously accepted. Renew the session to empty localStorage and see the gdpr again.");
				if (gdprflag === '1'){
					this.amIVisible = false;
				}
			},
			methods: {
				closeMe : function(){
					this.amIVisible = false;
					sessionStorage.setItem("gdprflag", 1);
					console.log("Turned gdpr banner off");
					console.log("--------------------------");
					this.$emit('gdprClosing')
				}
			}
		}
	</script>

	<!-- ================================================================================== -->

	<style lang="scss" scoped>
	</style>
