import {createStore} from "vuex";

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
    modules: {
    },
    state: {
        name: null,
        locale: null,
        idlanguagecode : 2,
        fingerprint: null,
        bearerToken: null,
        maintenance: false,
        langList: [],
        leadId: null,
        utm: {
            source: null,
            medium: null, 
            campaign: null,
            id: null,
            term: null,
            content: null
        },
    },

    
    /* Mutations are to update the state */
    /* ONE action by mutation */
    /* methodName (state, payload) {…} */
    /* mutations must be synchronous */
    mutations: {
        setName (state, name) {
            state.name = name;
        },
        clearName (state) {
            state.name = null;
        },
        setLead (state, leadId){
            state.leadId = leadId;
        },
        FILL_UTMLIST(state, utm){
            state.utm[utm.name] = utm.value;
        },
        SET_LOCALE (state, locale) {
            state.locale = locale;
        },
        SET_FINGERPRINT (state, fingerprint) {
            state.fingerprint = fingerprint;
        },
        SET_BEARER_TOKEN(state, bearerToken) {
            state.bearerToken = bearerToken;
        },
        SET_MAINTENANCE(state, value) {
            state.maintenance = value;
        },
        setIdLanguageCode (state, id){
            state.idlanguagecode = id;
        },
        fillLangList (state, list) {
            state.langList = list;
        },
    },





    /* Actions can be asynchronous */
    /* commit takes the name of the mutation it triggers */
    actions: {
        setName (context, name) {
            context.commit('setName', name);
        },
        clearName (context) {
            context.commit('clearName');
        },
        setLeadId (context, payload) {
            context.commit('setLead', payload);
        },
        setLocale (context, payload) {
            // console.log(`Store says: "locale received is ${payload.locale}"`);
            context.commit('SET_LOCALE', payload.locale);
        },
        setFingerprint (context, payload) {
            context.commit('SET_FINGERPRINT', payload.fingerprint);
        },
        setBearerToken(context, payload) {
            // console.log(payload);
            context.commit("SET_BEARER_TOKEN", payload.bearerToken);
        },
        setUTM(context, payload){
            context.commit("FILL_UTMLIST", payload);
        },
        setMaintenance(context, payload) {
            context.commit("SET_MAINTENANCE", payload);
        },
        setIdLanguageCode (context, id){
            // console.log("Store SET idlanguagecode to " + id);
            context.commit('setIdLanguageCode', id);
        },
        setLangList(context, payload){
            context.commit('fillLangList', payload);
        }
    },






    getters: {
        getName: (state) => {
            return state.name;
        },
        getLang: (state) => {
            // console.log("Lang is asked");
            return state.locale.slice(0,2);
        },
        getLeadId: (state) => {
            console.log("STORE RETURNS IDLEAD");
            console.log(state.leadId);
            return parseInt(state.leadId);
        },
        getLocale: (state) => {
            // console.log("Locale is asked");
            return state.locale;
        },
        getFingerprint: (state) => {
            return state.fingerprint;
        },
        getBearerToken: (state) => {
            return state.bearerToken;
        },
        getIdLanguageCode: (state) => {
            return state.idlanguagecode;
        },
        getMaintenance: (state) => {
            return state.maintenance;
        },
        getLangList: (state) => {
            return state.langList;
        },
        getState: (state) =>{
            return state;
        },
        getUtms: (state) => {
            return state.utm;
        },
    },
    strict: debug,
});
