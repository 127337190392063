// Cookiefirst
import config from "../config";

export default {

	methods: {
		putCookieFirstScriptInPlace(){
			console.log("--- COOKIEFIRST --------------------------------------");
			console.log("Deploying COOKIEFIRST");
			console.log("-----------------------------------------");
			let script = document.createElement('script');
			script.innerHTML = ``;
			script.setAttribute('src', `https://consent.cookiefirst.com/banner.js`);

			/* Put the code for this action here */
			script.setAttribute('data-cookiefirst-key',`7fb112cc-98f5-4fb3-96e7-a9a40edd471a`);
			try{
				document.body.prepend(script);
			}catch(e){
				console.log("Error when inserting cookieFirst in the page");
				console.log(e);
			}
			
		}
	},
	beforeMount() {
		if(config.isWebsiteInProduction && !this.isPnGAction){
			this.putCookieFirstScriptInPlace();
		}
	}

}
