import TestGenericMethods from "./TestGenericMethods";

export default {

	mixins: [
		TestGenericMethods,
		],

	data() {return {	}},

	methods: {
		
		fakingWrongDataInCouponDetails(data){
			/* Uncomment a line in checkCouponDetailsValidity() to run this */
			this.log("Fake data", 'alert');
			data[1].description = null;
			data[1].itemcode = null;
			data[2].imageName = undefined;
			delete data[3].idleadcoupon;
			delete data[4].nobon.nvaleu;
			data[5].gcn = null;
			delete data[6].maxcounter;
			return data;
		},

		checkCouponDetailsValidity(data){
			/* ! Uncomment this one to test with transformed data */
			// data = this.fakingWrongDataInCouponDetails(data);

			let isCouponValid = false;
			let comment = 'no comment';

			/* Does data exist? */
			if(!data){
				isCouponValid = false;
				comment = `"Coupon Details" route doesn't send any data`;
			}
			/* If it exist… */
			if(data){
				// Does ONE coupon have all its keys? and values? and sub-keys and sub-values ?
				let testOfEachCoupon = [];
				data.forEach(coupon => {
					this.log(`Testing coupon ${coupon.id} - ${coupon.description}`, 'low');
					let areCouponBaseKeysValid = this.isAllBaseRequiredKeysPresent(coupon, ['id', 'description', 'gcn', 'idleadmanagement', 'nobon', 'currentcounter', 'maxcounter', 'offersStillAvailableCounter', 'onlineenddate']);
					/* test key "nobon.nvaleu" */
					let areCouponNobonKeysValid = this.isAllBaseRequiredKeysPresent(coupon.nobon, ['nvaleu', 'nnobon']);
					testOfEachCoupon.push(areCouponBaseKeysValid && areCouponNobonKeysValid);
				});
				if(testOfEachCoupon.includes(false)){
					isCouponValid = false;
					comment = `There was problem with key(s) or value(s) in "Coupon Details" check.`;
				}else{
					isCouponValid = true;
					comment = `All coupon tested are OK`;
				}
			}
			return {'isCouponValid': isCouponValid, 'comment': comment}
		},

		test_couponDetails(data){
			if ( this.isCustomConsoleAllowed ){
				this.log("Testing coupon details", 'testOpen');
				let testResult = this.checkCouponDetailsValidity(data);
				this.log("", 'testClose');
				this.log(testResult.comment, testResult.isCouponValid ? 'green':'error' );
			}
		}
	}
}
