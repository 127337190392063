<template>


	<!-- START HEADER -->
	<div id="wowiheader" v-if="!userIsThereToPrintCoupon && !isUserBanned">

		<!-- Info Banner -->
		<!--
		<div v-if="participatingIsNotAllowedAnymore" style="background: #fc0; padding: 0.5em; text-align: center; font-size: 1.4em; font-weight: bold;">{{$t('pageTitles.actionEnded')}}</div>
	-->

	<div class="content">

		<!-- logo -->
		<div id="logo">
			<img src="~@/assets/img/wowideals.png" alt="Wowi deals logo" @click.prevent="moveToAnotherAction('portail')">
		</div>

		<!-- Slogan -->
		<h1 v-if="website === 'portail'">
			<span class="greeting" v-html="$t('pageLitteracy.aboutWowi')"></span>
		</h1>

		<!-- empty div for occupying grid space -->
		<div v-if="website !== 'portail'"></div>

		<!-- this is the special nav -->

		<div id="volets" v-if="website !== 'portail'">

			<div v-if="mustBePresentThisTime.includes('ecoupons')" class="tocoupons" :class="{'active': website === 'ecoupons'}" @click.prevent="moveToAnotherAction('ecoupons')">
				<span class="fullText" v-html="$t('pageLitteracy.header.spareCoupons')"></span>
				<span class="tinyText">eCoupons</span>
			</div>

			<div v-if="mustBePresentThisTime.includes('cashbacks')" class="tocashbacks" :class="{'active': website === 'cashbacks'}" @click.prevent="moveToAnotherAction('cashbacks')">
				<span class="fullText" v-html="$t('pageLitteracy.header.spareCashbacks')"></span>
				<span class="tinyText">Cashbacks</span>
			</div>

			<div v-if="mustBePresentThisTime.includes('primes')" class="toprimes" :class="{'active': website === 'primes'}" @click.prevent="moveToAnotherAction('primes')">
				<span class="fullText" v-html="$t('pageLitteracy.header.sparePrimes')"></span>
				<span class="tinyText">Promocodes</span>
			</div>


		</div>



		<!-- Login button -->
		<div id="profilaccess" v-if="isActionStatusInProduction() || isAdminNavAllowed">

			<!-- we are not connected -->
			<div class="unconnected"	v-if="!userIsLogged">
				<button id="loginbutton" @click.prevent="requireUserToLog()">{{$t('buttons.logme')}}</button>
			</div>
			<!-- we are connected -->
			<div class="connected" v-if="userIsLogged"	>
				<button id="burgermenu" @click="toggleMenu">Menu</button>

				<div id="menu" v-show="menuOpen">
					<div class="lines">
						<div class="line" @click.prevent="moveToAnotherAction('ecoupons')" :class="{'active': currentPage === 'wowi' && website === 'ecoupons'}">
							<div class="round">
								<div class="icon">									
									<svg version="1.1" id="ecouponsicon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 248.774 248.774" style="enable-background:new 0 0 24 24;" xml:space="preserve">
										<path d="M219.179,51.502H29.6C13.277,51.502,0,63.539,0,78.335v92.104c0,14.796,13.277,26.833,29.6,26.833h189.577
										c16.318,0,29.597-12.037,29.597-26.833V78.335C248.774,63.539,235.495,51.502,219.179,51.502z M233.557,170.439
										c0,6.404-6.455,11.616-14.38,11.616H29.6c-7.931,0-14.383-5.212-14.383-11.616V78.335c0-6.404,6.452-11.616,14.383-11.616
										h189.577c7.926,0,14.38,5.212,14.38,11.616V170.439z"/>
										<rect x="67.622" y="104.742" width="15.217" height="13.097"/>
										<rect x="67.622" y="157.127" width="15.217" height="13.097"/>
										<rect x="67.622" y="78.548" width="15.217" height="13.097"/>
										<rect x="67.622" y="130.936" width="15.217" height="13.094"/>
										<path d="M152.026,103.507c0-11.874-9.858-21.611-21.616-21.611c-11.874,0-21.613,9.62-21.613,21.611
										c0,12.115,9.741,21.613,21.613,21.613C142.168,125.12,152.026,115.503,152.026,103.507z M121.029,103.507
										c0-5.225,4.276-9.379,9.379-9.379s9.379,4.157,9.379,9.379c0,5.227-4.276,9.381-9.379,9.381
										C125.305,112.888,121.029,108.731,121.029,103.507z"/>
										<path d="M186.189,124.41c-11.874-0.043-21.651,9.544-21.697,21.537c-0.041,12.11,9.665,21.649,21.542,21.695
										c11.76,0.043,21.646-9.546,21.692-21.537C207.767,134.227,197.947,124.45,186.189,124.41z M186.108,155.407
										c-5.103,0-9.376-4.162-9.376-9.384c0-5.227,4.276-9.381,9.376-9.381c5.108,0,9.381,4.157,9.381,9.381
										C195.492,151.248,191.216,155.407,186.108,155.407z"/>
										<rect x="109.054" y="117.595" transform="matrix(0.4696 -0.8829 0.8829 0.4696 -26.7873 205.8196)" width="97.7" height="15.217"/>
									</svg>
								</div>
							</div>
							<div class="text">{{$t('navigation.menu.coupons')}}</div>
						</div>


						<div class="line" @click.prevent="moveToAnotherAction('cashbacks')" :class="{'active': currentPage === 'wowi' && website === 'cashbacks'}">
							<div class="round">
								<div class="icon">									
									<svg version="1.1" id="cashbacksicon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
										<path d="M13.8,19.8c-2.3,0-4.3-1.6-5.4-3.9h7.3v-3.2H7.5c0-0.3,0-0.5,0-0.8c0-0.3,0-0.5,0-0.8h8.1V7.9H8.4c1.1-2.3,3.1-3.9,5.4-3.9
										c2.2,0,4.3,1.5,5.4,3.9l2.9-1.4c-1.7-3.5-4.9-5.7-8.3-5.7c-2.6,0-5,1.2-6.8,3.3C6.1,5.3,5.4,6.6,4.9,8H1.4v3.2h2.9
										c0,0.3,0,0.5,0,0.8c0,0.3,0,0.5,0,0.8H1.4v3.2h3.5c0.5,1.4,1.1,2.6,2.1,3.7c1.8,2.2,4.2,3.3,6.8,3.3c3.5,0,6.6-2.2,8.3-5.7l-2.9-1.4
										C18.1,18.3,16,19.8,13.8,19.8z"></path>
									</svg>
								</div>
							</div>
							<div class="text">{{$t('navigation.menu.cashbacks')}}</div>
						</div>

						<div class="line" @click.prevent="moveToAnotherAction('primes')" :class="{'active': currentPage === 'wowi' && website === 'primes'}">
							<div class="round">
								<div class="icon">									
									<svg version="1.1" id="primesicon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 248.774 248.774" style="enable-background:new 0 0 24 24;" xml:space="preserve">
										<path d="M219.2,51.5H29.6C13.3,51.5,0,63.5,0,78.3v92.1c0,14.8,13.3,26.8,29.6,26.8h189.6c16.3,0,29.6-12,29.6-26.8V78.3C248.8,63.5,235.5,51.5,219.2,51.5zM233.6,170.4c0,6.4-6.5,11.6-14.4,11.6H29.6c-7.9,0-14.4-5.2-14.4-11.6V78.3c0-6.4,6.5-11.6,14.4-11.6h189.6c7.9,0,14.4,5.2,14.4,11.6V170.4z"></path>
										<path d="M36,150.9l-7.8-13.2h10.1l2,3.9c0.7,1.5,1.5,3.2,2.2,4.7h0.2c0.5-1.5,1.2-3.2,1.7-4.7l1.3-3.9h9.8L47.7,152l8.3,13.2H45.8	l-2.2-3.9c-0.8-1.5-1.6-3.2-2.4-4.7H41c-0.6,1.5-1.3,3.1-1.9,4.7l-1.5,3.9h-9.8L36,150.9z"></path>
										<path d="M65.7,150.9l-7.8-13.2h10.1l2,3.9c0.7,1.5,1.5,3.2,2.2,4.7h0.2c0.5-1.5,1.2-3.2,1.7-4.7l1.3-3.9h9.8L77.4,152l8.3,13.2H75.5l-2.2-3.9c-0.8-1.5-1.6-3.2-2.4-4.7h-0.2c-0.6,1.5-1.3,3.1-1.9,4.7l-1.5,3.9h-9.8L65.7,150.9z"></path>
										<path d="M95.4,150.9l-7.8-13.2h10.1l2,3.9c0.7,1.5,1.5,3.2,2.2,4.7h0.2c0.5-1.5,1.2-3.2,1.7-4.7l1.3-3.9h9.8l-7.8,14.3l8.3,13.2h-10.1l-2.2-3.9c-0.8-1.5-1.6-3.2-2.4-4.7h-0.2c-0.6,1.5-1.3,3.1-1.9,4.7l-1.5,3.9h-9.8L95.4,150.9z"></path>
										<path d="M117.5,147.9h13.9v6.6h-13.9V147.9z"></path>
										<path d="M141.7,150.9l-7.8-13.2H144l2,3.9c0.7,1.5,1.5,3.2,2.2,4.7h0.2c0.5-1.5,1.2-3.2,1.7-4.7l1.3-3.9h9.8l-7.8,14.3l8.3,13.2h-10.1l-2.2-3.9c-0.8-1.5-1.6-3.2-2.4-4.7h-0.2c-0.6,1.5-1.3,3.1-1.9,4.7l-1.5,3.9h-9.8L141.7,150.9z"></path>
										<path d="M171.4,150.9l-7.8-13.2h10.1l2,3.9c0.7,1.5,1.5,3.2,2.2,4.7h0.2c0.5-1.5,1.2-3.2,1.7-4.7l1.3-3.9h9.8L183,152l8.3,13.2h-10.1l-2.2-3.9c-0.8-1.5-1.6-3.2-2.4-4.7h-0.2c-0.6,1.5-1.3,3.1-1.9,4.7l-1.5,3.9h-9.8L171.4,150.9z"></path>
										<path d="M201.1,150.9l-7.8-13.2h10.1l2,3.9c0.7,1.5,1.5,3.2,2.2,4.7h0.2c0.5-1.5,1.2-3.2,1.7-4.7l1.3-3.9h9.8l-7.8,14.3l8.3,13.2	h-10.1l-2.2-3.9c-0.8-1.5-1.6-3.2-2.4-4.7H206c-0.6,1.5-1.3,3.1-1.9,4.7l-1.5,3.9h-9.8L201.1,150.9z"></path>
										<path d="M66.5,93.6c0-9.6,6.6-15.1,13.9-15.1c3.8,0,6.9,1.7,8.9,3.8l-4,4.6c-1.4-1.2-2.8-1.9-4.8-1.9c-3.4,0-6.3,3.1-6.3,8.3c0,5.4,2.5,8.4,6.2,8.4c2.2,0,4-0.9,5.2-2.2l4,4.5c-2.6,2.9-5.8,4.2-9.5,4.2C72.8,108.3,66.5,103.4,66.5,93.6z"></path>
										<path d="M90.6,93.2c0-9.5,5.4-14.8,13.4-14.8c8,0,13.4,5.4,13.4,14.8c0,9.5-5.4,15-13.4,15C96,108.3,90.6,102.7,90.6,93.2zM109.5,93.2c0-5.2-2.1-8.2-5.6-8.2c-3.4,0-5.6,3-5.6,8.2c0,5.2,2.1,8.5,5.6,8.5C107.4,101.7,109.5,98.5,109.5,93.2z"></path>
										<path d="M122.5,79h8.5c8.8,0,14.8,4.1,14.8,14.2c0,10.2-6,14.5-14.3,14.5h-8.9V79z M130.5,101.6c4.2,0,7.4-1.7,7.4-8.4	c0-6.7-3.3-8.1-7.4-8.1h-0.4v16.5H130.5z"></path>
										<path d="M150.8,79h18.6v6.4h-11v4.5h9.4v6.4h-9.4v5.1h11.4v6.4h-19V79z"></path>
									</svg>
								</div>
							</div>
							<div class="text">{{$t('navigation.menu.primes')}}</div>
						</div>

						<div class="line" @click.prevent="modifyMyProfile()" :class="{'active':currentPage === 'profile'}">
							<div class="round">
								<div class="icon">									
									<svg version="1.1" id="profileicon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
										<path d="M11.7,2.2c3.1,0,5.2,2.7,5.2,5.1c0,2.4-1.9,5.1-5.1,5.2c-3.2,0.1-5.2-2.4-5.3-5S8.5,2.2,11.7,2.2z"></path>
										<path d="M20.6,22.5H2.5c0,0,0-3.1,0.2-3.7s0.6-2.5,1.6-3.5s2.6-1.5,3.7-1.5s2.4,0.6,3.6,0.7s2.4-0.7,3.7-0.7
										s3,1,3.6,1.7s1.2,2.3,1.3,2.9S20.6,22.5,20.6,22.5z"></path>
									</svg>
								</div>
							</div>
							<div class="text">{{$t('navigation.menu.myProfil')}}</div>
						</div>
<!--
						<div class="line" @click.prevent="askDeleteAccountConfirmation()" :class="{'active':currentPage === 'deleteAccount'}">
							<div class="round">
								<div class="icon">
									<svg version="1.1" id="logouticon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
										<polygon points="16.5,4.8 15.1,3.4 10,8.5 4.9,3.4 3.5,4.8 8.6,9.9 3.5,15 4.9,16.4 10,11.3 15.1,16.4 16.5,15 11.4,9.9 "/>
									</svg>
								</div>
							</div>
							<div class="text">{{$t('navigation.menu.unregister')}}</div>
						</div>
					-->

				</div>
				<hr>

				<div class="lines">
					<div class="line" @click.prevent="userClickedOnLogoutButton()">
						<div class="round">
							<div class="icon">
								<svg version="1.1" id="logouticon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
									<path d="M3 3h8V1H3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h8v-2H3z"></path>
									<path d="M19 10l-6-5v4H5v2h8v4l6-5z"></path>
								</svg>
							</div>
						</div>
						<div class="text">{{$t('navigation.menu.logout')}}</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</div>
<!-- <div id="banner" v-if="dateStatus === 'actionEnded'">{{$t('pageTitles.actionEnded')}}</div> -->
</div>
<!-- END HEADER -->



<!-- Spinner - Loading message -->
<div id="loader" v-if="pendingParticipationSent">
	<div class="loader-message">
		<div class="loader-visual">&nbsp;</div>
		<div class="loader-text">{{$t('pageTitles.wait')}}</div>
	</div>
</div>




<!-- 
	MODALES 

-->
<div class="mask" v-show="isAtLeastOneModaleVisible"></div>

<!-- Screen surface to position the modale in the center -->
<div class="screenzone">
	
	<!-- LOGIN modale -->
	<transition name="fade" mode="out-in">

		<modale v-show="modaleVisibility.login" :title="$t('pageLitteracy.modales.login')" id="loginModale" @closeMe="userCloseThisModale('login')">
			<div class="content">
				<form class="fields fullwidth">
					<!-- EMAIL -->
					<crm-input
					fieldname="email"
					translationSource="email"
					inputType="text"
					:isRequired="true"
					name="email"
					autocompleteName="email"
					:isDisabled="isMailFieldDisabled"
					v-model.lazy:modelValue="fieldsValues.email"
					@runCheck="checkIfMustUnlockClaimCashbackButton"
					></crm-input>

					<!-- PASSWORD -->
					<free-input
					fieldname="pwd"
					translationSource="password"
					:displayErrors="false"
					inputType="password"
					name="password"
					autocompleteName="new-password"
					:acceptedValues="[]"
					:isRequired="true"
					v-model.lazy:modelValue="fieldsValues.password"
					@runCheck="updateValidityOfLoginFields"
					:isDisabled="false"
					></free-input>
					
					<div class="linkcontainer">
						<a href="" id="forgotpasswordbutton" @click.prevent="userForgotHisPassword">{{$t('pageLitteracy.modales.forgotPassword')}}</a>
					</div>

					<div class="logerrormsg" v-show="loginErrorIsVisible">
						<span v-html="$t('pageLitteracy.modales.errorLoginPassUnrecognised')"></span>
					</div>

				</form>
			</div>

			<div class="nav">
				<button class="primary" @click="userTryToLogin()" :disabled="!fieldsValues.email || !fieldsValues.password">{{$t('buttons.login')}}</button>
			</div>

			<div class="nav">
				<p class="center">{{$t('pageLitteracy.notYetRegistered')}}</p> 
				<button class="secondary" @click="moveToRegisterPage()">{{$t('buttons.register')}}</button>
			</div>

		</modale>
	</transition>


	<!-- ERROR modale -->
	<transition name="fade" mode="out-in">
		<modale v-show="modaleVisibility.error" :title="errorModaleTitle" @closeMe="userCloseThisModale('error')" id="errorModale">	
			<div class="content">
				<p class="center"><ul v-html="errorModaleMessage"></ul></p>
			</div>
		</modale>
	</transition>


	<!-- EMPTY ORDER modale -->
	<transition name="fade" mode="out-in">
		<modale v-show="modaleVisibility.emptyOrder" :title="$t('pageLitteracy.modales.emptyOrder.title')" @closeMe="userCloseThisModale('emptyOrder')" id="emptyOrderModale">	
			<div class="content">
				<p class="center">
					{{$t('pageLitteracy.modales.emptyOrder.text')}}			
				</p>
			</div>	
			<div class="nav">
				<button @click.prevent="userCloseThisModale('emptyOrder')">{{$t('pageLitteracy.modales.emptyOrder.button')}}</button>
			</div>
		</modale>
	</transition>
	

	<!-- UNIQUE CODE modale -->
	<transition name="fade" mode="out-in">
		<modale v-show="modaleVisibility.code" :title="$t('pageLitteracy.modales.code')" @closeMe="userCloseThisModale('code')" id="uniqueCodeModale">
			<div class="content">
				<form>
					<h3>{{$t('pageLitteracy.modales.validationCode')}}</h3>
					<p>{{$t('pageLitteracy.modales.shouldReceiveSMS')}}</p>
					<div class="center">
						<free-input
						fieldname="code"
						translationSource="code"
						inputType="text"
						:acceptedValues="[]"
						:isRequired="true"
						v-model.lazy:modelValue="fieldsValues.code"
						@runCheck="updateValidityOfLoginFields"
						:isDisabled="false"
						:isEmptyValueTolerated="true"
						></free-input>
					</div>
					<div class="logerrormsg" v-show="codeErrorIsVisible">
						<span v-html="$t('pageLitteracy.modales.codeError')"></span>	
					</div>
				</form>
			</div>
			<div class="nav">
				<button class="secondary" @click.prevent="userRequestsNewCode()" v-if="isResendCodeButtonVisible"
				:key="'resend-' + isResendCodeButtonVisible">{{$t('pageLitteracy.modales.askCode')}}</button>
				<button class="primary" @click="userTryToSendACode()" :disabled="!fieldsValues.code || fieldsValues.code.length !== 6">{{$t('buttons.validation')}}</button>
			</div>
		</modale>
	</transition>


	<!-- MODALE : Info reservation -->
	<transition name="fade" mode="out-in">
		<modale v-show="modaleVisibility.infoReservation" :title="$t('pageLitteracy.modales.reservation.titleModaleBox')" @closeMe="userCloseThisModale('infoReservation')" id="infoReservationModale">
			<div class="content">
				<h2>{{$t('pageLitteracy.modales.reservation.titleInsideModale')}}</h2>		
				<p>{{$t('pageLitteracy.modales.reservation.p1')}}</p>
				<p>{{$t('pageLitteracy.modales.reservation.p2')}}</p>
			</div>
			<div class="nav">
				<button @click.prevent="userCloseThisModale('infoReservation')">{{$t('pageLitteracy.modales.reservation.button')}}</button>
			</div>
		</modale>
	</transition>


	<!-- MODALE : Info Participation -->
	<transition name="fade" mode="out-in">
		<modale v-show="modaleVisibility.infoParticipation" :title="$t('pageLitteracy.modales.participation.titleModaleBox')" @closeMe="userCloseThisModale('infoParticipation')" id="infoParticipationModale">	
			<div class="content">
				<h2>{{$t('pageLitteracy.modales.participation.titleInsideModale')}}</h2>		
				<p v-html="$t('pageLitteracy.modales.participation.p1')"></p>
				<ul class="relisted">
					<li>{{$t('pageLitteracy.modales.participation.li1')}}</li>
					<li>{{$t('pageLitteracy.modales.participation.li2')}}</li>
					<li>{{$t('pageLitteracy.modales.participation.li3')}}</li>
				</ul>
				<p v-html="$t('pageLitteracy.modales.participation.p2')"></p>
				<p v-html="$t('pageLitteracy.modales.participation.p3')"></p>
				<p v-html="$t('pageLitteracy.modales.participation.p4')"></p>
				<p v-html="$t('pageLitteracy.modales.participation.p5')"></p>
			</div>
			<div class="nav">
				<button @click.prevent="userCloseThisModale('infoParticipation')">{{$t('pageLitteracy.modales.participation.button')}}</button>
			</div>
		</modale>
	</transition>

</div>

<div id="formsContainer" :class="{'redux': userIsThereToPrintCoupon}" >
<!-- 
THE MANY FORM PAGES

-->

<transition name="fade" mode="out-in">










	<page-form :title="$t('pageTitles.wait')" :grid="false" v-if="currentPage==='loading' || !allowFormToBeDisplayed" key="loading">
		<template v-slot:content>
			<!-- ================================================ -->
			<section>
				<!-- TODO maybe one day add a spinner here, or a random phrase -->
			</section>
			<!-- ================================================ -->
		</template>
	</page-form>


	<page-form :title="$t('pageTitles.maintenance')" v-else-if="currentPage==='maintenance'" key="maintenance">
		<template v-slot:content>
			<!-- ================================================ -->
			<section>
				<div style="text-align:center;">
					<p>{{$t('maintenance.line1')}}</p>
					<p>{{$t('maintenance.line2')}}</p>
					<p>{{$t('maintenance.thanks')}}</p>
				</div>
			</section>
			<!-- ================================================ -->
		</template>
	</page-form>


	<page-form :title="$t('pageTitles.pageError')" :grid="false" v-else-if="currentPage==='pageError'" key="pageError">
		<template v-slot:content>
			<!-- ================================================ -->
			<section>
				<div class="simpletext">
					<p>
						{{$t('pageLitteracy.pageError.intro')}}
						<br>{{$t('pageLitteracy.pageError.solution1')}}
					</p>
					<p v-html="$t('pageLitteracy.pageError.solution2')"></p>
				</div>
			</section>
			<!-- ================================================ -->
		</template>
	</page-form>


	<page-form :title="$t('pageTitles.beforeStart')" v-else-if="currentPage==='beforeStart'" key="beforeStart">
		<template v-slot:content>
			<!-- ================================================ -->
			<section>
				<div class="simpletext">
					<!-- Si la date est dépassée, mais que le status de l'offre n'est toujours pas à "P" qu'afficher -->
					<!-- Uniquement en production -->

					<p v-if="isWebsiteInProduction && lead.offerstatus.toUpperCase() != 'P' &&  actionFullDates.registration.start.getTime() <= actionFullDates.current.getTime()">{{$t('pageLitteracy.beforeStart.shouldBeginSoon')}}
					</p>

					<p v-else>{{$t('pageLitteracy.beforeStart.text')}} <strong><span v-if="!isWebsiteInProduction">{{$t(getHumanReadableDate(addSixWeeksTo(actionFullDates.registration.start)))}}</span>
						<span v-else>{{$t(getHumanReadableDate(actionFullDates.registration.start))}}</span></strong>.
					</p>

				</div>
			</section>
			<!-- ================================================ -->
		</template>
	</page-form>


	<page-form :title="$t('pageTitles.actionEnded')" v-else-if="currentPage==='actionEnded'" key="actionEnded">
		<template v-slot:content>
			<!-- ================================================ -->
			<section>
				<div class="simpletext">
					<p>{{$t('pageLitteracy.actionEnded.text')}}</p>
				</div>

				<!-- Special case for reservations -->
				<div v-if="isUserAbleToSendReservedCashbacks && website === 'cashbacks' ">
					<h2>{{$t('pageLitteracy.actionEnded.title2')}}</h2>
					<div class="simpletext">
						<p style="margin-bottom: 2em;">{{$t('pageLitteracy.actionEnded.text2')}}</p>
						<span class="fake_btn" v-if="!userIsLogged" @click="toggleModaleVisibility('login')">{{$t('pageLitteracy.actionEnded.btn_log')}}</span>
						<span class="fake_btn" v-if="userIsLogged" @click="refreshDataAndGoToOffersPage('offers')">{{$t('pageLitteracy.actionEnded.btn_offers')}}</span>
					</div>
				</div>

			</section>
			<!-- ================================================ -->
		</template>
	</page-form>


	<page-form :title="$t('pageTitles.maxReached')" v-else-if="currentPage==='maxReached'" key="maxReached">
		<template v-slot:content>
			<!-- ================================================ -->
			<section>
				<div class="simpletext">
					<p>{{$t('pageLitteracy.maxReached')}}</p>
				</div>
			</section>
			<!-- ================================================ -->
		</template>
	</page-form>








	<page-form :title="$t('pageTitles.noform')" v-else-if="currentPage==='noform'" key="noform">
		<template v-slot:content>
			<!-- ================================================ -->
			<section>
				<div style="text-align:center;">
					<p>{{$t('pageLitteracy.noform.text')}}</p>
					<p>{{$t('pageLitteracy.noform.text2')}}</p>
				</div>
			</section>
			<!-- ================================================ -->
		</template>
	</page-form>








	<page-form :title="$t('errors.accesBlocked')" v-else-if="currentPage==='banned'" key="banned">
		<template v-slot:content>
			<!-- ================================================ -->
			<section>
				<div style="text-align:center;">
					<!-- <p>{{$t('errors.accesBlocked')}}</p> -->
				</div>
			</section>
			<!-- ================================================ -->
		</template>
	</page-form>








	<page-form :title="$t('pageTitles.cannotPrintCoupon')" v-else-if="currentPage==='cannotPrintCoupon'" key="cannotPrintCoupon">
		<template v-slot:content>
			<!-- ================================================ -->
			<section>
				<div style="text-align:center;">
					<p>{{$t('pageLitteracy.cannotPrintCoupon.text')}}</p>
					<p>{{$t('pageLitteracy.cannotPrintCoupon.text2')}}</p>
				</div>
			</section>
			<!-- ================================================ -->
		</template>
	</page-form>



	<page-form :title="$t('printCoupons.page1.title')" v-else-if="currentPage==='printcoupon' && allowFormToBeDisplayed" key="printcoupon">
		<!-- WIP ================================================================== -->
		<template v-slot:content>
			<section class="formgrid">
				<div class="col1"></div>
				<div class="col2">

					<p v-html="$t('printCoupons.page1.intro')"></p>


					<h3 v-html="$t('printCoupons.page1.beforePrint')" />

					<p class="softWarningText" v-html="$t('printCoupons.page1.attention')"></p>

					<div class="instructionPrinting">
						<img src="@/assets/img/printer_ready.jpg" alt="" id="happyprinter">
						<ol>
							<li v-html="$t('printCoupons.page1.step1')" />
							<li v-html="$t('printCoupons.page1.step2')" />
							<li v-html="$t('printCoupons.page1.step3')" />
						</ol>
					</div>


					
					<h3 v-html="$t('printCoupons.page1.duringPrint')" />

					<p class="softWarningText" v-html="$t('printCoupons.page1.attention2')"></p>

					<p v-html="$t('printCoupons.page1.warnScale')"></p>
					
					<div class="block badBlock">
						<div class="icon cross">
							<svg height="40" viewBox="0 0 512 512" width="40" xmlns="http://www.w3.org/2000/svg"><path d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0" fill="#f44336"/><path d="m350.273438 320.105469c8.339843 8.34375 8.339843 21.824219 0 30.167969-4.160157 4.160156-9.621094 6.25-15.085938 6.25-5.460938 0-10.921875-2.089844-15.082031-6.25l-64.105469-64.109376-64.105469 64.109376c-4.160156 4.160156-9.621093 6.25-15.082031 6.25-5.464844 0-10.925781-2.089844-15.085938-6.25-8.339843-8.34375-8.339843-21.824219 0-30.167969l64.109376-64.105469-64.109376-64.105469c-8.339843-8.34375-8.339843-21.824219 0-30.167969 8.34375-8.339843 21.824219-8.339843 30.167969 0l64.105469 64.109376 64.105469-64.109376c8.34375-8.339843 21.824219-8.339843 30.167969 0 8.339843 8.34375 8.339843 21.824219 0 30.167969l-64.109376 64.105469zm0 0" fill="#fafafa"/></svg>
						</div>
						<img src="@/assets/img/thatsabadcoupon.png" alt="">
						<p v-html="$t('printCoupons.page1.badScale')"></p>
					</div>
					<p v-html="$t('printCoupons.page1.waited')"></p>
					<div class="block goodBlock">
						<div class="icon tick">
							<svg height="40" viewBox="0 0 512 512" width="40" xmlns="http://www.w3.org/2000/svg">
								<g>
									<path class="st0" d="M256,0C114.8,0,0,114.8,0,256s114.8,256,256,256s256-114.8,256-256S397.2,0,256,0z M385.7,201.7L247.1,340.4
									c-4.2,4.2-9.6,6.3-15.1,6.3c-5.5,0-10.9-2.1-15.1-6.3l-69.3-69.3c-8.3-8.3-8.3-21.8,0-30.2c8.3-8.3,21.8-8.3,30.2,0l54.3,54.3
									l123.6-123.6c8.3-8.3,21.8-8.3,30.2,0S394.1,193.4,385.7,201.7z" fill="#64D782"/>
								</g>
								<path class="st1" d="M355.6,171.6L232,295.2l-54.3-54.3c-8.3-8.3-21.8-8.3-30.2,0c-8.3,8.3-8.3,21.8,0,30.2l69.3,69.3
								c4.2,4.2,9.6,6.3,15.1,6.3c5.5,0,10.9-2.1,15.1-6.3l138.7-138.7c8.3-8.3,8.3-21.8,0-30.2S363.9,163.2,355.6,171.6z" fill="#fff"/>
							</svg>
						</div>
						<img src="@/assets/img/thatsagoodcoupon.png" alt="">
						<div>
							<p v-html="$t('printCoupons.page1.goodScale')"></p>
							<img v-if="$i18n.locale === 'fr_BE'" src="@/assets/img/scale_fr.png" alt="" class="scale">
							<img v-if="$i18n.locale === 'nl_BE'" src="@/assets/img/scale_nl.png" alt="" class="scale">
							<img src="@/assets/img/scale_en.png" alt="" class="scale">
						</div>
					</div>

					<p v-html="$t('printCoupons.page1.lastButNotLeast')"></p>
					<br />

					<!-- Custom Checkbox General Conditions -->
					<custom-checkbox
					fieldname="conditionsCoupons"
					inputid="demoConditionsCB"
					helper=""
					:isRequired="requiredFieldsShortList.includes('generalCondition')"
					:idLead="lead.id"
					v-model="fieldsValues.cg"
					@runCheck="checkIfMustUnlockClaimCashbackButton"
					special-style="vcheck"
					class="col2"
					>
					<template v-slot:msg>
						{{$t('printCoupons.page1.conditions')}}<br><a href="Terms and conditions" @click.prevent="openPDFFile('conditions')" download>{{$t('printCoupons.page1.moreInfo')}}.</a>
					</template>
				</custom-checkbox>
			</div>
		</section>
	</template>
	<!-- Nav bar -->
	<template v-slot:nav>
		<section class="navigation">	
			<button @click.prevent="userAskedToPrintCoupons" :disabled="!isThisButtonActive('btn_cashback_on_printcoupon') || userAlreadyAskedToPrintHisCoupon">{{$t('printCoupons.page1.button')}}</button>
		</section>
	</template>
</page-form>





<page-form :title="$t('pageLitteracy.goodCouponPrinting.title')" v-else-if="currentPage==='goodCouponPrinting'" key="goodCouponPrinting">
	<template v-slot:content>

		<!-- ================================================ -->
		<section>
			<div class="simpletext">
				<p v-html="$t('pageLitteracy.goodCouponPrinting.textes.p1')"></p>
				<p v-html="$t('pageLitteracy.goodCouponPrinting.textes.p2')"></p>
				<p v-html="$t('pageLitteracy.goodCouponPrinting.textes.p3')"></p>
				<p v-html="$t('pageLitteracy.goodCouponPrinting.textes.p4')"></p>
			</div>
		</section>
		<!-- ================================================ -->
	</template>
	<!-- Nav bar -->
	<template v-slot:nav>
		<section class="navigation">	
			<button @click.prevent="forceBackToWowi">{{$t('pageLitteracy.goodCouponPrinting.btn_back')}}</button>
		</section>
	</template>
</page-form>



<page-form :title="$t('pageLitteracy.badCouponPrinting.title')" v-else-if="currentPage==='badCouponPrinting'" key="badCouponPrinting">
	<template v-slot:content>

		<!-- ================================================ -->
		<section>
			<div class="simpletext">
				<p v-html="$t('pageLitteracy.badCouponPrinting.textes.p1')"></p>
				<p v-html="$t('pageLitteracy.badCouponPrinting.textes.p2')"></p>
				<p v-html="$t('pageLitteracy.badCouponPrinting.textes.p3')"></p>
				<p v-html="$t('pageLitteracy.badCouponPrinting.textes.p4')"></p>
			</div>
		</section>
		<!-- ================================================ -->
	</template>
	<!-- Nav bar -->
	<template v-slot:nav>
		<section class="navigation">	
			<button @click.prevent="forceBackToWowi">{{$t('pageLitteracy.badCouponPrinting.btn_back')}}</button>
		</section>
	</template>
</page-form>



<page-form :title="$t('pageLitteracy.confirmationCoupon.title')" v-else-if="currentPage==='confirmationCoupon'" key="confirmationCoupon">
	<template v-slot:content>
		<section>
			<div class="confirmCouponGrid">
				<div>
					<span class="smile">😊</span>
					<button class="green" @click="goto('goodCouponPrinting')">{{$t('pageLitteracy.confirmationCoupon.btn_yes')}}</button>
				</div>
				<div>
					<span class="smile">😕</span>
					<button class="red" @click="userReportsBadPrinting">{{$t('pageLitteracy.confirmationCoupon.btn_no')}}</button>
				</div>
			</div>
		</section>

		
		<!-- WIP ================================================================== -->
		<!-- WIP ================================================================== -->
		<!-- WIP ================================================================== -->
	</template>

</page-form>




















<page-form :title="$t('pageLitteracy.confirmationMail.title')" v-else-if="currentPage==='confirmationMail'" key="confirmationMail">
	<template v-slot:content>

		<!-- ================================================ -->
		<section>
			<div class="simpletext">
				<p v-html="$t('pageLitteracy.confirmationMail.textes.p1')"></p>
			</div>
		</section>
		<!-- ================================================ -->
	</template>
</page-form>


















<page-form :title="$t('pageTitles.consentAgain')" v-else-if="currentPage==='newConsent'" key="newConsent">
	<template v-slot:content>
		<!-- ================================================ -->
		<section class="formgrid limitWidth">

			<div class="col1"></div>

			<div class="col2">

				<p class="aered" v-html="$t('pageLitteracy.newConsent.consetGenCond')"></p>

				<!-- Custom Checkbox General Conditions -->
				<custom-checkbox
				fieldname="conditions"
				translationSource="conditions"
				inputid="demoConditionsCB"
				:isRequired="true"
				v-model="fieldsValues.generalCondition"
				@runCheck="checkIfMustUnlockClaimCashbackButton"
				special-style="vcheck"
				class="col2"
				>
				<template v-slot:msg>
					{{$t('inputsTexts.conditions.part1')}} <a href="Terms and conditions" @click.prevent="openPDFFile('conditions')" download>{{$t('inputsTexts.conditions.link')}}</a>
				</template>
			</custom-checkbox>

			<p class="bigaered" v-html="$t('pageLitteracy.newConsent.consetOptin')"></p>

			<!-- Custom Checkbox Optin -->
			<custom-checkbox
			fieldname="optin"
			translationSource="optin"
			inputid="optin"
			:isRequired="false"
			v-model="fieldsValues.optin"
			@runCheck="checkIfMustUnlockClaimCashbackButton"
			special-style="vcheck"
			class="col2"
			>
			<template v-slot:msg>
				<!-- Checkbox "label" -->
				<div>
					<span v-html="$t('inputsTexts.optin.longtext.part1')"></span>
					<a :href="$t('footer.genterms')" @click.prevent="openPDFFile('conditions')" download>{{$t('inputsTexts.optin.longtext.linkCaption')}}</a>
					<span v-html="$t('inputsTexts.optin.longtext.part2')"></span>
				</div>
				
			</template>
		</custom-checkbox>

	</div>

	<div class="col4 printbuttonblock endpage">
		<div class="printmycouponbuttons">
			<button class="small" @click.prevent="updateConsent()" :disabled="!isConsentToCgGiven">{{$t('buttons.emailMyCoupons')}}</button>
						<!--
						<button class="small" @click.prevent="tryToPrintCoupons" :disabled="!isThereAtLeastOneSelectedProduct">{{$t('buttons.printMyCoupons')}}</button>
					-->
				</div>
			</div>

		</section>
	</template>
</page-form>











<page-form :title="$t('pageTitles.modifLoginPass')" v-else-if="currentPage==='modifLoginPass'" key="modifLoginPass">
	<template v-slot:content>
		<!-- ================================================ -->
		<section class="formgrid limitWidth">

			<div class="col1"></div>
			<form class="col2">
				<p>
					{{$t('pageLitteracy.modifProfile')}}
				</p>
				<p>
					{{$t('pageLitteracy.infoModifyLimitation')}}
				</p>

				<!-- PASSWORD -->
				<free-input
				fieldname="pwd"
				translationSource="passwordReset"
				inputType="password"
				:acceptedValues="[]"
				:isRequired="false"
				name="password"
				autocompleteName="new-password"
				v-model.lazy:modelValue="fieldsValues.password"
				@runCheck="updateValidityOfLoginFields"
				:isDisabled="profilePasswordFieldIsDisabled"
				></free-input>

				<!-- EMAIL -->
				<crm-input
				fieldname="email"
				translationSource="email"
				inputType="text"
				:isRequired="false"
				name="email"
				autocompleteName="email"
				:isDisabled="profileEmailFieldIsDisabled"
				v-model.lazy:modelValue="fieldsValues.email"
				@runCheck="checkIfMustUnlockClaimCashbackButton"
				></crm-input>

				<!-- PHONE -->
				<phone-input
				fieldname="phoneNumber1"
				translationSource="phone"
				v-model="smsHelper"
				inputType="phone"
				:isRequired="false"
				name="phone"
				autocompleteName="tel"
				:isDisabled="profilePhoneFieldIsDisabled"
				@internationalNumber="setPhone"
				@runCheck="checkIfMustUnlockClaimCashbackButton"
				:receivedNumber="fieldsValues.phoneNumber1"
				ref="phoneNumber1"></phone-input>

				<div class="col4">
					{{$t('pageLitteracy.smsToConfirm')}}
				</div>

			</form>

			<div class="col4 center">
				<button 	@click.prevent="updateCredentials()">{{$t('buttons.updateProfile')}}</button>
				<div style="height:1em;"></div>
				<button 	@click.prevent="goto('profile')" class="secondary">{{$t('buttons.backToProfile')}}</button>
			</div>

		</section>
		<!-- ================================================ -->
	</template>
</page-form>












<page-form :title="$t('pageTitles.modifProfile')" v-else-if="currentPage==='modifProfile'" key="modifProfile">
	<template v-slot:content>
		<!-- ================================================ -->
		<!-- Note: autocompleteName are transmited to the input, to say to the browser what kind of pre-fill could happen there. -->
		<!-- ================================================ -->
		<section class="formgrid limitWidth">

			<!-- FIRSTNAME -->
			<crm-input
			fieldname="firstName"
			translationSource="firstName"
			inputType="text"
			:isRequired="true"
			name="fname"
			autocompleteName="given-name"
			v-model.lazy:modelValue="fieldsValues.firstName"
			@runCheck="checkIfMustUnlockClaimCashbackButton"
			class=""
			></crm-input>


			<!-- LASTNAME -->
			<crm-input
			fieldname="lastName"
			translationSource="lastName"
			inputType="text"
			:isRequired="true"
			name="lname"
			autocompleteName="family-name"
			v-model.lazy:modelValue="fieldsValues.lastName"
			@runCheck="checkIfMustUnlockClaimCashbackButton"
			class=""
			></crm-input>

			<!-- COUNTRY -->
			<select-api
			fieldname="countries"
			translationSource="countries"
			dataSource="countries-allowed"
			:isLeadRequested="true"
			:isRequired="true"
			name="country"
			autocompleteName="new-country"
			@runCheck="updateValidityOfThisField"
			v-model.lazy:modelValue="fieldsValues.idCountryCode"
			:caractersBeforeOpenList="1"
			:autocomplete="true"
			:isReparticipation="reparticipation"
			:isDisabled="false"
			class="col2"
			></select-api>

			<!-- POSTAL CODE -->
			<select-api
			fieldname="postal-code"
			translationSource="postalCode"
			dataSource="postal-code"
			:dataRange="{key: 'idcountrycode', value: fieldsValues.idCountryCode}"
			:isLeadRequested="false"
			:isRequired="true"
			:isArrowVisible="false"
			name="postal"
			autocompleteName="new-postal-code"
			@runCheck="updateValidityOfThisField"
			v-model.lazy:modelValue="fieldsValues.crmpostalcodeId"
			:caractersBeforeOpenList="1"
			:autocomplete="true"
			:isReparticipation="reparticipation"
			:isDisabled="false"
			class="col2"
			></select-api>

			<!-- CONDITIONS: user can't unsubscribe from conditions of use -->

			<div class="col4 center">
				<button @click.prevent="updateCredentials">{{$t('buttons.updateProfile')}}</button>
				<div style="height:1em;"></div>
				<button 	@click.prevent="goto('profile')" class="secondary">{{$t('buttons.backToProfile')}}</button>
			</div>

		</section>
		<!-- ================================================ -->
	</template>
</page-form>














<page-form :title="$t('pageTitles.iframe')" v-else-if="currentPage==='iframe'" key="iframe">
	<template v-slot:content>
		<!-- ================================================ -->
		<section class="formgrid limitWidth">
			<div class="col4">
				<iframe :src="receivedLink" frameborder="1" width="100%" height="800"></iframe>
			</div>


			<div class="col4 center">
				<button @click.prevent="refreshDataAndGoToOffersPage('offers')">{{$t('buttons.backToOffers')}}</button>
			</div>

		</section>
		<!-- ================================================ -->
	</template>
</page-form>










<page-form :title="$t('pageTitles.modifPrefs')" v-else-if="currentPage==='modifPrefs'" key="modifPrefs">
	<template v-slot:content>
		<!-- ================================================ -->
		<section class="formgrid limitWidth">

			<div class="col1"></div>

			<!-- OPTIN -->
			<custom-checkbox
			fieldname="optin"
			translationSource="optin"
			inputid="optin"
			:helper="$t('inputsTexts.optin.helper')"
			:isRequired="false"
			data-comment-required="This will NEVER be true for a OPT-IN. But for a checkbox…"
			v-model="fieldsValues.optin"
			@runCheck="checkIfMustUnlockClaimCashbackButton"
			special-style="vcheck"
			class="col2">
			<template v-slot:msg>
				<div>
					<span v-html="$t('inputsTexts.optin.longtext.part1')"></span>
					<a :href="$t('footer.genterms')" @click.prevent="openPDFFile('conditions')" download>{{$t('inputsTexts.optin.longtext.linkCaption')}}</a>
					<span v-html="$t('inputsTexts.optin.longtext.part2')"></span>
				</div>
			</template>
		</custom-checkbox>

		<div class="col4 center">
			<button @click.prevent="updateCredentials()">{{$t('buttons.updatePrefs')}}</button>
			<div style="height:1em;"></div>
			<button 	@click.prevent="backToProfile()" class="secondary">{{$t('buttons.backToProfile')}}</button>
		</div>

	</section>
	<!-- ================================================ -->
</template>
</page-form>














<page-form :title="$t('pageTitles.profile')" v-else-if="currentPage==='profile'" key="profil">
	<template v-slot:content>
		<!-- ================================================ -->
		<section id="profile" class="formgrid limitWidth">
			<div class="col1"></div>

			<div class="col2">

				<p>
					{{$t('pageLitteracy.profileIntro')}}
				</p>
				
				<div class="subsection">
					<h3>{{$t('sectionTitles.infoConnect')}}</h3>
					<div class="spaced">
						<div class="line">
							<span class="span_label">{{$t('pageLitteracy.profileEmail')}}:</span> <span class="span_value">{{userInfos.email}}</span>
						</div>
						<div class="line">
							<span class="span_label">{{$t('pageLitteracy.profileTel')}}:</span> <span class="span_value">{{userInfos.phoneNumber1}}</span>
						</div>
						<div class="line">
							<span class="span_label">{{$t('pageLitteracy.profilePassword')}}:</span> <span class="span_value">********</span>
						</div>
					</div>
					
				</div>

				<button 
				class="small centered" 
				@click.prevent="goto('modifLoginPass');"
				>{{$t('buttons.updateLoginInfo')}}</button>


				<div class="subsection">
					<h3>{{$t('sectionTitles.userInfo')}}</h3>
					<div class="spaced">

						<div class="line">
							<span class="span_label">{{$t('pageLitteracy.profileFirstName')}}:</span> <span class="span_value">{{userInfos.firstName}}</span>
						</div>
						<div class="line">
							<span class="span_label">{{$t('pageLitteracy.profileLastName')}}:</span> <span class="span_value">{{userInfos.lastName}}</span>
						</div>
						<div class="line">
							<span class="span_label">{{$t('pageLitteracy.profilePostalCode')}}:</span> <span class="span_value">{{userInfos.postalCode}}</span>
						</div>
						<div class="line">
							<span class="span_label">{{$t('pageLitteracy.profileCity')}}:</span> <span class="span_value">{{userInfos.city}}</span>
						</div>
						<div class="line">
							<span class="span_label">{{$t('pageLitteracy.profileCountry')}}:</span> <span class="span_value">{{convertCountryIdToDescription(userInfos.idCountryCode)}}</span>
						</div>
					</div>
				</div>

				<button 
				class="small centered" 
				@click.prevent="userTryToReachThePageModifProfile()"
				>{{$t('buttons.modifyProfile')}}</button>

				<div class="subsection">
					<h3>{{$t('sectionTitles.userPrefs')}}</h3>
				</div>

				<button 
				class="small centered" 
				@click.prevent="goto('modifPrefs');"
				>{{$t('buttons.updatePrefs')}}</button>


				
				<button id="btn_unsubscribe" class="small centered" @click="askDeleteAccountConfirmation()">
					{{$t('buttons.unregister')}}
				</button>
				
			</div>

			<div class="col4 center">
				<button @click.prevent="refreshDataAndGoToOffersPage('offers')" class="secondary">{{$t('buttons.backToOffers')}}</button>
			</div>
		</section>
		<!-- ================================================ -->
	</template>
</page-form>
















<page-form :title="$t('pageTitles.register')" v-else-if="currentPage==='register'" key="register">
	<template v-slot:content>
		<!-- ================================================ -->
		<form>
			<section class="limitWidth formgrid">

				<!-- Mandatory field line -->
				<p style="text-align: right; font-size:0.8em;" class="col4"><span style="color: red;">*</span> {{$t('inputLabel.mandatoryFields')}}</p>

				<h3>{{$t('sectionTitles.userInfo')}}</h3>

				<!-- FIRSTNAME -->
				<crm-input
				fieldname="firstName"
				translationSource="firstName"
				name="fname"
				autocompleteName="given-name"
				inputType="text"
				:isRequired="requiredFieldsShortList.includes('firstName')"
				v-model.lazy:modelValue="fieldsValues.firstName"
				@runCheck="checkIfMustUnlockClaimCashbackButton"
				class="col2"
				></crm-input>


				<!-- LASTNAME -->
				<crm-input
				fieldname="lastName"
				translationSource="lastName"
				name="lname"
				autocompleteName="family-name"
				inputType="text"
				:isRequired="requiredFieldsShortList.includes('lastName')"
				v-model.lazy:modelValue="fieldsValues.lastName"
				@runCheck="checkIfMustUnlockClaimCashbackButton"
				class="col2"
				></crm-input>


				<!-- EMAIL -->
				<crm-input
				fieldname="email"
				translationSource="email"
				name="email"
				autocompleteName="email"
				inputType="text"
				:isRequired="requiredFieldsShortList.includes('email')"
				:isDisabled="isMailFieldDisabled"
				v-model.lazy:modelValue="fieldsValues.email"
				@runCheck="checkIfMustUnlockClaimCashbackButton"
				class="col2"
				></crm-input>

				<!-- EMAIL BIS -->
				<free-input
				fieldname="emailbis"
				translationSource="emailbis"
				name="emailbis"
				autocompleteName="newemail"
				inputType="text"
				:addData="{allowCopyPaste: false}"
				:acceptedValues="[fieldsValues.email]"
				:isRequired="requiredFieldsShortList.includes('email')"
				:isDisabled="isMailFieldDisabled"
				v-model.lazy:modelValue="fieldsValues.emailbis"
				@runCheck="checkIfMustUnlockClaimCashbackButton"
				class="col2"
				></free-input>

				<!-- PHONE -->
				<phone-input
				fieldname="phoneNumber1"
				translationSource="phone"
				v-model="smsHelper"
				inputType="phone"
				:isRequired="true"
				name="phone"
				autocompleteName="tel"
				@internationalNumber="setPhone"
				@runCheck="checkIfMustUnlockClaimCashbackButton"
				:receivedNumber="fieldsValues.phoneNumber1"
				ref="phoneNumber1"
				class="col2"></phone-input>

				<div class="col4"></div>

				<!-- COUNTRY -->
				<select-api
				fieldname="countries"
				translationSource="countries"
				dataSource="countries-allowed"
				:isLeadRequested="true"
				:isRequired="true"
				name="country"
				autocompleteName="new-country"
				@runCheck="updateValidityOfThisField"
				v-model.lazy:modelValue="fieldsValues.idCountryCode"
				:caractersBeforeOpenList="1"
				:autocomplete="true"
				:isReparticipation="reparticipation"
				:isDisabled="false"
				class="col2"
				></select-api>

				<!-- POSTAL CODE -->
				<select-api
				fieldname="postal-code"
				translationSource="postalCode"
				dataSource="postal-code"
				:dataRange="computedDataRange"
				:isLeadRequested="false"
				:isRequired="true"
				:isArrowVisible="false"
				name="custompost"
				autocompleteName="new-postal-code"
				@runCheck="updateValidityOfThisField"
				v-model.lazy:modelValue="fieldsValues.crmpostalcodeId"
				:caractersBeforeOpenList="1"
				:autocomplete="true"
				:isReparticipation="reparticipation"
				:isDisabled="false"
				class="col2"
				></select-api>

				<!-- PASSWORD -->
				<free-input
				fieldname="pwd"
				translationSource="passwordRegister"
				inputType="password"
				:acceptedValues="[]"
				:isRequired="true"
				name="password"
				autocompleteName="new-password"
				v-model.lazy:modelValue="fieldsValues.password"
				@runCheck="updateValidityOfLoginFields"
				:isDisabled="false"
				class="col2"
				></free-input>

				<div class="col4"></div>

				<div class="col1"></div>

				<!-- Custom Checkbox General Conditions -->
				<custom-checkbox
				fieldname="conditions"
				translationSource="conditions"
				inputid="demoConditionsCB"
				:isRequired="true"
				v-model="fieldsValues.generalCondition"
				@runCheck="checkIfMustUnlockClaimCashbackButton"
				special-style="vcheck"
				class="col2"
				>
				<template v-slot:msg>
					{{$t('inputsTexts.conditions.part1')}} <a href="Terms and conditions" @click.prevent="openPDFFile('conditions')" download>{{$t('inputsTexts.conditions.link')}}</a>
				</template>
			</custom-checkbox>

			<div class="col4"></div>

			<div class="col1"></div>

			<!-- Custom Checkbox Optin -->
			<custom-checkbox
			fieldname="optin"
			translationSource="optin"
			inputid="optin"
			:isRequired="false"
			v-model="fieldsValues.optin"
			@runCheck="checkIfMustUnlockClaimCashbackButton"
			special-style="vcheck"
			class="col2"
			>
			<template v-slot:msg>
				<!-- Checkbox "label" -->
				<div>
					<span v-html="$t('inputsTexts.optin.longtext.part1')"></span>
					<a :href="$t('footer.genterms')" @click.prevent="openPDFFile('conditions')" download>{{$t('inputsTexts.optin.longtext.linkCaption')}}</a>
					<span v-html="$t('inputsTexts.optin.longtext.part2')"></span>
				</div>
			</template>
		</custom-checkbox>

		<div class="col4" style="text-align: center; margin-bottom:8em;">
			<button @click.prevent="userTryToRegister()" :disabled="isRegisterButtonDisabled">{{$t('pageLitteracy.sendProfile')}}</button>
			<br><br>
			<button class="secondary" @click.prevent="refreshDataAndGoToOffersPage('offers')">{{$t('buttons.backToOffers')}}</button>
		</div>

	</section>
</form>
<!-- ================================================ -->
</template>
</page-form>





























<page-form :title="$t('pageTitles.changePassword')" v-else-if="currentPage==='changePassword'" key="Change Password">
	<template v-slot:content>
		<!-- ================================================ -->
		<section class="formgrid limitWidth">
			<div class="col1"></div>
			<form class="col2">
				<p>{{$t('pageLitteracy.changePassword')}}</p>

				<!-- PASSWORD -->
				<free-input
				fieldname="pwd"
				translationSource="passwordRegister"
				inputType="password"
				:acceptedValues="[]"
				:isRequired="true"
				name="password"
				autocompleteName="new-password"
				v-model.lazy:modelValue="fieldsValues.password"
				@runCheck="updateValidityOfLoginFields"
				:isDisabled="false"
				></free-input>

				<div class="center">
					<button @click.prevent="userSubmitANewPassword" :disabled="!fieldsValues.password">{{$t('buttons.changePassword')}}</button>
				</div>
			</form>
		</section>
		<!-- ================================================ -->
	</template>
</page-form>








<page-form :title="$t('pageTitles.resetPassword')" v-else-if="currentPage==='resetPassword'" key="resetPassword">
	<template v-slot:content>
		<!-- ================================================ -->
		<section class="formgrid limitWidth">
			<div class="col1"></div>
			<div class="col2">
				<p v-html="$t('pageLitteracy.resetPassword.instruction')"></p>
				<p v-html="$t('pageLitteracy.resetPassword.ifKnown')"></p>
				<p v-html="$t('pageLitteracy.resetPassword.ifNotKnown')"></p>
				<crm-input
				fieldname="email"
				translationSource="email"
				inputType="text"
				name="email"
				autocompleteName="email"
				:isRequired="requiredFieldsShortList.includes('email')"
				:isDisabled="isMailFieldDisabled"
				v-model.lazy:modelValue="fieldsValues.email"
				@runCheck="checkIfMustUnlockClaimCashbackButton"
				></crm-input>
				<div class="center">
					<button @click="userAskToResetPassword">{{$t('buttons.sendLink')}}</button>
				</div>
			</div>
		</section>
		<!-- ================================================ -->
	</template>
</page-form>















<page-form :title="$t('pageTitles.confirmLinkSent')" v-else-if="currentPage==='confirmLinkSent'" key="confirmLinkSent">
	<template v-slot:content>
		<!-- ================================================ -->
		<section class="limitWidth">
			<div class="simpletext">
				<p>{{$t('pageLitteracy.confirmLinkSent')}}</p>
			</div>
			<div class="col4" style="text-align: center; margin-bottom:8em;">
				<button @click="refreshDataAndGoToOffersPage('offers')">{{$t('buttons.backToOffers')}}</button>
			</div>
		</section>
		<!-- ================================================ -->
	</template>
</page-form>










<page-form :title="$t('pageTitles.warningBeforePrint')" v-else-if="currentPage==='warningBeforePrint'" key="warningBeforePrint">
	<template v-slot:content>
		<!-- ================================================ -->
		<section class="limitWidth">

			<div class="formgrid">
				<div class="col1"></div>
				<div class="col2">
					<div class="simpletext">
						<p>{{$t('pageLitteracy.warningBeforePrint')}}</p>
					</div>
					<div class="center">
						<button @click.prevent="userAsksToPrintCoupons()">{{$t('buttons.printMyCoupons')}}</button>
						<div style="height:4em;"></div>
						<p>{{$t('pageLitteracy.warningAlternative')}}</p>

						<button class="small" @click.prevent="tryToReceiveCoupons" :disabled="!isThereAtLeastOneSelectedProduct">{{$t('buttons.emailMyCoupons')}}</button>

						<div style="height:4em;"></div>
						<button class="secondary" @click.prevent="userCancelPrintingCoupons()">{{$t('buttons.backToOffers')}}</button>
					</div>
				</div>
			</div>

		</section>
		<!-- ================================================ -->
	</template>
</page-form>











<page-form :title="$t('pageTitles.confirmReset')" v-else-if="currentPage==='confirmReset'" key="confirmReset">
	<template v-slot:content>
		<!-- ================================================ -->
		<section class="limitWidth formgrid">

			<div class="col1"></div>
			<div class="col2">
				<p class="center">{{$t('pageLitteracy.confirmReset')}}</p>
			</div>

		</section>
		<section class="navipage">
			<button @click="toggleModaleVisibility('login')">{{$t('buttons.logme')}}</button>
		</section>


		<!-- ================================================ -->
	</template>
</page-form>








<page-form :title="$t('pageTitles.confirmReservation')" v-else-if="currentPage==='confirmReservation'" key="confirmReservation">
	<template v-slot:content>
		<!-- ================================================ -->
		<section class="limitWidth formgrid">
			<div class="col1"></div>
			<div class="col2">
				<h4 v-html="$t('pageLitteracy.confirmReservation.title')"></h4>
				<ul>
					<li v-for="item in justReserved" :key="'ReservItem-' + item">
						<div class="boxgtin">

							<img :src="getCashbacksImgUrl(getItemDataToDisplay(item.idPOPItem).code)" alt="">
							<div class="reservedTexts">
								<div class="reservedTitle">{{getItemDataToDisplay(item.idPOPItem).code}}</div>		
								<div class="reservedPrice">{{getItemDataToDisplay(item.idPOPItem).value}}</div>
								<div class="reservedDescription"> {{getItemDataToDisplay(item.idPOPItem).description}}</div>		
							</div>
						</div>
					</li>
				</ul>

				<p>{{$t('pageLitteracy.confirmReservation.p1')}}</p>
				<p>{{$t('pageLitteracy.confirmReservation.p2')}}</p>

			</div>
			<div class="col1"></div>
			<div class="col1"></div>
			<div class="col2 center">
				<button @click.prevent="refreshDataAndGoToOffersPage('offers')">{{$t('pageLitteracy.confirmReservation.btn_back')}}</button>
			</div>
		</section>
		<!-- ================================================ -->
	</template>
</page-form>







<page-form :title="$t('pageTitles.summaryCashback')" v-else-if="currentPage==='summaryCashback'" key="summaryCashback">
	<template v-slot:content>
		<!-- ================================================ -->
		<section class="limitWidth formgrid">

			<div class="col1"></div>
			<div class="col2">

				<div id="warningMultiCashbacks">
					<div class="exclam">!</div>
					<div>
						<p v-html="$t('pageLitteracy.summaryCashback.p1')"></p>		
						<p v-html="$t('pageLitteracy.summaryCashback.p2')"></p>	
						<p v-html="$t('pageLitteracy.summaryCashback.p3')"></p>	
					</div>
				</div>

				<h4 v-html="$t('pageLitteracy.summaryCashback.title')"></h4>
			</div>
			<div class="col4">
				<div class="cards">
					<card-cashback	
					v-for="(item, key) in popitemlist.filter(item => selectedCashbacks.includes(item.id))" 
					:data="item"
					:key="key + '-' + item.id"
					:alreadyClaimed = "false"
					:reserved = "isThisCashbackReserved(item.id)"
					:disabled = "false"
					:lite= "true"
					></card-cashback>
				</div>
			</div>

		</section>

		<section class="navipage">
			<button @click.prevent="userModifyItsSelection()">{{$t('pageLitteracy.summaryCashback.btnModify')}}</button>
			<button @click.prevent="userValidateHisCashbackSelection()">{{$t('pageLitteracy.summaryCashback.btnValidate')}}</button>
		</section>
		<!-- ================================================ -->
	</template>
</page-form>








<page-form :title="$t('pageTitles.proofOfPurchasePage')" v-else-if="currentPage==='proofOfPurchasePage'" key="proofOfPurchasePage">
	<template v-slot:content>
		<!-- ================================================ -->
		<section class="limitWidth formgrid">

			<div class="col1"></div>
			<div class="col2">
				<h4>{{$t('pageLitteracy.proofOfPurchase.title')}}</h4>

				<custom-input
				fieldname="IBAN"
				:placeholder="$t('inputsTexts.IBAN.placeholder')"
				translationSource="IBAN"
				dataName="IBAN"
				inputType="text"
				:idLead="lead.id"
				:label="$t('inputsTexts.IBAN.label')"
				:helper="$t('inputsTexts.IBAN.helper')"
				:isRequired="true"
				v-model.lazy:modelValue="fieldsValues.iban"
				@runCheck="checkIfMustUnlockClaimCashbackButton"
				@maintenanceDetected="activeMaintenancePage"
				></custom-input>

				<h4>{{$t('pageLitteracy.proofOfPurchase.title2')}}</h4>

				<div v-for="(item, key) of selectedCashbacks" class="field" :key="'item-' + key">

					<div class="boxgtin">

						<img :src="getCashbacksImgUrl(getItemDataToDisplay(item).code)" alt="">
						<div class="librelistfields">
							<!-- Field type LIST for EAN/gtins -->
							<div v-if="itemExistsInSynonyms(item)">
								<select-gtin
								fieldname="SelectFree"
								:dataArray="synonyms.filter(snn => parseInt(snn.idpopitem) === parseInt(item))"
								translationSource="listEan"
								:isRequired="true"
								@runCheck="updateValidityOfThisField"
								v-model.lazy:modelValue="fieldsValues.gtinsValues[item]"
								:caractersBeforeOpenList="3"
								:autocomplete="true"
								:isDisabled="false"
								class="col2"
								></select-gtin>
							</div>

							<div v-else>
								<!-- Field controls free EAN/gtins -->
								<custom-input
								:fieldname="'gtin-' + item"
								dataName="gtin"
								translationSource="freeEan"
								inputType="number"
								:isRequired="true"
								v-model.lazy:modelValue="fieldsValues.gtinsValues[item]"
								@runCheck="updateValidityOfThisField"
								:isDisabled="false"
								></custom-input>
							</div>

						</div>
					</div>

				</div>

				<h4>{{$t('pageLitteracy.proofOfPurchase.title3')}}</h4>
				<p>{{$t('pageLitteracy.proofOfPurchase.p1')}}</p>

				<!-- Proof of purchase -->
				<upload
				:label="$t('inputsTexts.uploads.ticket.label')"
				fieldname="file"
				post-upload-url-prop="/participations/upload"
				:idLead="lead.id"
				typeOfUpload="ticket"
				:isRequired="true"
				@runCheck="checkIfMustUnlockClaimCashbackButton"
				@maintenanceDetected="activeMaintenancePage"
				class="col4"
				></upload>
				<!-- :idParticipationOwner="ownerData.id" -->
			</div>

		</section>
		<section class="navipage">
			<button @click.prevent="userTryToSendHisCashbacks">{{$t('pageLitteracy.proofOfPurchase.btn_send')}}</button>
		</section>
		<!-- ================================================ -->
	</template>
</page-form>





<page-form :title="$t('pageTitles.confirmPrime')" v-else-if="currentPage==='confirmPrime'" key="confirmPrime">
	<template v-slot:content>
		<!-- ================================================ -->
		<section class="limitWidth formgrid">
			<div class="col1"></div>
			<div class="col2">

				<p>{{$t('pageLitteracy.confirmPrime.p1')}}</p>
				<p>{{$t('pageLitteracy.confirmPrime.p2')}}</p>
				<p v-html="$t('pageLitteracy.confirmPrime.warn')" class="warn"></p>
				<p>{{$t('pageLitteracy.confirmPrime.p3')}}</p>

				<p v-if="isCouponWebsiteOpen">
					{{$t('pageLitteracy.confirmPrime.p4')}} <a href="#" @click.prevent="moveToAnotherAction('ecoupons')">{{$t('pageLitteracy.confirmPrime.linktocoupons')}}</a> {{$t('pageLitteracy.confirmPrime.or')}}
					<a href="#" @click.prevent="moveToAnotherAction('ecoupons')">{{$t('pageLitteracy.confirmPrime.linktocashbacks')}}</a>.
				</p>

				<p>{{$t('pageLitteracy.confirmPrime.p5')}}</p>
				<p>{{$t('pageLitteracy.confirmPrime.p6')}}</p>

			</div>
			<div class="col1"></div>
			<div class="col1"></div>

		</section>
		<section class="navipage">
			<!-- <button @click.prevent="refreshDataAndGoToOffersPage()">{{$t('pageLitteracy.confirmPrime.btn_again')}}</button> -->
			<button @click.prevent="refreshDataAndGoToOffersPage('offers')">{{$t('pageLitteracy.confirmPrime.btn_back')}}</button>
		</section>
		<!-- ================================================ -->
	</template>
</page-form> 




<page-form :title="$t('pageTitles.confirmCashback')" v-else-if="currentPage==='confirmCashback'" key="confirmCashback">
	<template v-slot:content>
		<!-- ================================================ -->
		<section class="limitWidth formgrid">
			<div class="col1"></div>
			<div class="col2">

				<p>{{$t('pageLitteracy.confirmCashback.p1')}}</p>
				<p>{{$t('pageLitteracy.confirmCashback.p2')}}</p>
				<p>{{$t('pageLitteracy.confirmCashback.p3')}}</p>

				<p v-if="isCouponWebsiteOpen">
					<span v-if="website === 'ecoupons'">
						{{$t('pageLitteracy.confirmCashback.p4')}} 
						<a href="#" @click.prevent="moveToAnotherAction('primes')">{{$t('pageLitteracy.confirmCashback.linktocashbacks')}}</a>
						{{$t('pageLitteracy.confirmCashback.or')}}
						<a href="#" @click.prevent="moveToAnotherAction('primes')">{{$t('pageLitteracy.confirmCashback.linktoprimes')}}</a>
						{{$t('pageLitteracy.confirmCashback.p4final')}}.
					</span>
					<span v-if="website === 'cashbacks'">
						{{$t('pageLitteracy.confirmCashback.p4')}} 
						<a href="#" @click.prevent="moveToAnotherAction('ecoupons')">{{$t('pageLitteracy.confirmCashback.linktocoupons')}}</a>
						{{$t('pageLitteracy.confirmCashback.or')}}
						<a href="#" @click.prevent="moveToAnotherAction('primes')">{{$t('pageLitteracy.confirmCashback.linktoprimes')}}</a>
						{{$t('pageLitteracy.confirmCashback.p4final')}}.
					</span>
					<span v-if="website === 'primes'">
						{{$t('pageLitteracy.confirmCashback.p4')}} 
						<a href="#" @click.prevent="moveToAnotherAction('ecoupons')">{{$t('pageLitteracy.confirmCashback.linktocoupons')}}</a>
						{{$t('pageLitteracy.confirmCashback.or')}}
						<a href="#" @click.prevent="moveToAnotherAction('primes')">{{$t('pageLitteracy.confirmCashback.linktocashbacks')}}</a>
						{{$t('pageLitteracy.confirmCashback.p4final')}}.
					</span>
				</p>

				<p>{{$t('pageLitteracy.confirmCashback.p5')}}</p>
				<p>{{$t('pageLitteracy.confirmCashback.p6')}}</p>

			</div>
			<div class="col1"></div>
			<div class="col1"></div>

		</section>
		<section class="navipage">
			<button @click.prevent="refreshDataAndGoToOffersPage('offers')">{{$t('pageLitteracy.confirmCashback.btn_again')}}</button>
			<button @click.prevent="refreshDataAndGoToOffersPage('portail')">{{$t('pageLitteracy.confirmCashback.btn_back')}}</button>
		</section>
		<!-- ================================================ -->
	</template>
</page-form>





<page-form v-else-if="currentPage==='wowi' && allowFormToBeDisplayed && website === 'portail' " key="wowi-portail">
	<!-- ! MULTIPLES PAGE "WOWI", one for each "website" -->
	<!-- ==== This one is for "portail" ==== -->
	<template v-slot:content>
		<!-- ================================================ -->

		<div id="greeting">
			<h1 v-show="!userIsLogged" class="greeting" :class="{'unlogged': !userIsLogged}" v-html="$t('pageLitteracy.aboutWowi')"></h1>
			<h1 v-show="userIsLogged" class="greeting logged">{{$t('pageLitteracy.profileGreeting')}} <b>{{userInfos.firstName}}</b></h1>
		</div>

		<section id="wowibody" class="limitWidth">

			<div v-if="!userIsLogged">
				<p class="incitant">
					<a href="#" @click.prevent="moveToRegisterPage()">{{$t('pageLitteracy.wowiCashbacks.register')}}</a><span v-html="$t('pageLitteracy.wowiCashbacks.registerFor')"></span>
				</p>
			</div>

			<div class="portail">
				<div v-if="mustBePresentThisTime.includes('ecoupons')" class="imgtoecoupons hero fr" :class="{'passed': isCouponDatePassed, 'fr': localeIsFr, 'nl': localeIsNl}">
					<button @click.prevent="moveToAnotherAction('ecoupons')">{{$t('buttons.enjoyCoupons')}}</button>
					<div v-if="isCouponDatePassed" class="macaron"><div>{{$t('pageTitles.actionEnded')}}</div></div>
				</div>
				<div v-if="mustBePresentThisTime.includes('cashbacks')" class="imgtocashbacks hero fr" :class="{'passed': isCashbackDatePassed, 'fr': localeIsFr, 'nl': localeIsNl}">
					<button @click.prevent="moveToAnotherAction('cashbacks')">{{$t('buttons.enjoyCashback')}}</button>
					<div v-if="isCashbackDatePassed" class="macaron"><div>{{$t('pageTitles.actionEnded')}}</div></div>
				</div>
				<div v-if="mustBePresentThisTime.includes('primes')" class="imgtopromocodes hero fr" :class="{'passed': isPromocodeDatePassed, 'fr': localeIsFr, 'nl': localeIsNl}">
					<button @click.prevent="moveToAnotherAction('primes')">{{$t('buttons.enjoyPromocodes')}}</button>
					<div v-if="isPromocodeDatePassed" class="macaron"><div>{{$t('pageTitles.actionEnded')}}</div></div>
				</div>
			</div>

		</section>

		<!-- ================================================ -->
	</template>
</page-form>









<page-form v-else-if="currentPage==='wowi' && allowFormToBeDisplayed && website === 'cashbacks' " key="wowi-cashbacks">
	<!-- ! MULTIPLES PAGE "WOWI", one for each "website" -->
	<!-- ==== This one is for "cashbacks" ==== -->
	<template v-slot:content>
		<!-- ================================================ -->

		<div id="greeting">
			<h1>
				<span v-show="!userIsLogged" class="greeting" :class="{'unlogged': !userIsLogged}" v-html="$t('pageLitteracy.aboutWowi')"></span>
				<span v-show="userIsLogged" class="greeting logged">{{$t('pageLitteracy.profileGreeting')}} <b>{{userInfos.firstName}}</b></span>
			</h1>
		</div>

		<section id="wowibody" class="limitWidth">

			<div v-if="!userIsLogged">
				<p class="incitant">
					<a href="#" @click.prevent="moveToRegisterPage()">{{$t('pageLitteracy.wowiCashbacks.register')}}</a><span v-html="$t('pageLitteracy.wowiCashbacks.registerFor')"></span>
				</p>
			</div>

			<div v-if="userIsLogged" class="incitant">
				<p v-html="$t('pageLitteracy.wowiCashbacks.action')" style="margin-top: 0;"></p>
			</div>

			<div class="ch65">
				<p>
					<span v-html="$t('pageLitteracy.wowiCashbacks.infoReserve')"></span> <span class="fakelink" @click="toggleModaleVisibility('infoReservation')">{{$t('pageLitteracy.wowiCashbacks.moreInfo')}}</span>.
				</p>
				<p>
					<span v-html="$t('pageLitteracy.wowiCashbacks.infoAsk')"></span> <span class="fakelink" @click="toggleModaleVisibility('infoParticipation')">{{$t('pageLitteracy.wowiCashbacks.moreInfo')}}</span>.
				</p>
			</div>


			<!-- TODO I should be able to use the computed isUserAbleToSendReservedCashbacks to display only the RESERVED ones when the time is right. 
				But, maybe I receive the info from ACTION API and they are automatically in the availablePopitems then? -->

				<div class="cards">

					<card-cashback	
					v-for="(item, key) in availablePopitems" 
					:data="item"
					:key="key + '-' + item.id"
					@changeOfValue="updateSelectedCashbacksList"
					:alreadyClaimed = "isThisCashbackClaimed(item.id)"
					:reserved = "isThisCashbackReserved(item.id)"
					:disabled = "false"
					:selected = "isThisCashbackSelected(item.id)"
					></card-cashback>

					<!-- Propriété encore inutilisées mais potentiellement utiles -->
					<!--
						:timeBeforeAlert="timeLimitBeforeOfferDisplayAnAlert"
						:qttBeforeAlert="qttLimitBeforeOfferDisplayAnAlert" 		
						v-model:modelValue="fieldsValues.wowicards[card.id]"
					-->
				</div>

				<div v-if="availablePopitems.length <= 0" class="center" style="padding:4em 0 2em 0;">
					<p v-html="$t('pageLitteracy.noMoreOffers')"></p>
				</div>

				<div class="printbuttonblock middlepage">
					<div class="dowithcashbacksbuttons">
						<button class="small" @click.prevent="userAskedCashbackOperation('reserve')" :key="`BtnCbRes`" :disabled="!isThereAtLeastOneSelectedCashback || isThereOneSelectedReservedCashback">{{$t('buttons.reserveCashbacks')}}</button>
						<button class="small" @click.prevent="userAskedCashbackOperation('order')" :key="`BtnCbOrd`" :disabled="!isThereAtLeastOneSelectedCashback">{{$t('buttons.sendCashbacks')}}</button>
					</div>	
				</div>

			</section>

			<section id="probanner" class="limitWidth">

				<h3 class="green">
					{{$t('pageLitteracy.probanner.title1')}}
				</h3>
				<h4>
					{{$t('pageLitteracy.probanner.title2')}}
				</h4>

				<a class="noStyle" v-if="$i18n.locale === 'fr_BE'" href="https://pgprofessional.pg.com/?lang=fr" target="_blank">
					<img src="~@/assets/img/probanner_fr.jpg" alt="">
				</a>

				<a class="noStyle" v-if="$i18n.locale === 'nl_BE'" href="https://pgprofessional.pg.com/" target="_blank">
					<img src="~@/assets/img/probanner_nl.jpg" alt="">
				</a>

			</section>




			<section class="limitWidth">

				<h3 v-if="noMoreAvailablePopitems.length > 0">{{$t('pageLitteracy.pastOffers')}}</h3>


				<div class="cards">

					<card-cashback	
					v-for="(item, key) in noMoreAvailablePopitems" 
					:data="item"
					:key="key + '-' + item.id"
					@changeOfValue="updateSelectedCashbacksList"
					:alreadyClaimed = "isThisCashbackClaimed(item.id)"
					:reserved = "false"
					:disabled = "true"
					></card-cashback>

				</div>



			</section>
			<!-- ================================================ -->
		</template>
	</page-form>








	<page-form v-else-if="currentPage==='wowi' && allowFormToBeDisplayed && website === 'ecoupons' " key="wowi-ecoupons">
		<!-- ! MULTIPLES PAGE "WOWI", one for each "website" -->
		<!-- ==== This one is for "ecoupons" ==== -->
		<template v-slot:content>
			<!-- ================================================ -->


			<div id="greeting">
				<h1>
					<span v-show="!userIsLogged" class="greeting" :class="{'unlogged': !userIsLogged}" v-html="$t('pageLitteracy.aboutWowi')"></span>
					<span v-show="userIsLogged" class="greeting logged">{{$t('pageLitteracy.profileGreeting')}} {{userInfos.firstName}}</span>
				</h1>
				<p v-show="userIsLogged && dateStatus != 'actionEnded'" class="incitant" v-html="$t('pageLitteracy.wowiLoggedGreeting')"></p>
				<p v-show="userIsLogged && dateStatus === 'actionEnded'" class="incitant" v-html="$t('pageLitteracy.wowiLoggedActionEnded')"></p>

			</div>

			<section id="wowibody" class="limitWidth">

				<div v-if="!userIsLogged">
					<p class="incitant">
						<a href="#" @click.prevent="moveToRegisterPage()">{{$t('pageLitteracy.wowiCashbacks.register')}}</a> <span v-html="$t('pageLitteracy.wowiCashbacks.registerFor')"></span>
					</p>
				</div>

				<div class="cards">

					<card-coupon
					v-for="(item, key) in sortedLiveWowiCards"
					:data="item"
					:key="key + '-' + item.id"
					@changeOfValue="updateWowiCardList"
					:alreadyClaimed = "isThisCouponClaimed(item.id)"
					:disabled = "false"
					:selected = "item.selected"
					></card-coupon>

<!--
					<wowi-card v-for="(card, key) in sortedLiveWowiCards"
					:identifier="card.id"
					:packshot="card.imageName"
					:leftoffers="card.offersStillAvailableCounter"
					:price="card.nobon.nvaleu"
					:description="card.description"
					:expireson="card.onlineenddate"
					:selected="card.selected"
					:alreadyClaimed="isThisCardClaimed(card.id)"
					:key="key + '-' + card.id"
					:timeBeforeAlert="timeLimitBeforeOfferDisplayAnAlert"
					:qttBeforeAlert="qttLimitBeforeOfferDisplayAnAlert" 
					:totalQuantity="card.maxcounter"
					v-model:modelValue="fieldsValues.wowicards[card.id]"
					@changeOfValue="updateWowiCardList"
					></wowi-card>
				-->
			</div>

			<div v-if="sortedLiveWowiCards.length <= 0" class="center" style="padding:4em 0 2em 0;">
				<p v-html="$t('pageLitteracy.noMoreOffers')"></p>
			</div>

			<div class="printbuttonblock middlepage">
				<div class="printmycouponbuttons">

					<button class="small" 
					@click.prevent="tryToReceiveCoupons" 
					:key="`Test-${(Math.random()*300000).toFixed(4)}`" 
					:disabled="!isThereAtLeastOneSelectedProduct"
					>{{$t('buttons.emailMyCoupons')}}</button>

				</div>	
			</div>

			<h3 v-if="sortedDeadWowiCards.length > 0">{{$t('pageLitteracy.pastOffers')}}</h3>

			<div class="cards" v-if="sortedDeadWowiCards.length > 0">

				<card-coupon
				v-for="(item, key) in sortedDeadWowiCards"
				:data="item"
				:key="key + '-' + item.id"
				@changeOfValue="updateWowiCardList"
				:alreadyClaimed = "isThisCouponClaimed(item.id)"
				:disabled = "false"
				:selected = "false"
				></card-coupon>

				<!--
				<wowi-card v-for="(card, key) in sortedDeadWowiCards"
				:selected="card.selected"
				:alreadyClaimed="isThisCardClaimed(card.id)"
				:timeBeforeAlert="timeLimitBeforeOfferDisplayAnAlert"
				:qttBeforeAlert="qttLimitBeforeOfferDisplayAnAlert" 
				v-model:modelValue="fieldsValues.wowicards[card.id]"
				@changeOfValue="updateWowiCardList"
				></wowi-card>
			-->
		</div>

		<div class="printbuttonblock endpage" v-if="sortedDeadWowiCards.length > 0">
			<div class="printmycouponbuttons">

				<button class="small" 
				@click.prevent="tryToReceiveCoupons" 
				:key="`Test-${(Math.random()*300000).toFixed(4)}`" 
				:disabled="!isThereAtLeastOneSelectedProduct"
				>{{$t('buttons.emailMyCoupons')}}</button>

			</div>
		</div>

	</section>
	<!-- TODO See in cashback section: I used a section.navipage instead, that manage one or two buttons perfectly. -->

	<!-- ================================================ -->
</template>
</page-form>



























<page-form v-else-if="currentPage==='wowi' && allowFormToBeDisplayed && website === 'primes' " key="wowi-primes">
	<!-- WIP Introduction des code promo ======================================================= -->
	<!-- WIP Introduction des code promo ======================================================= -->
	<!-- WIP Introduction des code promo ======================================================= -->
	<!-- ! MULTIPLES PAGE "WOWI", one for each "website" -->
	<!-- ==== This one is for "primes" ==== -->
	<template v-slot:content>
		<!-- ================================================ -->


		<div id="greeting">
			<h1>
				<span v-show="!userIsLogged" class="greeting" :class="{'unlogged': !userIsLogged}" v-html="$t('pageLitteracy.aboutWowi')"></span>
				<span v-show="userIsLogged" class="greeting logged">{{$t('pageLitteracy.profileGreeting')}} {{userInfos.firstName}}</span>
			</h1>
			<p v-show="userIsLogged && dateStatus != 'actionEnded' && availableOrderedItemsList > 0" class="incitant" v-html="$t('pageLitteracy.wowiLoggedGreeting')"></p>
			<p v-show="userIsLogged && dateStatus === 'actionEnded'" class="incitant" v-html="$t('pageLitteracy.wowiLoggedActionEnded')"></p>

		</div>


		<section id="wowibody" class="limitWidth">

			<div v-if="!userIsLogged">
				<p class="incitant">
					<a href="#" @click.prevent="moveToRegisterPage()">{{$t('pageLitteracy.wowiCashbacks.register')}}</a> <span v-html="$t('pageLitteracy.wowiCashbacks.registerFor')"></span>
				</p>
			</div>

			<!-- WIP ========== START WIP 1933 ========== -->
			<div class="cards">
				<card-prime
				v-for="(item, key) in availableOrderedItemsList"
				:data="item"
				:key="'prime-' + key + '-' + item.id"
				@changeOfValue="updateWowiCardList"
				:alreadyClaimed = "isThisPrimeClaimed(item.id)"
				:disabled = "false"
				:selected = "item.selected"
				></card-prime>
			</div>
			<!-- WIP ========== END WIP 1933 ========== -->

			<div v-if="availableOrderedItemsList.length <= 0" class="center" style="padding:4em 0 2em 0;">
				<p v-html="$t('pageLitteracy.noMoreOffers')"></p>
			</div>

			<div class="printbuttonblock middlepage" v-if="availableOrderedItemsList.length > 0">
				<div class="printmycouponbuttons">

					<button class="small" 
					@click.prevent="tryToReceivePrimes" 
					:key="`Test-${(Math.random()*300000).toFixed(4)}`" 
					:disabled="!isThereAtLeastOneSelectedProduct"
					>{{$t('buttons.emailMyPromocode')}}</button>

				</div>	
			</div>

			<h3 v-if="unavailableOrderedItemsList.length > 0">{{$t('pageLitteracy.pastOffers')}}</h3>

			<div class="cards" v-if="unavailableOrderedItemsList.length > 0">

				<card-prime
				v-for="(item, key) in unavailableOrderedItemsList"
				:data="item"
				:key="'prime-' + key + '-' + item.id"
				@changeOfValue="updateWowiCardList"
				:alreadyClaimed = "isThisPrimeClaimed(item.id)"
				:disabled = "true"
				:selected = "false"
				></card-prime>

			</div>

			<div class="printbuttonblock endpage" v-if="unavailableOrderedItemsList.length > 0 && availableOrderedItemsList > 0">
				<div class="printmycouponbuttons">

					<button class="small" 
					@click.prevent="tryToReceivePrimes" 
					:key="`Test-${(Math.random()*300000).toFixed(4)}`" 
					:disabled="!isThereAtLeastOneSelectedProduct"
					>{{$t('buttons.emailMyPromocode')}}</button>

				</div>
			</div>

		</section>
		<!-- TODO See in cashback section: I used a section.navipage instead, that manage one or two buttons perfectly. -->
		<!-- WIP END Introduction des code promo ======================================================= -->
		<!-- WIP END Introduction des code promo ======================================================= -->
		<!-- WIP END Introduction des code promo ======================================================= -->
		<!-- ================================================ -->
	</template>
</page-form>



































<page-form :title="$t('pageTitles.wrongCode')" v-else-if="currentPage==='wrongcode'" key="wrongcode">
	<template v-slot:content>
		<!-- ================================================ -->
		<section>
			<div class="simpletext">
				<p v-html="$t('pageLitteracy.wrongCode.text')"></p>
				<p v-if="registrationRequired">{{$t('pageLitteracy.wrongCode.textregistration')}}</p>
			</div>
		</section>
		<!-- ================================================ -->
	</template>
	<template v-slot:nav v-if="registrationRequired">	
		<section class="navigation">			
			<button @click.prevent="openRegistrationPage()">{{$t('navigation.signup')}}</button>
		</section>
	</template>
</page-form>






<page-form :title="$t('pageTitles.wrongCode2')" v-else-if="currentPage==='wrongcode2'" key="wrongcode2">
	<template v-slot:content>
		<!-- ================================================ -->
		<section>
			<div class="simpletext">
				<p v-if="registrationRequired" v-html="$t('pageLitteracy.wrongCode.errorWithReparticipation')"></p>
				<p class="copy" v-html="getUrlParamsHTML()"></p>
			</div>
		</section>
		<!-- ================================================ -->
	</template>
</page-form>






<page-form :title="$t('pageTitles.alreadySend')" v-else-if="currentPage==='participated'" key="participated">
	<template v-slot:content>
		<!-- ================================================ -->
		<section>
			<div class="simpletext">
				<p>{{$t('pageLitteracy.alreadySend')}}</p>
			</div>
		</section>
		<!-- ================================================ -->
	</template>
</page-form>























<page-form :title="$t('pageTitles.sorryYouLeave')" v-else-if="currentPage==='deleteAccount'" key="deleteAccount">
	<!-- WIP ========== START WIP 2361 ========== -->
	<!-- WIP ========== START WIP 2361 ========== -->
	<!-- WIP ========== START WIP 2361 ========== -->
	<!-- WIP ========== START WIP 2361 ========== -->
	<!-- WIP ========== START WIP 2361 ========== -->

	<template v-slot:content>
		<!-- ================================================ -->
		<section class="limitWidth formgrid">
			<div class="col1"></div>
			<div class="col2">

				<select-free
				fieldname="SelectFree"
				:dataArray="unsubscribeRefsList"
				:label="$t('inputsTexts.reasontoleave.label')"
				:placeholder="$t('inputsTexts.reasontoleave.placeholder')"
				:helper="$t('inputsTexts.reasontoleave.helper')"
				:isRequired="true"
				@runCheck="checkIfUnlockUnsubscribeButton"
				@maintenanceDetected="activeMaintenancePage"
				v-model.lazy:modelValue="fieldsValues.reasonToUnsubscribeObj"
				:caractersBeforeOpenList="3"
				:autocomplete="false"
				:isDisabled="false"
				class="col2"
				>
			</select-free>

			<text-area
			v-if="fieldsValues.reasonToUnsubscribeObj?.code === 'OTH'"
			fieldname="reasontoleave"
			:label="$t('inputsTexts.freereasontoleave.label')"
			:placeholder="$t('inputsTexts.freereasontoleave.placeholder')"
			:helper="$t('inputsTexts.freereasontoleave.helper')"
			:isRequired="false"
			v-model.lazy:modelValue="fieldsValues.commentOnReasonToLeave"
			@runCheck="log('test')"
			class="col2"
			></text-area>

			<free-input
			fieldname="deleteMe"
			translationSource="deleteMe"
			:displayErrors="false"
			inputType="text"
			name="deleteMe"
			autocompleteName="new-password"
			:acceptedValues="['LEAVE', 'VERLATEN', 'QUITTER']"
			:isEmptyValueTolerated="true"
			:isRequired="true"
			v-model.lazy:modelValue="fieldsValues.deleteMe"
			@runCheck="storeUnregisterSecureButtonState"
			:isDisabled="false"
			></free-input>
		</div>
	</section>

	<section class="navipage">
		<div class="center">
			<button class="red" :disabled="unregisterButtonDisabled" @click="userConfirmedUnregistration">{{ $t('navigation.unregister') }}</button>
			<p v-html="$t('navigation.irreversible')"></p>
		</div>

		<button @click="goto('profile')">Annuler</button>		
	</section>
	<!-- ================================================ -->
</template>
<!-- WIP ========== END WIP 2361 ========== -->
<!-- WIP ========== END WIP 2361 ========== -->
<!-- WIP ========== END WIP 2361 ========== -->
<!-- WIP ========== END WIP 2361 ========== -->
<!-- WIP ========== END WIP 2361 ========== -->
</page-form>



<page-form :title="$t('pageLitteracy.confirmDelete.title')" v-else-if="currentPage==='confirmDelete'" key="confirmDelete">
	<template v-slot:content>
		<!-- ================================================ -->
		<section>
			<div class="simpletext">
				<p v-html="$t('pageLitteracy.confirmDelete.textes.p1')"></p>
				<p v-html="$t('pageLitteracy.confirmDelete.textes.p2')"></p>
				<p>
					<em v-html="$t('pageLitteracy.confirmation.textes.signature')"></em>
				</p>
			</div>
			<div class="center" style="margin-top:4em;">
				<button @click.prevent="refreshDataAndGoToOffersPage('offers')">{{$t('buttons.backToOffers')}}</button>
			</div>
		</section>
		<!-- ================================================ -->
	</template>
</page-form>












<page-form :title="$t('pageLitteracy.confirmation.title')" v-else-if="currentPage==='confirmation'" key="confirmation">
	<template v-slot:content>
		<!-- ================================================ -->
		<section>
			<div class="simpletext">
				<p v-html="$t('pageLitteracy.confirmation.textes.p1')"></p>
				<p v-html="$t('pageLitteracy.confirmation.textes.p2')"></p>
				<p v-html="$t('pageLitteracy.confirmation.textes.warn')" class="warn"></p>
				<p v-html="$t('pageLitteracy.confirmation.textes.p3')"></p>
				<p>
					<em v-html="$t('pageLitteracy.confirmation.textes.signature')"></em>
				</p>
			</div>
			<div class="center" style="margin-top:4em;">
				<button @click.prevent="refreshDataAndGoToOffersPage('offers')">{{$t('buttons.backToOffers')}}</button>
			</div>
		</section>
		<!-- ================================================ -->
	</template>
</page-form>




</transition>






















<!-- 
ADMIN PANEL

-->
<div id="cheatnav" class="debug" v-if="isDevNavVisible && isAdminNavAllowed && allowFormToBeDisplayed" :class="{'retracted':cheatPanelHidden}">
	<div class="swiper" @click="toggleCheatPanel">
		<span v-if="cheatPanelHidden">&lt;</span>
		<span v-if="!cheatPanelHidden">&gt;</span>
	</div>

	<div class="innercheat">

		<div style="padding:0.25em 0; font-size: 1.2em; font-weight: bold;">Hdref: {{lead.referencehd}} — Id: {{lead.id}}</div>



		<fieldset>
			<legend>Infos</legend>
			Acceptance start: <strong>{{getHumanReadableDate(actionFullDates?.registration?.start)}}</strong> | end: <strong>{{getHumanReadableDate(actionFullDates?.participation?.end)}}</strong><br>
			Display start date (+6 week): <strong>{{getHumanReadableDate(addSixWeeksTo(actionFullDates?.registration?.start))}}</strong>
			<hr>
			Registration Required: <strong>{{registrationRequired}}</strong><br>
			Survey possible: <strong>{{thereIsASurveyInThisAction}}</strong><br>
			Participations: <strong>{{lead.participations_total}}/{{lead.maxparticipationcounter}} - {{lead.participations_left}} left</strong><br>
			<hr>
			Current page technical name: <strong>{{currentPage}}</strong><br>
			Current locale: <strong>{{$i18n.locale}}</strong><br>
			Form display allowed: <strong>{{allowFormToBeDisplayed}}</strong><br>
			
		</fieldset>



		<fieldset>
			<legend>Navigation: "the user's form"</legend>
			<menu>
				<li @click="changeWebsiteTo('portail')" :class="{active: website === 'portail'}">Portail</li>
				<li @click="changeWebsiteTo('ecoupons')" :class="{active: website === 'ecoupons'}">Ecoupons</li>
				<li @click="changeWebsiteTo('cashbacks')" :class="{active: website === 'cashbacks'}">Cashbacks</li>
				<li @click="changeWebsiteTo('primes')" :class="{active: website === 'primes'}">Primes</li>
			</menu>
			<div>COMMONS</div>
			<menu>
				<!-- COMMONS -->
				<li @click="refreshDataAndGoToOffersPage()" :class="{active: currentPage == 'wowi'}">Wowi</li>
				<li @click="goto('register')" :class="{active: currentPage == 'register'}">Wowi Register</li>

				<li @click="goto('resetPassword')" :class="{active: currentPage == 'resetPassword'}">Reset Password</li>
				<li @click="goto('confirmLinkSent')" :class="{active: currentPage == 'confirmLinkSent'}">confirmLinkSent</li>

				<li @click="goto('changePassword')" :class="{active: currentPage == 'changePassword'}">Change Password</li>
				<li @click="goto('confirmReset')" :class="{active: currentPage == 'confirmReset'}">Confirm Reset</li>

				<li @click="goto('modifLoginPass')" :class="{active: currentPage == 'modifLoginPass'}">Modify Login & Pass</li>
				<li @click="goto('warningBeforePrint')" :class="{active: currentPage == 'warningBeforePrint'}">Warning before print</li>

				<li @click="goto('modifProfile')" :class="{active: currentPage == 'modifProfile'}">Modify Profile</li>
				<li @click="goto('profile')" :class="{active: currentPage == 'profile'}">Profile</li>
				<li @click="goto('deleteAccount')" :class="{active: currentPage == 'deleteAccount'}">Delete Account</li>
				<li @click="goto('confirmDelete')" :class="{active: currentPage == 'confirmDelete'}">Confirm. Deleted</li>
			</menu>
			<div>COUPONS</div>
			<menu>
				<!-- COUPONS -->
				<li @click="goto('printcoupon')" :class="{active: currentPage == 'printcoupon'}">Print eCoupons</li>
				<li @click="goto('confirmation')" :class="{active: currentPage == 'confirmation'}">Coupons confirm.</li>
				<li @click="goto('iframe')" :class="{active: currentPage == 'iframe'}">Iframe coupons</li>
				<li @click="goto('newConsent')" :class="{active: currentPage == 'newConsent'}">New CG Consent</li>
			</menu>
			<div>CASHBACKS</div>
			<menu>
				<!-- CASHBACKS -->
				<li @click="goto('confirmReservation')" :class="{active: currentPage == 'confirmReservation'}">Reservation confirm.</li>
				<li @click="goto('summaryCashback')" :class="{active: currentPage == 'summaryCashback'}">CB summary</li>
				<li @click="goto('proofOfPurchasePage')" :class="{active: currentPage == 'proofOfPurchasePage'}">Send tickets</li>
				<li @click="goto('confirmCashback')" :class="{active: currentPage == 'confirmCashback'}">CB confirm.</li>
			</menu>
			<div>PROMOCODES</div>
			<menu>
				<!-- PRIMES -->
				<li @click="goto('confirmPrime')" :class="{active: currentPage == 'confirmPrime'}">Prime confirm.</li>

			</menu>
			<hr>
			<menu>
				<button @click="toggleModaleVisibility('login')">Login modale</button>
				<button @click="toggleModaleVisibility('code')">Code modale</button>
				<button @click="toggleModaleVisibility('error')">Error modale</button>
				<button @click="toggleModaleVisibility('printCoupons')">Print my coupons</button>
				<button @click="toggleModaleVisibility('infoReservation')">Reservation modale</button>
				<button @click="toggleModaleVisibility('infoParticipation')">Participation modale</button>
				<button @click="toggleModaleVisibility('emptyOrder')">Empty order modale</button>
				<button @click="getListOfSelectedOffersIds">Log list of selected coupons</button>
				<button @click="getListOfSelectedCashbacksOffersIds">Log list of selected cashbacks</button>
			</menu>
		</fieldset>

		<fieldset>
			<legend>Fixed pages in all forms</legend>
			<menu>
				<li @click="goto('beforeStart')" :class="{active: currentPage == 'beforeStart'}">Before Action</li>
				<li @click="goto('actionEnded')" :class="{active: currentPage == 'actionEnded'}">After Action</li>
				<li @click="goto('maintenance')" :class="{active: currentPage == 'maintenance'}">Maintenance</li>
				<li @click="goto('loading')" :class="{active: currentPage == 'loading'}">Loading</li>
				<li @click="goto('pageError')" :class="{active: currentPage == 'pageError'}">Error</li>
			</menu>
		</fieldset>

		<fieldset>
			<legend>Logs</legend>
			<menu>
				<button @click="displayRequiredFieldsList()">Mandatory fields</button>
				<button @click="displayMetaFieldsList()">Meta fields</button>
				<button @click="showDataToBeSent()">Particip. data</button>
				<button @click="testAllLogs()">Custom logs</button>
				<button @click="displayFieldsValues()">FieldsValues</button>
				<button @click="displayFieldsValidity()">Fields Validity</button>
				<button @click="displaySelectedCars()">Cards: selected</button>
				<button @click="displayConsumedCars()">Cards: consumed</button>
				<button @click="displayUserInfo()">User info</button>
			</menu>
		</fieldset>

		<fieldset>
			<legend>Others</legend>
			<menu>

				<li @click="goto('choosecouponway')" :class="{active: currentPage == 'choosecouponway'}">How to print coupons?</li>
				<li @click="goto('printcoupon')" :class="{active: currentPage == 'printcoupon'}">Print my coupons</li>
				<li @click="goto('confirmationCoupon')" :class="{active: currentPage == 'confirmationCoupon'}">Confirmation Coupon</li>

				<li @click="goto('goodCouponPrinting')" :class="{active: currentPage == 'goodCouponPrinting'}">goodCouponPrinting</li>
				<li @click="goto('badCouponPrinting')" :class="{active: currentPage == 'badCouponPrinting'}">badCouponPrinting</li>

				<button @click="forceBackToWowi()">Force context. url</button>

				<button @click="storeCryptedIdInLocalStorage('Lorem')">Store CID</button>

				<button @click="unlockButtons()">Unlock buttons</button>
				<button @click="loadFor5Sec()">Load 3 sec</button>
				<div>
					<button @click="displayModaleMessages('Error on demand', ['You wanted it, you have it!', 'Do\'h!'], 'alert')" style="width:49%; margin-right:1%;">Error</button>
					<button @click="displayModaleMessages('Info on demand', ['This information is free.'], 'info')" style="width:49%; margin-left:1%;">Info</button>
				</div>
				<div>
					<button @click="setLang(1)" style="width:49%; margin-right:1%;">=> FR</button>
					<button @click="setLang(2)" style="width:49%; margin-left:1%;">=> NL</button>
				</div>
				<button @click="allowFormToBeDisplayed = !allowFormToBeDisplayed">Toggle allow form</button>
				<button @click="displayLocalStorageContent()">LocalStorage content</button>
				<button @click="displayStoreContent()">Store content</button>
				<button @click="deleteCurrentUser()" class="red" v-if="userIsLogged">Delete current user</button>
			</menu>
		</fieldset>
	</div>

</div>
</div>
</template>
<!-- 
END ADMIN PANEL

-->

































<!-- ================================================================================== -->

<script>
	/* ======== OTHERS ======== */
	import { toRaw } from 'vue';
	import 'intl-tel-input/build/css/intlTelInput.css';
	import config from "../config";

	/* ======== COMPONENTS ======== */
	import PageForm from '@/components/PageForm';
	// import Toaster from '@/components/Toaster';
	import FreeInput from "@/components/FreeInput";
	import PhoneInput from '@/components/PhoneInput';
	import CrmInput from "@/components/CrmInput";
	import CustomCheckbox from '@/components/CustomCheckbox';
	// import WowiCard from "@/components/WowiCard";
	import Modale from "@/components/Modale";
	import CardCashback from "@/components/CardCashback";
	import CardCoupon from "@/components/CardCoupon";
	import CardPrime from "@/components/CardPrime";
	import CustomInput from "@/components/CustomInput";
	// import MetaInput from "@/components/MetaInput";
	// import CustomRadio from '@/components/CustomRadio';
	// import Spacer from '@/components/Spacer';
	// import Breadcrumbs from '@/components/Breadcrumbs';
	// import IconsSvg from '@/components/IconsSvg';
	/* INPUTS (user TYPE answers) */
	/* Other fields */
	import Upload from "@/components/Upload";
	// import GoogleSelect from "@/components/GoogleSelect";
	import SelectFree from "@/components/SelectFree";
	import SelectGtin from "@/components/SelectGtin";
	import SelectApi from "@/components/SelectApi";
	import TextArea from "@/components/TextArea";


	// import SelectMeta from "@/components/SelectMeta";
	/* TO BE REPLACED */
	// import Popitem from '@/components/Popitem';
	//* import CustomSelect from '@/components/CustomSelect';
	// import MetaCustomSelect from '@/components/MetaCustomSelect';



	/* ======== MIXINS ======== */
	// import GoogleAPI from "../mixins/GoogleAPI";
	import ga from "../mixins/ga";
	import pixel from "../mixins/pixel";
	import Functions from "../mixins/Functions";
	import APICallBacks from "../mixins/APICallbacks";
	import APICallBacksCRM from "../mixins/APICallbacksCRM";
	import APICalls from "../mixins/APICalls";
	import Cheat from "../mixins/Cheat";
	import ecoupons from "../mixins/ecoupons";
	import errorsManager from "../mixins/errorsManager";
	import FL from "../mixins/floodlight";
	import customLog from "../mixins/CustomLog";
	import ActionDates from "../mixins/ActionDates";
	import openPDF from "../mixins/openpdf";
	import fieldsvalidator from "../mixins/fieldsvalidity";
	import metaFields from "../mixins/metaFields";
	import modales from "../mixins/modales";
	import ToasterFunctions from "../mixins/ToasterFunctions";
	import NonAutomatedData from "../mixins/NonAutomatedData";
	import sysinfos from "../mixins/sysinfos";
	import wowipixel from "../mixins/wowipixel";
	import wowilogic from "../mixins/wowilogic";
	import wowiflow from "../mixins/wowiflow";
	import utm from "../mixins/utm";
	import participationsData from "../mixins/participationsData";



	export default {
		name:"Form",

		mixins: [
			ActionDates, 
			APICalls, 
			APICallBacks,
			APICallBacksCRM,
			customLog,

			ecoupons,
			errorsManager,

			fieldsvalidator,
			FL, 
			Functions, 
			ga,
			// GoogleAPI, 
			metaFields,
			modales,
			NonAutomatedData,
			openPDF,
			participationsData,
			pixel, 
			sysinfos,
			ToasterFunctions, // still used apparently, see in the vicinity of API calls
			utm,
			wowipixel,
			wowilogic,
			wowiflow,
			/* DEBUG */
			Cheat,
			],

		components: {
			/* ==== Dropdowns ==== */
			SelectApi,
			SelectFree,
			SelectGtin,
			/* ==== Inputs ==== */
			CardCashback,
			CardCoupon,
			CardPrime,
			CrmInput,
			CustomCheckbox,
			CustomInput,
			FreeInput,
			Modale,
			PhoneInput,
			TextArea,
			Upload,
			/* ==== Tools ==== */
			PageForm,
			// Toaster,
			// WowiCard,	
		},















/*
DATA

*/

		data () {
			return {
				isAdminNavAllowed : config.isAdminNavAllowed, 
				isMaintenanceCheckRequired : config.allowMaintenanceCheck,
				isWebsiteInProduction : config.isWebsiteInProduction,
				userIsThereToPrintCoupon: false,
				currentCodes: null,
				ownerData: {},
				popitemlist : [],
				synonyms: [],
				reparticipation: false,
				metaIds: [],
				dateMessage: null,
				smsHelper: '', // used as modelValue. Maybe change name. …later.
				pendingParticipationSent: false,
				composedDataForParticipation: {},
				fullTestPanelHidden: true,
				registrationRequired: true,
				isUploadPresent: false,
				listOfAllowedCountries: null,
				filteredListOfPostalCode: [],
				locale: null,
				menuOpen: false,
				participRefs: [],
				justReserved: [],

				/* List for cashbacks */
				listOfReservedCashbacks: [],
				listOfConsumedCashbacks: [],
				listOfAllCashbacksStatus: [],

				/* List for profile */
				unsubscribeRefsList: [],

				fieldsValues : {	
					deleteMe: "",
					wowicards: {	/* Keys are wowi item ids added dynamically : {1234:true}, {5678:false} */},
					cashbacks: {/* Same for cashback? */},
					gtinsValues: {/* Same for gtins? */},
					CRMParticipationOwnerId: null,
					dataDestination: {
						api: null,
						meta: null,
						free: 3
					},
					bic:null,
					iban:null,
					idTitle: null,
					idGender: null,
					uniqueCode : null,
					firstName: null,
					lastName: null,
					email: null,
					password: null,
					popitem: null, // * old one
					popitems:{
						/* Need as many popitem as user can declare */
						popitem1 : null,
						popitem2 : null,
						popitem3 : null,
					},
					demo: null,
					retailer: null,
					freeRetailer:null,
					purchaseDate: null,
					serialNumber: null,
					quantity: 0, // Must be 0 and not null to avoid a for-loop problem. => To be solved.
					language: null,
					birthdate : null,
					phoneNumber1: null,
					phoneNumber2: null,
					/* Adresse 1 */
					street1 : null,
					street2 : null,
					houseNumber : null,
					boxNumber : null,
					postalCode : null, /* minisite and user details */
					crmpostalcodeId: null, /* wowi */
					city : null,
					idCountryCode : null,

					/* Adresse 2 (1th time in 17017) */
					/* … */

					societe1 : null,
					societe2 : null,
					vatCountry: null,
					vatCode : null,
					confidentiality :null,
					generalCondition : 0,
					optin: 0, // default value required
					survey:{
						/* Automatically fills after the load */
					},

					/* profile unsubscribe fields */
					reasonToUnsubscribeObj: null,
					commentOnReasonToLeave: null,
				},
				// flag for api call
				cashbackDataAreReceived: false,

				// Related to UNREGISTER (DELETE ACCOUNT)
				unregisterButtonDisabled: true,
				unregisterSecureButtonIsValid: false,
				userJustDeletedHisAccount: false,

			};
		},











/*
METHODS

*/

		methods: {	

			checkIfUnlockUnsubscribeButton(param){
				this.updateValidityOfThisField(param);
				// when the field is updated, now check if button could be unlocked
				this.updateUnregisterButtonState();
			},

			storeUnregisterSecureButtonState(secureWord){
				/* secureWord = {fieldname: 'deleteMe', valid: true} */
				this.unregisterSecureButtonIsValid = secureWord.valid;
				// when the field is updated, now check if button could be unlocked
				this.updateUnregisterButtonState();
			},

			updateUnregisterButtonState(){
				this.log("updateUnregisterButtonState", 'function');
				this.unregisterButtonDisabled = true;
				this.log(`secureButton says ${!this.unregisterSecureButtonIsValid}, reasonField says ${!this.fieldsValues.reasonToUnsubscribeObj.description}`, 'low');
				this.log(`If both are FALSE, the button should be enabled`, 'low');
				this.unregisterButtonDisabled = !this.unregisterSecureButtonIsValid || !this.fieldsValues.reasonToUnsubscribeObj;
			},

			getDescriptionById(arr, id){
				// Given an ARRAY and an ID, returns the DESCRIPTION of the object with ID === ID.
				// arr = [ {id: 1, description: "lorem"},{…} ]
				this.log(`Getting description for id ${id}`, "wip");
				const foundObj = arr.find(item => item.id === id);
				return foundObj ? foundObj.description : null;
			},

			/* WIP ========== START WIP 2962 ========== */
			userConfirmedUnregistration(){
				this.log("userConfirmedUnregistration", 'todo');
				this.log(`Trying to delete user ${this.userInfos.id}...`);
				let comments = this.fieldsValues.reasonToUnsubscribeObj.description;
				// let code = this.fieldsValues.reasonToUnsubscribeObj.code;
				this.log(`User says: ${this.fieldsValues.reasonToUnsubscribeObj.description}`, 'low');
				let data = {
					"idCRMParticipationOwner": this.userInfos.id,
					"comments": comments,
					// "code": code, // not required
				};

				if(this.fieldsValues.reasonToUnsubscribeObj.code === 'OTH' && this.fieldsValues.commentOnReasonToLeave){
					data.comments = this.fieldsValues.commentOnReasonToLeave;
				}

				this.log(`Before going through Axios, data looks like: `, 'low');
				this.log(data);

				// Send the unsubscribe request
				this.postToAxios(this.callConfigs.postUnsubscribeUser, data);
			},
			/* WIP ========== END WIP 2962 ========== */

			cb_postUnsubscribeUserPassed(response){
				this.log(`reponse positive`, 'green');
				this.log(response);
				this.userJustDeletedHisAccount = true;
				this.userWantToLogout();
			},
			cb_postUnsubscribeUserFailed(error){
				this.log(`reponse negative`, 'red');
				console.log(error);
			},

			askDeleteAccountConfirmation(){
				this.log("askDeleteAccountConfirmation", 'function');
				this.getUnsubscribeReferences();
				this.goto("deleteAccount");
			},

			forceBackToWowi(){
				// Force a way back to The adequat wowi website.
				let theDestinationUrl = "portail";
				fbq('trackCustom', 'RetourAuxOffres');

				// urlsSites
				if (this.website === "ecoupons"){
					theDestinationUrl = "coupons"
				}
				if (this.website === "cashbacks"){
					theDestinationUrl = "cashbacks"
				}
				if (this.website === "primes"){
					theDestinationUrl = "primes"
				}
				let url = (this.isAdminNavAllowed) ? this.urlsSites[theDestinationUrl].acceptance : this.urlsSites[theDestinationUrl].production;
				window.location.replace(url);
			},

			forceBackToPortail(){
				let url = (this.isAdminNavAllowed) ? this.urlsSites.portail.acceptance : this.urlsSites.portail.production;
				window.location.replace(url);
			},


			toggleMenu(){
				this.menuOpen = !this.menuOpen;
			},


			getUrlUtmsParamString(){
				/* TODO goes to the store, gets utm, create the suffix, returns it */
				let str = "";
				let objUtmKnown = this.$store.getters.getUtms;
				for (let item in objUtmKnown){
					if (objUtmKnown[item]){
						str += `&utm_${item}=${objUtmKnown[item]}`;
					}
				}
				return str;
			},

			getUrlLangParamString(){
				/* Just in case there is no lang, default value is applied */
				/* So there is ALWAYS something between the ? and the first & in the url recomposition */
				let lang = 'nl';
				lang = this.$store.getters.getLang;
				return `lang=${lang}`;
			},

			doTheRedirection(url){
				// this.log(url + '?' + this.getLangSuffix() + this.getUtmListForUrl());
				window.location.href = url + '?' + this.getUrlLangParamString() + this.getUrlUtmsParamString();

				/* INFO above don't work on localhost. But the scheme below is not ideal either */
				// returnUrl = window.location.protocol + "//" + window.location.host + Path
				// window.location.href = window.location.protocol + "//" + window.location.host + '?' + this.getUrlLangParamString() + this.getUrlUtmsParamString();
			},

			moveToCouponsAction(){
				let url = (this.isAdminNavAllowed) ? this.urlsSites.coupons.acceptance : this.urlsSites.coupons.production;
				// this.website = "ecoupons"; // when I stay on localhost
				this.doTheRedirection(url);
			},

			moveToCashbacksAction(){
				let url = (this.isAdminNavAllowed) ? this.urlsSites.cashbacks.acceptance : this.urlsSites.cashbacks.production;
				// this.website = "cashbacks"; // when I stay on localhost
				this.doTheRedirection(url);
			},

			moveToPrimesAction(){
				let url = (this.isAdminNavAllowed) ? this.urlsSites.primes.acceptance : this.urlsSites.primes.production;
				// this.website = "primes"; // when I stay on localhost
				this.doTheRedirection(url);
			},

			moveToPortailAction(){
				let url = (this.isAdminNavAllowed) ? this.urlsSites.portail.acceptance : this.urlsSites.portail.production;
				// this.website = "portail"; // when I stay on localhost
				this.doTheRedirection(url);
			},

			setMenuVisibility(bool){
				this.menuOpen = bool;
			},

			moveToAnotherAction(str){
				this.log(`moveToAnotherAction(${str})`, 'function');
				// Cleaning selection to avoid one coupon to allow prime button activeness!
				sessionStorage.removeItem("wowicards");

				// Closing menu
				this.setMenuVisibility(false);
				
				/* If the link does not point to the same page (so I don't reload) */
				if (str === "primes" && this.website !== "primes"){
					this.moveToPrimesAction();
				}else if(str === "primes"){
					this.currentPage = "wowi"; // for the menu to work
					this.triggerRefreshListOfAvailableOffers();
				}
				/* If the link does not point to the same page (so I don't reload) */
				if (str === "ecoupons" && this.website !== "ecoupons"){
					this.moveToCouponsAction();
				}else if(str === "ecoupons"){
					this.currentPage = "wowi"; // for the menu to work
					this.triggerRefreshListOfAvailableOffers();
				}
				/* If the link does not point to the same page (so I don't reload) */
				if (str === "cashbacks" && this.website !== "cashbacks"){
					this.moveToCashbacksAction();
				}else if(str === "cashbacks"){
					this.currentPage = "wowi"; // for the menu to work
					this.triggerRefreshListOfAvailableOffers();
				}		

				if (str === "portail"){
					this.moveToPortailAction();
				}	


			},

			triggerRefreshListOfAvailableOffers(){
				// TODO I'm sure it already exist a function that refresh data. Must just find it and run it.
				this.continue_RefreshUserData();
			},

			displayStoreContent(){
				this.log("STATE", 'sep');
				this.log(toRaw(this.$store.getters.getState));
				this.log("", 'sep');
			},

			activeMaintenancePage(){
				this.log("Request for maintenance page", "alert");
				this.goto('maintenance');
			},

			treatProofOfPurchaseDetailConsumer(category) {
				this.log("treatProofOfPurchaseDetailConsumer", 'function');
				this.log("Quand je récupère les cashbacks du conso, je dois redispatcher les IDS et les GTINS", 'todo');
				this.log("…pas les POPItems tels quels", 'todo');
				/* TODO describeTask */
				this.log("treatProofOfPurchaseDetailConsumer", 'group');

				this.bigOwnerData[category].forEach((pop, index) => {
					this.log(pop);
					this.fieldsValues.popitems["popitem" + (index+1)] = parseInt(this.bigOwnerData[category][index].idpopitem);
				});

				this.log('', 'groupEnd');
			},

			putDataIntoFieldsForAReparticipation(){
				let categories = Object.keys(this.bigOwnerData);
				this.log(categories);
				categories.forEach((category) => {

					this.log(`Checking category "${category}"`, 'low');

					/* WIP ========== START WIP 2292 ========== */
					this.log("treatProofOfPurchaseDetailConsumer", 'todo');
					this.log("I must receive this data, then modify the function to dispatch POPITEM datas in the right fields", 'todo');
					// see in the function for what to do…
					/* WIP ========== END WIP 2292 ========== */

					if(category === 'ParticipationMetaData'){
						this.log("Treating " + category);
						this.treatParticipationMetaData(category);
					}

					if(category === "ProofOfPurchaseHeaderConsumer"){
						this.log("Treating " + category);
						this.treatProofOfPurchaseHeaderConsumer(category);
					}

					if(category === "ProofOfPurchaseDetailConsumer" && this.bigOwnerData["ProofOfPurchaseDetailConsumer"]) {
						this.log("Treating " + category);
						this.treatProofOfPurchaseDetailConsumer(category);
					}

					if(category === 'ParticipationOwner'){
						this.log("Treating " + category);
						this.treatParticipationOwnerData(category);
					}

					if (category === "Participation"){
						this.log("Treating " + category);
						// TODO get what is given
						this.log(this.bigOwnerData, 'table');
						this.log(this.bigOwnerData);

						this.log("Nothing to do (yet) inside " + category);
					}

				});
			},








			composeProofOfPurchaseDetailConsumer(popitemListLength){
				/* INFO ProofOfPurchaseDetailConsumer must be present if there is an info about product (popitem, serialNumber) otherwise, it must be left empty*/

				/* ==== POPITEM ==== */
				if (popitemListLength === 0){
					this.log("There is no POPItem, so I don't add ProofOfPurchaseDetailConsumer to data", 'info');
					/* null */
				}
				/* ! As it can be an array in both case, I could use a loop to keep it DRY */
				if (popitemListLength === 1){
					this.log("There is only ONE POPItem, so I fill ProofOfPurchaseDetailConsumer whith this unique data", 'info');
					this.composedDataForParticipation.ProofOfPurchaseDetailConsumer = [{"idPOPItem": this.popitemlist[0].id, "quantity": 1}]; 
					/* is an object */
				}
				if (popitemListLength > 1){
					this.log("THERE IS Multiple POPItems, so I must check what user CHOOSE in the form", 'info');
					this.composedDataForParticipation.ProofOfPurchaseDetailConsumer = this.returnsOnlyNonEmptyPopitems(); 
					/* return an array */
				}
				/* ! end */


				/* ==== SERIAL NUMBER ==== */
				/* If there is a serialNumber */
				if (this.fieldsValues.serialNumber){
					/* If there is NO ProofOfPurchaseDetailConsumer, I create it as an empty array */					
					if (!this.composedDataForParticipation.ProofOfPurchaseDetailConsumer){
						this.composedDataForParticipation.ProofOfPurchaseDetailConsumer = [];
					}
					/* In both case, I push the serialNumber value */
					this.composedDataForParticipation.ProofOfPurchaseDetailConsumer.push({serialNumber : this.fieldsValues.serialNumber});
				}

				/* ==== Quantity ==== */
				if (this.fieldsValues.quantity){
					this.composedDataForParticipation.ProofOfPurchaseDetailConsumer.push({quantity: this.fieldsValues.quantity});
				}

			},


			async userReportsBadPrinting(){
				this.log("userReportsBadPrinting", 'todo');
				let data = {idparticipation: this.participationKeyFromUrl};
				let requestConfig = {
					title: "POST COUPON PROBLEM",
					url: `coupons/problem`,
					data: data,
				};
				try{
					let response = await this.promisePostToAxios(requestConfig);
					this.log(response);
					this.goto('badCouponPrinting');
				}catch(error){
					// Todo next function
					// ? afficher une erreur ?
					this.goto('pageError');
				}
			},


			composeParticipationOwner(){
				let ParticipationOwner = {
					"idParticipationOwner": this.ownerData.id,
					"idTitle": this.fieldsValues.idTitle,
					"idGender": this.fieldsValues.idGender,
					"phoneNumber1": this.fieldsValues.phoneNumber1,
					"lastName": this.fieldsValues.lastName,
					"firstName": this.fieldsValues.firstName,
					"email": this.fieldsValues.email,
					"IBAN": this.fieldsValues.iban,
					"VATNumber": this.fieldsValues.VATNumber,
					"optin": this.fieldsValues.optin,
					"generalCondition": this.fieldsValues.generalCondition,
					"idLanguageCode": this.getIdOfLanguage(),
					"idCountryCode": this.fieldsValues.idCountryCode,
					"companyName1": this.fieldsValues.companyName1, 
					"street1": this.removeSpecialChars(this.fieldsValues.street1),
					"street2": this.removeSpecialChars(this.fieldsValues.street2),
					"houseNumber": this.fieldsValues.houseNumber,
					"boxNumber": this.fieldsValues.boxNumber,
					"city": this.removeSpecialChars(this.fieldsValues.city),
					"postalCode": this.fieldsValues.postalCode,
					"keyAddress": this.fieldsValues.placeId,
				}
				return this.cleanNullValues(ParticipationOwner);
			},

			removeSpecialChars(str){
				if(str){
					this.log(`Removing special char from "${str}"`, "low");
					str = str.replaceAll('œ', 'oe');
					str = str.replaceAll('Œ', 'OE');
					this.log(`=> "${str}"`, "low");
				}
				return str;
			},

			getIdTypeFor(str){
				return this.participRefs.find(item => item.code === str).id;
			},

			returnFieldsValue(){
				this.log("returnFieldsValue", 'function');

				let participaitonType;
				if(this.website === "ecoupons"){
					participaitonType = 'HCDCPRINT';
				}else{
					participaitonType = 'PART';
				}

				let idForThisParticipationType = this.getIdTypeFor(participaitonType);
				this.log(`The participation type id for ${participaitonType} is ${idForThisParticipationType}.`, 'wip');

				let theFieldsValues = {
					"Authorization" : this.oauth.token_type + ' ' + this.oauth.access_token,
					"idLeadManagement": this.lead.id,
					"isUploadPresent": this.isUploadPresent,
					"userSystemInfo": null, // ! Not used yet
					"Participation": {
						"idParticipationType": idForThisParticipationType, 
						'fingerprint': this.$store.getters.getFingerprint,
					},				
					"ParticipationOwner": this.composeParticipationOwner(),
					"ParticipationMetaData": this.composeParticipationMetaData(),
				};

				/* Must only add this if it contains at least one value */
				let ProofOfPurchaseHeaderConsumer = this.composeProofOfPurchaseHeaderConsumer();
				let size = Object.keys(ProofOfPurchaseHeaderConsumer).length;
				if (size > 0){
					theFieldsValues['ProofOfPurchaseHeaderConsumer'] = ProofOfPurchaseHeaderConsumer;
				}

				/* Last console check */
				this.log("The gathered field values are as such:", 'info');
				this.log(theFieldsValues);
				return theFieldsValues;
			},

			composeParticipationMetaData(){
				/* Empty array used if there is META but no "survey" */
				let survarray = []
				/* If there IS a survey, maybe the user answers it, or not */
				if (this.thereIsASurveyInThisAction){
					survarray.push({
						"idLeadTMD": 1,
						"idLeadTMDV": this.surveyMustBeFilled ? 1 : 2 
						/* 
						1: the user filled the survey, the coupon is sent in the mail
						2: the user skip the survey, the coupon is not sent in the mail 
						*/
					});
				}
				this.log("composeParticipationMetaData", "function");
				this.log("The array for survey is as such:");
				this.log(survarray);
				return survarray;
			},

			composeProofOfPurchaseHeaderConsumer(){
				let POPHC = {
					"idPOPRetailer": this.fieldsValues.retailer,
					"freeRetailerName" : this.fieldsValues.freeRetailer,
					"purchaseDate" : this.fieldsValues.purchaseDate,
				};
				return this.cleanNullValues(POPHC);
			},



			/* TODO : decompose this long function into smaller ones */
			composeData(){
				this.log("prep of the DATA before sending participation", 'title');

				/* Whitening */
				this.composedDataForParticipation = {};

				/* getting fields values */
				this.composedDataForParticipation = this.returnFieldsValue();

				/* TODO add here the ordereditem */
				if (this.website === "primes"){
					this.log("test", "wip");
					// ! this.composedDataForParticipation.PurchaseOrderConsumer = this.composeOrderedItem();
					/* ! not a normal participation, this is probably not called */
				}

				/* getting popitem(s) */
				let itemListLength = this.popitemlist.length;

				this.log('List of products','group')
				this.log({...this.popitemlist});
				this.log(`There are ${itemListLength} elements in the popitems list`);
				this.log('', 'groupEnd');

				this.composeProofOfPurchaseDetailConsumer(itemListLength);

				/* UTMs */
				/* ? Double job with AxiosClass (line 82 to 99) ? */
				this.composedDataForParticipation.Participation.UTMSource = this.utmSource;
				this.composedDataForParticipation.Participation.UTMMedium = this.utmMedium;
				this.composedDataForParticipation.Participation.UTMCampaign = this.utmCampaign;
				this.composedDataForParticipation.Participation.UTMId = this.utmId;
				this.composedDataForParticipation.Participation.UTMTerm = this.utmTerm;
				this.composedDataForParticipation.Participation.UTMContent = this.utmContent;

				/* getting Metas */
				let nombreDeMetas = this.theMetaFieldsList.length;
				this.log("Details about meta-data", "group");
				this.log("There must be " + nombreDeMetas + " meta-data", "info");
				for (let i=0; i < nombreDeMetas; i++){
					this.checkMeta(this.metaIds[i], 'answersSQ' + (i+1)); 
				}
				this.log('', 'groupEnd');
				this.log(this.composedDataForParticipation);
				this.log("End of preview of data to be send", 'low');
			},

			checkMeta(questId, survId){
				this.log("Checking data for meta " + survId);
				let meta = this.theMetaFieldsList.filter(el => el.leadmanagementmetadata.id == questId);
				this.log(`Question ${questId} is: "${meta[0].leadmanagementmetadata.description}"`);
				this.log(`Answer is an id: ${this.fieldsValues.survey[survId]} of a string: ${this.fieldsValues.survey[survId + 'alt']}`);
				this.log("-".repeat(20));

				let newMetaToPushInParticipation = {};
				if(this.fieldsValues.survey[survId] !== '' && this.fieldsValues.survey[survId] !== null){
					newMetaToPushInParticipation["idLeadTMD"] = questId; // question ID
					newMetaToPushInParticipation["idLeadTMDV"] = parseInt(this.fieldsValues.survey[survId]); // Answer ID
				}
				if(this.fieldsValues.survey[survId + 'alt'] !== '' && this.fieldsValues.survey[survId + 'alt'] !== null){
					newMetaToPushInParticipation["answer"] = this.fieldsValues.survey[survId + 'alt']; // Free answer
				}
				if(Object.keys(newMetaToPushInParticipation).length > 0){
					this.composedDataForParticipation.ParticipationMetaData.push(newMetaToPushInParticipation);
				}
			},


			backToTop(){
				document.body.scrollTop = 0; // For Safari
  				document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  			},


  			areConditionsToSendTheParticipationMet(){
  				this.log("Check sending conditions", 'low');
  				let conditionsAreMet = true;
				/* TODO Re-write conditions here */
  				this.log(`Sending conditions: ${conditionsAreMet}`, 'wip');
  				return conditionsAreMet;
  			},


  			whatIfIGetTheCountries(data){
  				this.log("So, I got an allowed countries list.", 'low');
  				this.test_allowedCountries(data);
  				this.listOfAllowedCountries = data;
  				this.fieldsValues.idCountryCode = this.getCountryIdFromLocale();
				/* Refreshing list in the country field */
  				if(this.fieldsValues.idCountryCode){
  					this.fieldsValues.descriptioncountrycode = this.convertCountryIdToDescription(parseInt(this.fieldsValues.idCountryCode));
  				}
  			},

  			whatIfICantGetTheCountries(error){
				/* TODO DISPLAY AN ERROR? */
  				this.log("I can't get the country list. What can I do now?",'todo');
  				console.log(error);
  			},

  			updateCountryDescription(){
				/* When locale change, the description of the country must be adapted manually */
  				this.getFromAxios(this.callConfigs.getCountries);
  			},



		}, /* End of methods */






  			beforeMount(){
				// Clear console only in non-admin environment.
  				if(!this.isAdminNavAllowed){
  					console.clear();
  				}else{
  					this.log("FORM.VUE", "title");
  				}
  				this.consoleDateOfBuild();
				this.getUserEnvironment(); // functions located in mixins/sysinfos.js
				if(config.allowMaintenanceCheck){
					this.checkMaintenance();
				}else{
					this.log("No maintenance check required, proceeding…");
					this.startAction();
				}
			},

			mounted(){
				if( this.website === 'ecoupons' ){
					this.requestCouponsDetails();
				}
				if( this.website === 'cashbacks' ){
					this.getFromAxios(this.callConfigs.getSynonyms);
				}
				if( this.website === 'primes'){
					this.requestOrderedItemList();
					// this.requestPrimesDetails();
				}
				this.gatherAllWowiPostalCode();
				this.takeCareOfUtmInUrl();

					// obtain the id/code for participation kinds
				this.participRefs = this.getParticipationTypeReference();
			},

			computed: {

				getUnregisterSpecialFieldValidity(){
					return this.unregisterButtonFree;
				},

				/* As the portail don't get each date FOR EACH ACTION, but only his own, I must FAKE it. */
				isCouponDatePassed(){
					return new Date() > new Date(this.endDates.ecoupons); 
				},
				isCashbackDatePassed(){
					return new Date() > new Date(this.endDates.cashbacks);
				},
				isPromocodeDatePassed(){
					return new Date() > new Date(this.endDates.promocodes);
				},


				isConsentToCgGiven(){
					return this.fieldsValues.generalCondition === 1 || this.fieldsValues.generalCondition === "1";
				},

				isTodayAfterLastDayOfPromocodes(){
					return new Date() >= new Date(this.endDates.promocodes);
				},

				availableOrderedItemsList(){
					// retourne la liste des orderedItem minus les consumed ones
					// …et ceux dont la date de validité dépasse le 30/10 à 23h59:59
					if(this.isTodayAfterLastDayOfPromocodes){
						return [];
					}
					else{
						if(this.userIsLogged){

							return this.orderedItemList.filter(elem => {
								return !this.consumedCards.includes(elem.id) && parseInt(elem.available_purchase_order_unique_code_count) > 0;
							});
						}else{
							return this.orderedItemList;
						}
					}
				},

				unavailableOrderedItemsList(){
					// retourne les orderedItems indisponibles
					let unavailOrderedItemList;
					if(this.isTodayAfterLastDayOfPromocodes){
						// return all
						unavailOrderedItemList = this.orderedItemList;
					}else{
						unavailOrderedItemList = this.orderedItemList.filter(elem => {
							return this.consumedCards.includes(elem.id) || parseInt(elem.available_purchase_order_unique_code_count) <= 0;
						});
					}				
					return unavailOrderedItemList;
				},




				isCouponWebsiteOpen(){
					return new Date() <= new Date(this.endDates.ecoupons);
				},

				availablePopitems(){
					if(this.userIsLogged && this.cashbackDataAreReceived){
						this.log("User is logged and cashback data are received", 'wip');
					}

					let availableCashbackFilteredList = this.popitemlist.filter(item => {

							// I suppose the data is well formed. I can check earlier if it's the case
						let condition1 = item.maxcounter - item.currentcounter > 0;
						let today = new Date();
						let couponLimit = new Date(item.participationenddate);
						let condition2 = today < couponLimit;

							// special cases
						let cashbackIsReserved = false;
						let cashbackIsConsumed = false;

						if(this.userIsLogged && this.cashbackDataAreReceived){
							if(this.listOfAllCashbacksStatus.reserved){
								cashbackIsReserved = this.listOfAllCashbacksStatus.reserved.includes("" + item.id);
							}
							if(this.listOfAllCashbacksStatus.consumed){
								cashbackIsConsumed = this.listOfAllCashbacksStatus.consumed.includes("" + item.id);
							}
						}				

							/*
							this.log(`Cashback id: ${item.id}`, 'title');
							this.log(`Cashback counter is below zero: ${condition1}`, condition1?'green':'red');
							this.log(`Cashback is out of date range: ${condition2}`, condition2?'green':'red');
							this.log(`Cashback is reserved: ${cashbackIsReserved}`, cashbackIsReserved?'green':'green');
							this.log(`Cashback is consumed: ${cashbackIsConsumed}`, cashbackIsConsumed?'red':'green');
							*/

						let finalConditions = 
							// counter, date and non-consumed
						condition1 && condition2 && !cashbackIsConsumed || 
							// or reserved AND not consumed
						cashbackIsReserved && !cashbackIsConsumed;

						if(!finalConditions){ this.log(`${item.id} => This cashback is unavailable`); }
						return finalConditions;
					});
					return availableCashbackFilteredList;
				},




				noMoreAvailablePopitems(){
					if(this.userIsLogged && this.cashbackDataAreReceived){
						this.log("User is logged and cashback data are received", 'wip');
					}

					let unavailableCashbackFilteredList = this.popitemlist.filter(item => {
							// I suppose the data is well formed. I can check earlier if it's the case
						let condition1 = item.maxcounter - item.currentcounter <= 0;
						let today = new Date();
						let couponLimit = new Date(item.participationenddate);
						let condition2 = today > couponLimit;

							// special cases
						let cashbackIsReserved = false;
						let cashbackIsConsumed = false;
						if(this.userIsLogged && this.cashbackDataAreReceived){
							if(this.listOfAllCashbacksStatus.reserved){
								cashbackIsReserved = this.listOfAllCashbacksStatus.reserved.includes("" + item.id);
							}
							if(this.listOfAllCashbacksStatus.consumed){
								cashbackIsConsumed = this.listOfAllCashbacksStatus.consumed.includes("" + item.id);
							}
						}				

							/*
							this.log(`Cashback id: ${item.id}`, 'title');
							this.log(`Cashback counter is below zero: ${condition1}`, condition1?'green':'red');
							this.log(`Cashback is out of date range: ${condition2}`, condition2?'green':'red');
							this.log(`Cashback is reserved: ${cashbackIsReserved}`, cashbackIsReserved?'green':'red');
							this.log(`Cashback is consumed: ${cashbackIsConsumed}`, cashbackIsConsumed?'green':'red');
							*/

						let finalConditions = 
							// counter < 0 AND not reserved
						condition1 && !cashbackIsReserved || 
							// date expired AND not reserved
						condition2 && !cashbackIsReserved ||
							// consumed, in any case
						cashbackIsConsumed;
						if(!finalConditions){ this.log(`${item.id} => This cashback is available`); }
						return finalConditions;
					});
					return unavailableCashbackFilteredList;
				},






			},

			watch: {
				'$store.getters.getMaintenance': function(){
					this.log("MAINTENANCE DETECTED", 'wip');
					this.activeMaintenancePage();
				},

					/* TODO check if this works. I had to move it to a computed in NewMaster v2 for it to work */		
					/* Probably because a getter is a passive function that must be called, and I should watch state instead? */			
				'$store.getters.getLocale': function(newLocale, oldLocale){
					this.log(`${oldLocale} --> ${newLocale}`, 'low');
					this.locale = newLocale;

					if(this.registrationRequired){
						this.updateWhatsappCodes();
					}

					// some refresh call 
					this.updateCountryDescription();
					this.getProductsList(); // To refresh product description
					this.getUnsubscribeReferences();

					if( this.website === "primes"){
						this.requestOrderedItemList();
					}

				},
			},
		}
	</script>

	<!-- ================================================================================== -->

	<style lang="scss" scoped>
	</style>
