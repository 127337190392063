export default {
	/* ======== DATA ======== */
	data () {
		return {
			
		}
	},
	methods: {

		getAllSelectedPrimeDetails(){
			this.log("getAllSelectedPrimeDetails", 'function');
			// we'll returns tablePrimes, containing all details for Action-API.
			let tablePrimes = [];

			// ! this.wowicards does not exists here. We get it in sessionStorage (could be from store ?)
			let wowicards = JSON.parse(sessionStorage.getItem("wowicards"));

			// We check what IDs are selected ({276: true, 277: false})
			let arr = Object.entries(wowicards);
			let selectedIds = [];
			arr.forEach(card => {
				if(card[1]){
					selectedIds.push(card[0]);
				}
			});
			// We got a list of all selected IDs

			// We check in all Promocodes those whose id are in the selectedIds list
			// if so, we add the details to the table we'll return.
			this.orderedItemList.forEach(orderitem => {
				if(selectedIds.includes(String(orderitem.id))){
					
					tablePrimes.push(orderitem.id);

					/*
					tablePrimes.push({
						idPurchaseOrderItem: orderitem.id,
						quantity: 1,
						itemCode: orderitem.itemcode
					});
					*/
				}
			});

			return tablePrimes;
		},


		composeCrmParticipation(){
			/* Collecting data for both ecoupons OR cashbacks action */
			this.log("participationsData > composeCrmParticipation()", 'wip');

			// The data collector that will be returned
			const fullData = {};

			/* ======== Adding what is required for BOTH type of action ======== */

			/* The action ID */
			fullData.idLeadManagement = this.$store.getters.getLeadId;

			/* Infos about the partOwner */
			fullData.ParticipationOwner = {
				idCRMParticipationOwner: this.userInfos.id,
				// UTM will come here later thanks to Axios
			};

			// The fingerprint
			fullData.Participation = {
				fingerprint: this.$store.getters.getFingerprint,
			};

			// The code
			fullData.code = this.lastValidCodeSent;

			// TYPE OF PARTICIPATION (PART: participation, REPART: reparticipation, HCDCPRINT: coupon, RESERVAT: reservation, PROMOCODES: for promocodes)
			// participRefs is what I got from API
			// this.participationCode comes from wowiflow
			// this.participRefs.forEach(test => this.log(test));
			
			// ! What if I have not the participRefs yet !? => Blocking Error (id of undefined)
			if(!this.participationCode){
				this.log("Il n'y a pas de participationCode !", 'warning');
				this.log("Add a participation Code manually", 'red');
				if(this.website === "ecoupons"){
					this.log("Add HCDCPRINT code");
					this.participationCode = "HCDCPRINT";
				}else if(this.website === 'primes'){
					this.log("Add PROMOCODES code");
					this.participationCode = "PROMOCODES";
				}else if(this.website === 'cashbacks'){
					this.log("Add cashback PART code");
					this.participationCode = "PART";
				}
			}else{
				this.log(`The PartCode was ${this.participationCode}`);
				this.log(this.participationCode);
			}

			this.log("Jusqu'ici, tout va bien", 'wip');
			this.log("Participation Refs code", 'low');
			this.log(this.participRefs);
			this.log("-".repeat(10), 'low');
			this.log(`Le participation code à ce stade est: ${this.participationCode}`);

			let refId = this.participRefs.filter(ref => ref.code === this.participationCode)[0];
			fullData.Participation.idParticipationType = refId.id; 

			this.log(`The participation type is: ${fullData.Participation.idParticipationType}`, 'low');

			// Following the type of website and the type of participation

			// ==== PRIMES ====
			if(this.website === 'primes'){
				this.log("composeCrmParticipation > this website is 'primes'", 'wip');
				fullData.sendEmail = 1;
				// fullData.PurchaseOrderConsumer = [];
				fullData.CRMParticipationOwner = {
					email: this.fieldsValues.email,
					firstname: this.fieldsValues.firstName,
					lastname: this.fieldsValues.lastName,
				};
				fullData.purchaseOrderItemIds = this.getAllSelectedPrimeDetails();
			}

			// ==== ECOUPONS - COUPON DIRECT PRINT
			/* Not applicable yet : no corresponding participationCode */
			/*
				fullData.sendEmail = 0;
			*/

			// ==== ECOUPONS - COUPON MAIL PRINT
			if(this.website === "ecoupons" && this.participationCode === "HCDCPRINT"){
				this.log("composeCrmParticipation for HCDCPRINT", 'low');
				fullData.sendEmail = 1;
				fullData.isCodeRequired = 1;
				// * fullData.purchaseOrderItemIds = this.getListOfSelectedOffersIds();
				fullData.ConsumerCoupon = this.getListOfSelectedOffersIds();
			}

			// ==== CASHBACKS - (RE)PARTICIPATION
			if(this.website === 'cashbacks' && (this.participationCode === "PART" || this.participationCode === "REPART")){
				this.log("composeCrmParticipation for cashback PART or REPART", 'low');
				// About the participation itself
				fullData.Participation.imageUploaded = 1;
				// User IBAN
				fullData.CRMParticipationOwner = {IBAN: this.fieldsValues.iban};				
			}

			// ==== CASHBACKS - RESERVATION
			if(this.website === 'cashbacks' && this.participationCode === "RESERVAT"){
				// About the participation itself
				this.log("composeCrmParticipation for cashback RESERVAT", 'low');
				fullData.sendEmail = 1;
			}

			if(this.website === 'cashbacks' && ( this.participationCode === "PART" || this.participationCode === "REPART" )){
				fullData.sendEmail = 1;
			}

			if(this.website === 'cashbacks'){
				fullData.isCodeRequired = 0;	
				fullData.ProofOfPurchaseDetailConsumer = this.getSelectedCashbacksForThisParticipation();
			}


			this.log("fullData.ProofOfPurchaseDetailConsumer:", 'low');
			this.log(fullData.ProofOfPurchaseDetailConsumer); // undefined if ecoupons or prime
			this.log("(undefined is normal if not cashbacks)", 'low');


			// Finally…
			if (fullData.ProofOfPurchaseDetailConsumer || this.website === "ecoupons" || this.website === "primes"){
				this.log("Final fullData: ", 'wip');
				this.log(fullData);
				return fullData;
			}else{
				// There is no cashback returned
				// Redirection to a specific page
				this.goto('emptyOrder');
				// mettre ce check sur les fonctions qui appellent "getSelectedCashbacksForThisParticipation"
			}
		},





		getSelectedCashbacksForThisParticipation(){
			let list = [];
			if(this.selectedCashbacks.length > 0){
				this.selectedCashbacks.forEach(item => {
					let product = {idPOPItem: item, quantity: 1};
					if(typeof this.fieldsValues.gtinsValues[item] === 'number'){
						product.idItemSynonym = this.fieldsValues.gtinsValues[item];
					}else{
						product.itemSynonym = this.fieldsValues.gtinsValues[item];
					}
					list.push(product);
				});
			}
			return list;
		},

		getUtmsForParticipation(){
			let salvagedUtms = this.$store.getters.getUtms;
			let listUTM = {
				UTMSource : salvagedUtms.source,
				UTMMedium : salvagedUtms.medium,
				UTMCampaign : salvagedUtms.campaign,
				UTMId : salvagedUtms.id,
				UTMTerm : salvagedUtms.term,
				UTMContent : salvagedUtms.content,
			};
			listUTM = this.removeNullOrUndefinedValuesFromObject(listUTM);
			this.log("UTM list added to baseData in composeCrmParticipation()", 'wip');
			this.log(listUTM, 'table');
			return listUTM;
		},

		removeNullOrUndefinedValuesFromObject(obj){
			return Object.entries(obj).reduce((a, [k, v]) => (v == null ? a : (a[k] = v, a)), {});
		},

	}
}