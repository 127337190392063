export default {
    data () {
        return {
            // modale visibility
            modaleVisibility: {
                login: false,
                code: false,
                error: false,
                printCoupons: false, // Not used. In wowi (17928), was the iframe option. Not present in this version.
                infoReservation: false,
                infoParticipation: false,
                emptyOrder: false,
            },
        }
    },

    computed: {
        isAtLeastOneModaleVisible(){
            return Object.values(this.modaleVisibility).some(val => val);
        },
    },

    methods: {

        // Form can require the closing of all modales
        closeAllModales(){
            this.log("Closing ALL modales", 'low');
            let modaleToClose = ['login', 'code', 'error', 'infoReservation', 'infoParticipation', 'printCoupons'];
            modaleToClose.forEach(mod => this.turnThisModaleVisibility(mod, false));
        },

        // Switch on or off a particular modale by it's name
        turnThisModaleVisibility(name, bool){
            this.log(`Turn modale ${name} ${bool ? 'ON' : 'OFF'}`, 'low');
            this.modaleVisibility[name] = bool;
        },

        // Toggle (false become true and true become false)
        toggleModaleVisibility(name){
            this.log(`Change visibility for modale ${name}`, 'low');
            this.modaleVisibility[name] = !this.modaleVisibility[name];
        },

        requireUserToLog(){
            this.log(`Asking user to log in`, 'low');
            this.fieldsValues.email = undefined;
            this.fieldsValues.password = undefined;
            this.turnThisModaleVisibility('login', true);
        },

        requireUserToGiveCode(){
            this.log(`Asking user to give code`, 'low');
            this.fieldsValues.code = undefined;
            this.turnThisModaleVisibility('code', true);
        },

        userCloseThisModale(name){
            /* Special case: user close a modale himself, renoncing to what he started (login, printing coupon) */
            this.endAllOperationInProgress();
            this.turnThisModaleVisibility(name, false);
        },
    },
}
