<template>
	<div class="aCustomInput field">
		<label  :for="translationSource"><span v-html="$t('inputsTexts.' + translationSource + '.label')"></span><span v-if="isRequired" class="req">&nbsp;*</span></label>
		<input
		:id="translationSource"
		:type="inputType"
		:name="name"
		:autocomplete="autocompleteName"
		v-model="modelValueComputed"
		:placeholder="$t('inputsTexts.' + translationSource + '.placeholder')"
		:disabled="isDisabled"
		:class="{hasError: isInError, hasSuccess: isInSuccess, isLoading: isLoading}"
		autocapitalize="off" 
		spellcheck="false" 
		autocorrect="off"
		>
		<div class="errorMessage" v-if="isInError"><span v-html="errorMessage"></span></div>
		<div class="helper" v-html="$t('inputsTexts.' + translationSource + '.helper')"></div>
	</div>
</template>

<!-- ========================================= -->

<script>
// Load only a part of lodash to keep the application small
	import { debounce } from "lodash";
	import FormFields from "../mixins/FormFields";
	import dayjs from "dayjs";
	var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
	dayjs.extend(isSameOrBefore)
	export default {
		name: "customInput",

		emit: ["update:modelValue", "update:valueHasChanged", "runCheck"],

		mixins: [FormFields],

		props: {
			inputType:{
				type: String,
				validator : function(value){
					return ["text","password","email","hidden","number","search","tel","url", "date"].includes(value);
				}
			},
			isDisabled : {
				type: Boolean,
				required: false,
			default: false
			},
			valueHasChanged: {
				type: String,
				required: false
			},
		},

		data(){
			return {
				errorMessage : "",
			}
		},

		computed: {
			modelValueComputed: {
				get() {
					return this.modelValue;
				},
				set(newVal) {
					this.validateMyselfDebounced();
					this.$emit('update:modelValue', newVal);
				}
			}
		},

		methods : {
			validateMyself() {
				if(this.inputType === "date") {
					return this.dateCheck()
				}

				if(this.modelValueComputed){

					this.setLoading();

					console.group("CRM Input Validation " + this.fieldname);
					console.log(`I use ${this.fieldname} as key and ${this.modelValueComputed} as value`);
					console.groupEnd();

					let data = {
						[this.fieldname]: this.modelValueComputed.toLowerCase(),
					};

					this.axios.post(
						`/crm/wowi/data/${this.fieldname}/validate`,
						data
						)
					.then(res => {
						// console.log("I got a positive CustomInput result: ");
						// console.log(res);
						if(res === "don't use this part, it's for lint only"){ console.log("If you see this, buy lottery tickets NOW!"); }
						// A non-required field must be neutral if empty
		  				/* INFO I had to use this.modelValue.Computed.toString().length > 0 with a quantity (as a number, length was undefined).  */
						if(this.modelValueComputed.length > 0){
							this.giveSuccess();
							this.$emit('runCheck', {fieldname: this.fieldname, valid: true});
						}else{
							this.removeSuccessOrError();
						}  
						this.setNotLoading();
					})
					.catch(err => {
						console.log(`CustomInput reported an error: ${err.response.data.message}`);
						if(err.response.status === 503){
							this.setNotLoading();
							this.$store.dispatch('setMaintenance', true);
						}else{
							this.giveError();
							this.setNotLoading();
							this.$emit('runCheck', {fieldname: this.fieldname, valid: false});
							let errorDescriptionList = [];

							Object.values(err.response.data.errors).forEach(errorArray => {
								errorArray.forEach(errorDescription => {
									errorDescriptionList.push(errorDescription);
								});
							});

							let text = "<ul>";
							errorDescriptionList.forEach(error => {
								text += `<li>${error}</li>`;
							});
							text += "</ul>";
							this.errorMessage = text;
						}

					})
					.then(()=>{});
				}else{
					/* Required or not, if empty, no success or error status */
					this.removeSuccessOrError();
				}
			},


			/* TODO : add a check: is date inbetween purchase dates from lead */
			/* ! THERE IS A PROBLEM : 2022/12/05 and 2022/05/12 are not the same ! */
			/* use then this.$t('errors.dateOutOfPeriod'); */
			dateCheck() {
				this.dateMessage = null;
				let date = dayjs(this.modelValueComputed);
				if(!date.isValid()) {
					this.errorMessage = this.$t('errors.dateInvalid');
					this.$emit('runCheck', {fieldname: this.fieldname, valid: false});
				} else if(!date.isSameOrBefore(dayjs())) {
					this.errorMessage = this.$t('errors.dateBefore');
					this.giveError();
					this.$emit('runCheck', {fieldname: this.fieldname, valid: false});
				} else {
					this.giveSuccess();
					this.$emit('runCheck', {fieldname: this.fieldname, valid: true});
				}
			},
		},

		watch: {
			modelValue(){
				this.validateMyselfDebounced();
			},
			/* * maybe not necessary. 
			isDisabled(){
				this.removeSuccessOrError();
				this.$emit('runCheck', {fieldname: this.fieldname, valid: false});
			}
			*/
		},

		created() {
			this.validateMyselfDebounced = debounce(this.validateMyself, 500);
			// run validation immediately when the value is not null or empty
			if(this.modelValueComputed) {
				this.validateMyselfDebounced();
			}
		}
	}

</script>

<!-- ========================================= -->

<style lang="scss" scoped>
</style>
