export const de = {
    general: {
        'world': 'Hallo Welt',
        'Y': 'Ja',
        'N': 'Nein',
    },

    gdpr: {
        'title': "Datenschutzhinweis"
    },

    auth: {
        form: {
            login: "Anmeldung",
            username:"Benutzername",
            usernamePlaceholder: "Benutzername Platzhalter",
            password: "Passwort",
        }
    },

    breadcrumbSteps: {
        registration: "Registrieren",
        store: "Ihre Einkäufe tätigen",
        form: "Füllen Sie das Formular aus",
        survey: "Füllen Sie einen optionalen Fragebogen aus",
        cashback: "Erhalten Sie Ihre Rückerstattung"
    },

    inputLabel: {
        typeFirstname : "Indiquez votre PRÉNOM",
        typeLastname : "Gelieve je NAAM in the vullen",
        typeEmail : "Écrivez votre EMAIL ici",
        typeIban : "Gelieve je IBAN nummer te vullen",
        //https://vue-i18n.intlify.dev/guide/essentials/syntax.html#literal-interpolation
        helpEmail : "Votre email doit comporter un {'@'}, but in DE",
        typePhone : "Write your mobile number",
        helpPhone : "With the international code, please",
        typePassword : "Passwort",
        helpPassword : "",
        typeUniqueCode : "Indiquez ici le code visible sur le ticket de caisse",
        typeTitle : "Titre",
        helperTitle : "Cela servira uniquement dans nos communications",
    },

    // start a new object for every topic
    players: {
        // tableheaders
        table: {

        },
        // form-labels
        form: {

        },
        //error-messages
        errors: {

        }
    }
}
