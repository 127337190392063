import config from "../config";

export default {
	data() {
		return {		}
	}, 
	methods: {


		/* SUCCESS CALLBACK FOR "GET PRODUCTS IDS" API CALL */
		whatIfIFoundProductsIds(data){
			this.log(`Products stored in propitemlist`, 'low');
			this.popitemlist = [...data];
		},

		/* ERROR CALLBACK FOR "GET PRODUCTS IDS" API CALL */
		/* ? Is this the right redirection? */
		whatIfICantFoundProductId(error){			
			this.goto('pageError'); 
			this.iGotThisErrorFromActionApi(error, this.$t('errors.productCodesNotFound'));
		},





		/* SUCCESS CALLBACK FOR "GET LOCALIZING DATA" API CALL */
		localizingDataFound(data){
			/* Storing the data for futur usage */
			this.localizingData = data;
			/* setting the marker to allow the display of the form */
			this.localizingDataAreReceived = true;
			/* What is interesting in this function */
			this.langswitchsThatMustBePresent = [];

			/* Display more things */
			this.log("Localizing data", 'group');
			this.log("What I'm expecting as lang buttons: ", 'info');
			for (let item in data){
				/* Summary in the form: [info] BE 1 | FR 1*/
				this.log(`${data[item].countrycodereference.code} ${data[item].countrycodereference.id} | ${data[item].languagecodereference.code} ${data[item].languagecodereference.id}`);
				/* Put them in a list */
				this.langswitchsThatMustBePresent.push(`${data[item].languagecodereference.code}_${data[item].countrycodereference.code}`);
			}	
			/* …store the list */
			this.$store.dispatch('setLangList', this.langswitchsThatMustBePresent);
			this.log(this.langswitchsThatMustBePresent);
			this.log('', 'groupEnd');	
			this.wowiStarterCheckLang();	
			this.getIdOfLanguage(); // WIP a effacer ? Or not used here ?
		},

		/* ERROR CALLBACK FOR "GET LOCALIZING DATA" API CALL */
		localizingDataNotFound(error){
			this.log(error, 'alert');
		},







		/* SUCCESS CALLBACK FOR "GET RETAILERS" API CALL */
		whatIfIGetTheRetailersList(data){
			/* ? WHAT IF THERE IS NO RETAILERS (empty list)? */
			/* => Array is empty, the condition in the field is never true, so no other field appears */
			/* ? But does THIS code produce an blocking error? */
			/* The request give me the list of retailer. I can then extract the freefield IDs */
			let listFreeFields = data.filter(item => {
				return item.freefield === "1" || item.freefield === 1;
			});
			listFreeFields.forEach(item => {
				this.unlockFreeRetailersIDs.push(item.id);
			});
			this.log(`I also got the list of the "Other" retailers IDs`, 'success');
			this.log(this.unlockFreeRetailersIDs);
		},

		/* ERROR CALLBACK FOR "GET RETAILERS" API CALL */
		whatIfIDontGetTheRetailersList(error){
			this.log(error, 'alert');
			this.iGotThisErrorFromActionApi(error, this.$t('errors.retailerListNotFound'));
		},









		// At statup form I call the API "getSynonyms"
		whatIfIGetTheSynonymsList(data){
			this.synonyms = data;
			this.log(`Synonyms stored in "synonyms"`, 'info');
		},
		whatIfICantGetTheSynonymsList(err){
			this.log("APICallbacks > whatIfICantGetTheSynonymsList", "error");
			this.log(err);
		},


		

		getProductsList(){
			this.getFromAxios(this.callConfigs.getProductsIds);
		},


		


		/* SUCCESS CALLBACK FOR "GET THE LEAD" API CALL */
		whatIfIGetTheLead(data){
			/* As I receive the data, I set a marker */
			this.leadDataAreReceived = true;
			/* …then I go my business as usual. */
			this.lead = data;

			this.log(`The HDREF is : ${this.lead.referencehd} | The ID is : ${this.lead.id}`, "info");

			/* Look if registration is required or not */
			this.talkingAboutRegistration();

			this.metaIds = this.getMetaIds(); /* ! Could be renamed : it gets fields, not only meta */
			this.actionFullDates = this.getFullDates();

			/* Get products list */
			this.getProductsList();

			/* Get retailer list */
			this.getFromAxios(this.callConfigs.getRetailers);

			this.displayAvailableParticipations();
			this.displayDatesInConsole();
			/* Continue… */
			this.checkIfAccessToFormIsPossible();
		},

		/* ERROR CALLBACK FOR "GET THE LEAD" API CALL */
		whatIfIDontGetTheLead(error){
			this.goto('pageError');
			this.log(error.response?.data?.errors, 'alert');
			this.iGotThisErrorFromActionApi(error, this.$t('errors.leadNotFound'));
		},



		/* SUCCESS FOR "POST PARTICIPATION" API CALL */
		participationSuccessful(res){
			this.log("The sending of the participation is a success", 'success');
			this.log(res);
			this.pendingParticipationSent = false;
			this.goto('confirmation');
		},
		/* ERROR FOR "POST PARTICIPATION" API CALL */
		participationFailed(error){
			this.log("participationFailed(error)", "function");
			this.log("The sending of the participation is a failure", 'alert');

			let title = error.response.data.message;
			let details;

			if(!!error.response.data.code && (error.response.data.code === "NO_FILE_UPLOAD" || error.response.data.code === "MISSING_FILE_UPLOAD")){
				/* These are known error, with message + notification */				
				if(typeof(error.response.data.notifications) === "object"){
					this.log("notifications is an object");
					details = this.formatKeyedErrorForDisplay(error.response.data.notifications);
					this.log(details);
				}else{
					this.log("notifications is not an object");
					details = error.response.data.notifications;
				}
			}else{
				/* These are unknown error with possible unformated message */
				details = this.formatKeyedErrorForDisplay(error?.response?.data?.errorsKeyed);
			}

			this.displayModaleMessages(title, details, 'alert');
			this.pendingParticipationSent = false;
			this.isClaimCashbackButtonActive = true;
			this.goto('form1');
		},


		/* FORM MUST CREATE A PARTICIPATION OWNER */
		createParticipationOwner() {
			this.log("createParticipationOwner", 'function');
			let data = {
				"idLeadManagement": config.idlead,
				"email" : this.fieldsValues.email,
				"idLanguageCode": this.getIdOfLanguage(),
			};
			this.log(data);
			this.postToAxios(this.callConfigs.postParticipationOwner, data);
		},

		/* SUCCESS CALLBACK FOR "POST PARTICIPATION OWNER" API CALL */
		successInCreateParticipationOwner(res) {
			this.log(`I managed to create the participationOwner ${res.data.participationOwner.id}`,'success');
			this.ownerData.id = res.data.participationOwner.id;
			this.sendParticipation();
		},

		/* ERROR CALLBACK FOR "POST PARTICIPATION OWNER" API CALL */
		failedInCreateParticipationOwner(error) {
			this.log("ParticipationOwner was not created", 'alert');
			this.log(error.response);
			if(error.response.data.code === "PARTICIPATED"){
				/* User has already participated with these identifiers */
				let title = error.response.data.message;
				let details = error.response.data.notifications;
				this.displayModaleMessages(title, details, 'alert');
			}else{
				let title = error.response.data.message;
				let details = this.formatKeyedErrorForDisplay(error?.response?.data?.errorsKeyed);
				this.displayModaleMessages(title, details, 'alert');
			}
			this.pendingParticipationSent = false;
		},



		/* If "registration" is a key, what is it's value */
		talkingAboutRegistration(){
			if("registration" in this.lead){
				this.log('There is a "registration" key in the lead', 'low');
				this.registrationRequired = this.isRegistrationRequired(); 
				if (this.registrationRequired){
					this.updateWhatsappCodes();
				}
			}else{
				/* For unknow reason, there is no registration in this lead */
				this.log('There was NO "registration" key in the lead', 'alert');
			}
		},

		/* CHECK IF REGISTRATION KEY IS 1 OR 0, RETURNS TRUE OR FALSE */
		isRegistrationRequired(){		
			let	regReq = this.lead.registration === "1";
			let not = regReq?'':'not ';
			this.log(`For this action, a registration is ${not}required.`, 'info');
			return regReq;
		},




		/* SUCCESS CALLBACK FOR "GET PARTICIPATION OWNER DATA" API CALL */
		whatIfIGetTheOwnerData(data){
			/* Check if the lead of the owner and the lead of this action are the same */
			/* So we cannot fill a form of another action! */
			this.log(`Id owner: ${data.idleadmanagement}, id lead: ${this.lead.id}`, 'low');
			if(parseInt(data.idleadmanagement) === parseInt(this.lead.id)){
				this.ownerIsLegitToParticipateHere(data);
			}else{
				this.log("IDs of owner and action are differents", "alert");
				this.log(typeof data.idleadmangement);
				this.log(typeof this.lead.id);
				this.whatIfIDontGetTheOwnerData();
			}
		},
		/* ERROR CALLBACK FOR "GET PARTICIPATION OWNER DATA" API CALL */
		whatIfIDontGetTheOwnerData(error){
			this.goto('wrongcode');
			this.iGotThisErrorFromActionApi(error, this.$t('errors.codeWrongOrUnidentified'));
		},
		/* I get the participationOwner from the key in url in cas of reparticipation */
		whatIfIGetTheParticipationOwnerData(data){

			// special case: it's for a eCoupon
			if(this.currentOpe === 'printCoupon'){

				// TODO I need to log the user. I can use data.idcrmparticipationowner as a "crypted"Id
				// Is it in the localStorage ?
				// if it is, get it there (getUserDetailFromSavedCryptedCode)
				// if it is not, find it 
				this.getUserDetailFromSavedCryptedCode();


			// TODO adapt locale idlanguagecode

				this.log("ADAPT LANGUAGE TO USER'S", 'todo');
				this.log(data.idlanguagecode);
				this.log("now, setLang with it", 'low');
				this.setLang(data.idlanguagecode);


				this.log("Owner found, continuing login process", 'low');
				this.operations.login_request = true;
				this.continuePreviousOperation();
			}else{
			/* SUCCES ACTIONS IS THE SAME AS WITH SIMPLE OWNER DATA*/
				this.whatIfIGetTheOwnerData(data);
			}


		},
		whatIfIDontGetTheParticipationOwnerData(error){
			/* THIS SHOULD DISPLAY ANOTHER ERROR MESSAGE */
			this.goto('wrongcode2');
			this.iGotThisErrorFromActionApi(error, this.$t('errors.codeWrongOrUnidentified'));
		},
		
	},
	computed: {

	},
}