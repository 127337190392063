export default {
	data () {
		return {
			actionFullDates: {},
			participatingIsNotAllowedAnymore: false,
			dateStatus: null,
		}
	},
	methods: {


		addSixWeeksTo(date){
			/* Add 6 weeks to compensate the difference between Production en Acceptance */
			/* ! Must only be used for display, so the client is not afraid to see a "wrong" date in acceptance */
			let delay = 6 * 7 * 24 * 60 * 60 * 1000; /* 6 weeks of 7 days of 24 hours of 60 minutes of 60 secondes of 1000 ms */
			return new Date(date.getTime() + delay);
		},

		/* GET ALL DATES USEFUL FOR THIS ACTIONS */
		/* Returns an object with many properties: startFullData, endFullData, currentDate */
		getFullDates(){
			let dates = {
				// current: new Date("2024-02-13"), // For test
				current: new Date(),
				registration:{
					start:  new Date(this.lead.registrationbegindate),
					end:  new Date(this.lead.registrationenddate),
				},
				participation: {
					start: new Date(this.lead.participationbegindate),
					end:  new Date(this.lead.participationextensiondate),
				},
				reparticipation: {
					start:  new Date(this.lead.participationbegindate),
					end:  new Date(this.lead.treatmentenddaterepart),
				},
				purchaseDates: {
					start: new Date(this.lead.purchasebegindate),
					end: new Date(this.lead.purchaseenddate),
				}
			};

			/* Adapt dates for correct timing */
			let hours = 23 * 60 * 60;
			let minutes = 59 * 60;
			let seconds = 59;
			let almostADayDuration = (hours + minutes + seconds) * 1000;
			// We add 23h59m59s because registration and (re)participation close at the END of the day 
			dates.registration.end.setTime(dates.registration.end.getTime() + almostADayDuration);       
			dates.participation.end.setTime(dates.participation.end.getTime() + almostADayDuration);  
			dates.reparticipation.end.setTime(dates.reparticipation.end.getTime() + almostADayDuration);
			this.log('Dates techniques', 'group');
			this.log(dates);
			this.log('', 'groupEnd');
			// // this.customLog(dates);
			return dates;
		},

		/* DISPLAY THE DATES IN THE CONSOLE */
		/* For CONSOLE INFO only */
		displayDatesInConsole(){
			this.log("Human-readable dates for this action", "sep");
			try{
				console.group("Human-readable dates for this action");
				console.log("Current:                       %c " + this.getHumanReadableDate(this.actionFullDates.current), 'color:#0000cc;');
				console.log("Registration opens on:         %c " + this.getHumanReadableDate(this.actionFullDates.registration.start), 'color:#0000cc;');
				// console.log("Registration closes on:        %c " + this.getHumanReadableDate(this.actionFullDates.registration.end) + " (+ 23h59m59s included)", 'color:#0000cc;');
				// console.log("Participation opens on:        %c " + this.getHumanReadableDate(this.actionFullDates.participation.start), 'color:#0000cc;');
				// console.log("Participations closes on:      %c " + this.getHumanReadableDate(this.actionFullDates.participation.end) + " (+ 23h59m59s included)", 'color:#0000cc;');   
				// console.log("Reparticipations closes on:    %c " + this.getHumanReadableDate(this.actionFullDates.reparticipation.end) + " (+ 23h59m59s included)", 'color:#0000cc;');            
				console.groupEnd();
			}catch(error){
				this.log(error, "alert");
			}
		},

		/* FORMAT DATE SO HUMANS CAN EASILY READ THEM */
		getHumanReadableDate(isoDate){
			let humanReadableStartDate = isoDate.toLocaleDateString("fr-BE", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
			});
			return humanReadableStartDate;
		},
		
		/* User is too soon/late for registration or participation: he cannot enter the form */ 
		/* CONDITIONS => Must return true or false */
		/* ! Should be computed maybe, not methods, but are fixed, not evolving… */
		isUserThereTooSoon() {
			return this.actionFullDates.current.getTime() < this.actionFullDates.registration.start.getTime();
		},
		isUserThereTooLate() {
			return this.actionFullDates.current.getTime() > this.actionFullDates.participation.end.getTime();
		},
		isUserAbleToReparticipate() {
			return this.actionFullDates.current.getTime() > this.actionFullDates.participation.end.getTime() &&
			this.actionFullDates.current.getTime() < this.actionFullDates.reparticipation.end.getTime();
		},

		checkRegistrationDates(){
			/* Too soon for registration */
			if ( this.actionFullDates.current.getTime() < this.actionFullDates.registration.start.getTime() )	{
				this.goto('beforeStart');
			}
			/*  Too late for registration */
			if ( this.actionFullDates.current.getTime() > this.actionFullDates.registration.end.getTime() ){
				this.goto('actionEnded'); 
			}
			/* Time ok for registration */
			if (	this.actionFullDates.current.getTime() > this.actionFullDates.registration.start.getTime() && 
				this.actionFullDates.current.getTime() < this.actionFullDates.registration.end.getTime() ) 
			{
				this.goto('registration');
			}
		},
		
		checkParticipationDates(){
			/* Too soon for participation */
			if ( this.actionFullDates.current.getTime() < this.actionFullDates.participation.start.getTime() ){
				this.goto('beforeStart');
			}
			/* Too late for participation */
			if ( this.actionFullDates.current.getTime() > this.actionFullDates.participation.end.getTime() ){
				if(this.website === 'portail' && this.isUserAbleToSendReservedCashbacks){
					// change of url, the new website will make it's own tests and display it's own "actionEnded" page.
					this.moveToCashbacksAction(); // method in Form.vue
				}else{
					// action has ended
					this.participatingIsNotAllowedAnymore = true;
					this.dateStatus = "actionEnded";

					// If there is an "ope" param in the url, an operation is in progress and the redirection should be blocked 
					// As example, "ope" could be reset password (resetpass).
					let urlParams = new URLSearchParams(window.location.search);					
					if (!urlParams.has('ope')){
						this.log("There is no 'ope' in progress, I can proceed with normal redirection", 'info');
						this.goto('actionEnded');
					}else{
						this.log("There is an 'ope' in progress, I should halt the redirection", 'info');
					}
				}
			}
			/* Time ok for participation */
			if ( 
				this.actionFullDates.current.getTime() > this.actionFullDates.participation.start.getTime() && 
				this.actionFullDates.current.getTime() < this.actionFullDates.participation.end.getTime() )
			{
				this.putDataInFields();
				this.justBeforeLoadingTheFormPage();
			}
		},

		checkReparticipationDates(){


			/* This code was added in another action, but was not tested (no cashback then) */
			/*
			// if there is no param "ope" in the url
				let urlParams = new URLSearchParams(window.location.search);
				if (!urlParams.has('ope')){
					this.log("There is no 'ope' in progress", 'info');
					this.goto('actionEnded');
				}else{
					this.log("There is an 'ope' in progress", 'info');
				}
				*/


			if (this.actionFullDates.current.getTime() < this.actionFullDates.participation.start.getTime() ) {
				/* never occurs, as it cannot exist a reparticipation state if there is no participation. But nevertheless… */
				this.goto('beforeStart');
			}
			if ( this.actionFullDates.current.getTime() >= this.actionFullDates.reparticipation.end.getTime() ) {
				// Info Attention: in Wowi cashback, Users have 7 days bonus for their reservated material.
				// Info the actionEnded page display a special link. Nothing to do here.
				// TODO except for reparticipation probably : store keys/data.
				this.goto('actionEnded');
				this.dateStatus = "actionEnded";
			}
			if ( this.actionFullDates.current.getTime() >= this.actionFullDates.participation.start.getTime() &&
				this.actionFullDates.current.getTime() < this.actionFullDates.reparticipation.end.getTime()
				) {
				this.putDataInFields();
			
			this.justBeforeLoadingTheFormPage();
		}
	},

},
computed: {
	isUserAbleToSendReservedCashbacks(){
			/* Rule: the user can still connect and send cashbacks while currentDate < endDate + delay */
		let delay = 8 * 24 * 60 * 60 * 1000;
		return this.actionFullDates.current.getTime() < this.actionFullDates.participation.end.getTime() + delay;
	},
},

}