<template>
	<div class="modalebox">
		<header>
			<h2>{{title}}</h2>
			<div class="closebutton" @click="closeMe()"></div>
		</header>
		<main>
			<slot><!-- Content --></slot>
		</main>		
	</div>
</template>

<!-- ================================================================================== -->

<script>
	export default {
		name:"Modale",
		props: {
			title: {
				type: String,
				required: true,
				default: "— no title —",
			},
		},
		methods: {
			closeMe(){
				this.$emit('closeMe');
			},
		}
	}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>
</style>
