import axios from 'axios'
import store from "../store/store";
//import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";

export default class Axios {
    constructor(config/*, finger*/) {
    /*if (typeof finger === 'undefined') {
        throw new Error('Cannot be called directly')
    }*/

	let baseHeader = {}

	const modifiedConfig = config
	const baseUrl = config.baseUrl

	if (Object.prototype.hasOwnProperty.call(modifiedConfig, 'customHeaders')) {
		baseHeader = {...baseHeader, ...config.customHeaders}
	}

	if (!Object.prototype.hasOwnProperty.call(baseHeader, 'Accept')) {
		baseHeader['Accept'] = 'application/json'
	}

	if (!Object.prototype.hasOwnProperty.call(baseHeader, 'Content-Type')) {
		baseHeader['Content-Type'] = 'application/json'
	}

	let service = axios;

	if (config.baseUrl) {
		service.defaults.baseURL = config.baseUrl
	} else {
		service.defaults.baseURL = baseUrl
	}

	if (config.customHeaders) {
		const finalHeader = {...baseHeader, ...config.customHeaders}
		for (const key of Object.keys(finalHeader)) {
			service.defaults.headers.common[key] = finalHeader[key]
		}
	} else {
		for (const key of Object.keys(baseHeader)) {
			service.defaults.headers.common[key] = baseHeader[key]
		}
	}

	service.interceptors.request.use(function(config) {
        // Do something before request is sent

		/* Preparing the required data to add to EVERY request (GET, DELETE, POST, PUT, PATCH) */
		const baseBody = {
			Participation: {
				fingerPrint: store.getters.getFingerprint,
			},
			fingerPrint: store.getters.getFingerprint,
			lang: store.getters.getLang
		}

		if(config.method === 'get' || config.method === 'delete'  ) {
			config.params = {...config.data, ...baseBody};
		}

		if(config.method === 'post' || config.method === 'put' || config.method === 'patch' ) {
			if(config.data instanceof FormData) {
				/* I don't know when it's the case… */
				config.data.append('fingerPrint', baseBody.fingerPrint);
				config.data.append('lang', baseBody.lang);
			}else{
				// Add/replace basic var htat must be present in every request
				if(config.data.Participation){
					// config.data.Participation.fingerprint = baseBody.fingerprint;
					config.data.Participation.fingerPrint = baseBody.fingerPrint;
				}
				// config.data.fingerprint = baseBody.fingerprint;
				// config.data.fingerPrint = baseBody.fingerprint;
				config.data.lang = baseBody.lang;
				// Next : Adding the UTMs
				// Get list of available then for each, if exist, add it to config.data
				const listOfStoredUtms = store.getters.getUtms;
				const listOfExpectedUTMs = [
					{name: 'source', varname: 'UTMSource'},
					{name: 'medium', varname: 'UTMMedium'},
					{name: 'campaign', varname: 'UTMCampaign'},
					{name: 'id', varname: 'UTMId'},
					{name: 'term', varname: 'UTMTerm'},
					{name: 'content', varname: 'UTMContent'},
					];
				listOfExpectedUTMs.forEach(item => {
					// Only if there IS an utm known, I add it to the config.data
					if(listOfStoredUtms[item.name]){
						if(config.data.ParticipationOwner){
							config.data.ParticipationOwner[item.varname] = listOfStoredUtms[item.name];
						}
						config.data[item.varname] = listOfStoredUtms[item.name];
					}
				});
			}
		}
		return config;
	}, function (error) {
            // Do something with request error
		return Promise.reject(error);
	});

	return service;
}
}
