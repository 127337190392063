import config from "../config";

export default {
	data() {
		return {
			/* === modifiable data =========================*/

			/* G.A. for Gamned */
			clientGaMustBePresent: true,
			gaClient: 'AW-11432763221',
			
			/* === don't modify below this point =========================*/		
			consentOverlayID: 'htmllang/be109216-2735-4008-bac8-9298608f812a',
			lang: '',
			country: '',
			loaded: false,
		}
	},
	methods: {



		insertOneTrustTag(){
			this.setLangVariables()
			let script = document.createElement('script')
			script.innerHTML = `
			var PGdataLayer =
			{
				GTM: 
				{
					ConsentOverlay: "OneTrust",
					ConsentOverlayID: "${this.consentOverlayIDComputed}",
					SiteBrand: "Ucran",
					SiteCountry: "${this.country}",
					SiteEnvironment: "Prod",
					SiteHost: "highactions",
					SiteLanguage: "${this.lang}",
					SiteLocalContainer: "",
					SiteLocale: "${this.fullLangString}",
					SitePlatform: "",
					SitePrivacyProtection: "GDPR",
					SiteStatus: "Live",
					SiteTechnicalAgency: "HighCoData",
					SiteTouchpoint: "",
					GoogleAnalyticsLocal: "${this.gaClient}",
					GoogleAnalyticsConsentRequired: "true",
					GoogleAnalyticsBrand: "",
					GoogleAnalyticsSiteSpeedSampleRate: "",
					GoogleAnalyticsAllowLinker: "",
					GoogleAnalyticsLinkerDomains: "",
					GoogleAnalyticsEnableOptimizely: "",
					GoogleAnalyticsOptimizeContainerID: "",
					GoogleAnalyticsReportingView: "",
					FacebookConnectAppID: "",
					FacebookConnectLocale: "",
					FacebookRemarketingID: "",
					BINPlatform: "",
					GoogleReCaptcha: ""
				}
			}
			`;
			document.head.prepend(script);
		},




		insertGtmTag() {
		// GTM script 
			let script = document.createElement('script')
			script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
			var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
			j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
			f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${this.gtm}');`;
			document.head.appendChild(script);

		// GTM noscript 
			let noscript = document.createElement('noscript');
			noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${this.gtm}" 
			height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
			document.body.prepend(noscript);
		},

		insertEventSnippet() {
			let script = document.createElement('script');
			script.innerHTML = `
			function gtag_report_conversion(url) {
				var callback = function () {
					if (typeof(url) != 'undefined') {window.location = url;};
					gtag('event', 'conversion', {
						'send_to': 'AW-11432763221/kTOkCMjdvvwYENW-yMsq',
						'event_callback': callback
					});
					return false;
				}
			}`;
			document.head.appendChild(script);
		},

		insertGTag() {
			let script = document.createElement('script');
			script.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${this.gaClient}`);
			script.setAttribute('async', '');
			document.head.appendChild(script);

			script = document.createElement('script');
			script.innerHTML = `window.dataLayer = window.dataLayer || []; 
			function gtag(){dataLayer.push(arguments);} 
			gtag('js', new Date()); 
			gtag('config', '${this.gaClient}');`;
			document.head.appendChild(script);
		},

		insertNoScript(){
			let noscript = document.createElement('noscript');
			noscript.innerHTML = '<img src="https://ad.doubleclick.net/ddm/activity/src=11232842;type=invmedia;cat=flood0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>';
			document.body.prepend(noscript);
		},

		updatePGDataLayer() {
		/* Must get the correct new destination language */
			this.setLangVariables();
		/* Only then… */
			if (window.OneTrust) {
				window.OneTrust.changeLanguage(this.lang);
			}
		},
		setLangVariables() {
			let helper = this.$i18n.locale.split('_');
			this.lang = helper[0];
			this.country = helper[1];
			document.getElementsByTagName('html')[0].lang = this.lang;
		},
	},
	watch: {
		'$i18n.locale': {
			handler: function () {
				this.updatePGDataLayer();
			},
		},
	},
	computed: {
		fullLangString () {
			return this.lang + '_' + this.country;
		},
		consentOverlayIDComputed () {
			if (process.env.NODE_ENV !== 'production') {
				return this.consentOverlayID + '-test';
			}
			return this.consentOverlayID;
		}
	},
	beforeMount() {

		if(config.isWebsiteInProduction){
		/* Insert GA Tag for tracking by Gamned */
			if(this.clientGaMustBePresent){
				this.insertGTag();
				this.insertEventSnippet();
			}

		/* Insert a noscript, just in case */
			this.insertNoScript();

		}
	},
	mounted() {
		this.loaded = true;
		console.log("----- GA is mounted -----");
	}
}
