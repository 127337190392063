/* global gtag_report_conversion */

export default {
	/* ======== DATA ======== */
	data () {
		return {
			ownerID: null,
			cryptedId: null,
			userInfos: {},
			errorModaleMessage: null,
			errorModaleTitle: "Error",
			userIsLogged: false,
			loginErrorIsVisible: false,
			codeErrorIsVisible: false,
			filterWowiCardsBy: 3,
			qttLimitBeforeOfferDisplayAnAlert: 10, // Percentage
			timeLimitBeforeOfferDisplayAnAlert: 1000 * 60 * 60 * 24 * 3, // 3 Days
			wowicards: [], // coupons
			selectedCashbacks: [], // ids of selected cashbacks
			productAlreadyConsumedList: [],
			fullListOfPostalCode: null,
			userAlreadySentThisNumberOfValidationCode: 0,
			receivedLink: "",
			consumedCards: [],
			lastCodeSent: null,
			lastValidCodeSent: null,
			isResendCodeButtonVisible: false,
			userJustRegistered: false,
			userLoggedWithCoupons: false,
			userLoggedWithCashbacks: false,
			theCodeMustBeSentWithNextRequest: false,
			isUserBanned: false,

			// States for refresh user operations
			methodGetOwnerDetailsIsDone: false,
			methodGetListOfAlreadyConsumedCouponsIsDone: false,
			methodGetListOfAlreadyReservedCashbacksIsDone: false,
			methodGetListOfAlreadyConsumedCashbacksIsDone: false,

			// Operations state flags
			operations : {
				login_request: false,
				registrationDataSent_request: false,
				confirmRegistration_request: false,
				resetPassword_request: false,
				userChangingPassword_request: false,
				updateCredentials_request: false,
				endingContinue_request: false,
				refreshUserData_request: false,
				printCoupons_request: false,
				mailCoupons_request: false,
				userAskedCashbacksReservation: false,
				userAskedCashbacksOrder: false,
				sendingCashbackInProgress: false,
				userAskedPrimeOrder: false,
			},			
			/* Keeping track of user movement */
			userHasClickedOnLoginButton: false,
			userClickedOnRegisteringButton: false,


		}
	},




/*

COMPUTED

*/

	computed: {

		isThereAtLeastOneSelectedCashback(){
			return this.selectedCashbacks.length > 0;
		},

		isThereOneSelectedReservedCashback(){
			return this.listOfReservedCashbacks.some(id => this.selectedCashbacks.includes(parseInt(id)));
		},

		computedIdLead(){
			return this.$store.getters.getLeadId;
		},

		computedDataRange(){
			return {key: 'idcountrycode', value: this.fieldsValues.idCountryCode};
		},

		profilePasswordFieldIsDisabled(){
			return this.fieldsValues.phoneNumber1 !== this.userInfos.phoneNumber1 || this.fieldsValues.email !== this.userInfos.email;
		},

		profileEmailFieldIsDisabled(){
			return this.fieldsValues.phoneNumber1 !== this.userInfos.phoneNumber1 || !!this.fieldsValues.password;
		},

		profilePhoneFieldIsDisabled(){
			return this.fieldsValues.email !== this.userInfos.email || !!this.fieldsValues.password;
		},

		isThereAtLeastOneSelectedProduct(){
			return Object.values(this.fieldsValues.wowicards).some(val => val === true);
		},

		isCardClaimed(){
			return card =>{
				return this.consumedCards.includes(card.id);
			}
		},

		isRegisterButtonDisabled(){
			let fieldsAllFilled = this.areTheseFieldsFilled('registrationMandatoryFieldsList');
			/* If all fields are filled, button is ENabled. */
			/* So, I must return the inverse of the value if I want to DISable the button. */
			return !fieldsAllFilled;
		},

		sortedLiveWowiCards(){
			return this.randomizeArray(this.sortedWowiCards.filter(card => !this.isCardExpired(card) && !this.isCardClaimed(card) && !this.isCardSoldOut(card)));
		},
		
		sortedDeadWowiCards(){
			return this.sortedWowiCards.filter(card => this.isCardExpired(card) || this.isCardClaimed(card) || this.isCardSoldOut(card));
		},

		sortedWowiCards(){
			try{
				switch (this.filterWowiCardsBy){
				case 0: 
					this.log("Filter cards by id", 'low');
					this.defaultSortWowicards();
					break;
				case 1:
					this.log("Filter cards by price ASC", 'low');
					this.sortWowicardsByPriceAsc();
					break;
				case 2:
					this.log("Filter cards by price DESC", 'low');
					this.sortWowicardsByPriceDesc();
					break;
				case 3:
					this.log("Filter cards by left QTT/TIME", 'low');
					this.sortWowicardsByLeftOffers();
					break;
				case 4:
					this.log("Filter cards by random order", 'low');
					this.sortWowicardsByRandom();
					break;
				case 5:
					this.log("Filter cards by user selection", 'low');
					this.sortWowicardsByUserSelection();
					break;
				default: 
					this.defaultSortWowicards();
					break;
				}
			}catch(e){
				this.log(e, "alert");
			}
			return this.wowicards;
		},
	},






/*

METHODS

*/

	methods: {

		itemExistsInSynonyms(id){
			return this.synonyms.some(obj => parseInt(obj.idpopitem) === parseInt(id));
		},

		isThisCardClaimed(id){
			return this.consumedCards.includes(id);
		},

		isThisPrimeClaimed(id){
			return this.consumedCards.includes(id);
		},

		isThisCouponClaimed(id){
			let claimed = this.consumedCards.includes(id)
			this.log(`Coupon ${id} ${claimed ? 'already' : 'not'} claimed`, `${claimed ? 'red' : 'green'}`);
			return claimed;
		},

		isThisCashbackClaimed(id){
			if(this.listOfConsumedCashbacks){
				return this.listOfConsumedCashbacks.includes(String(id));
			}else{
				return false;
			}
		},

		isThisCashbackReserved(id){
			if(this.listOfReservedCashbacks){
				return this.listOfReservedCashbacks.includes(String(id));
			}else{
				return false;
			}
		},

		isThisCashbackSelected(id){
			return this.selectedCashbacks.includes(id);
		},

		isCardExpired(card){
			let currentDate = new Date();
			let expiredDate = new Date(card.onlineenddate);
			// Take a special case into account: action is finished, coupons are not
			// was possible with Promolife. Should be an accident in our custom tool.
			if (this.dateStatus === "actionEnded"){
				return true;
			}else{
				return expiredDate <= currentDate;
			}
		},

		isCardSoldOut(card){
			return card.offersStillAvailableCounter <= 0;
		},

		updateSelectionOfOneCard(targetId, cardSelectionState){
			// attention this.wowicards et this.fieldsValues.wowicards sont différents !
			this.wowicards.forEach(card => {
				if (card === targetId){
					this.log(`A card with the id ${targetId} was found`, 'low');
					card.selected = cardSelectionState;
				}
			});
			this.fieldsValues.wowicards[targetId] = cardSelectionState;
		},

		userClickedOnLogoutButton(){
			/* Closing menu */
			this.setMenuVisibility(false);
			/* Continuing */
			this.log("User clicked on the login button.", 'low');
			// this.toggleLoginControlsVisibility(false);
			this.userWantToLogout();
		},

		endAllOperationInProgress(){
			// Pass value of every key to false
			for (let key in this.operations){
				this.operations[key] = false;
			}
		},


		userHaveStatus30(){
			this.log("Preventing user's login", 'info');

			/* * Don't display an error anymore, but change to a blank page */
			/*
			let errorTitle = this.$t('errors.error');
			let errorMessages = [this.$t('errors.accesBlocked')];
			this.displayModaleMessages(errorTitle, errorMessages, null, 'alert');
			*/
			
			this.isUserBanned = true;	
			this.goto('banned');		

			// FORCE UNLOG & CLEAN
			this.cleanBehindLoggedUser();
			this.displaySpinner(false);
		},


		getItemDataToDisplay(id){
			this.log(`getItemDataToDisplay(${id})`, 'function');

			this.log(this.popitemlist);

			/* Returns what is necessary to display info to user about his reserved products */
			let concernedItem = this.popitemlist.filter(item => item.id === id)[0];
			this.log(concernedItem, 'table');
			let valueToDisplay;

			if (concernedItem.cashbackpercentage){
				let content = parseInt(concernedItem.cashbackpercentage); 
				valueToDisplay = `${content} %`;				
			}
			if (concernedItem.cashbackfixedvalue){
				let content = parseFloat(concernedItem.cashbackfixedvalue).toFixed(2);
				let currentLang = this.$store.getters.getLang;
				if(currentLang === "fr"){
					valueToDisplay = `${content} €`;
				}else{
					valueToDisplay = `€ ${content}`;
				}
			}
			return {
				code: concernedItem.code,
				description: concernedItem.description,
				value: valueToDisplay,
			};
		},



		clearLocalStorage(){
			this.log("clearLocalStorage", "low");
			localStorage.removeItem("cid");
			sessionStorage.removeItem("wowicards");
			// localStorage.clear();
		},

		moveToRegisterPage(){
			this.userClickedOnRegisteringButton = true;
			this.log("moveToRegisterPage", "low");
			this.turnThisModaleVisibility('login', false);
			this.fieldsValues.password = undefined;
			this.goto('register');
		},

		sortWowicardsByLeftOffers(){
			this.wowicards.sort((a, b) => {
				let currentDate = new Date();
				let remainingTime_A = new Date(a.onlineenddate).getTime() - currentDate.getTime();
				let remainingTime_B = new Date(b.onlineenddate).getTime() - currentDate.getTime();
				const aCondition = remainingTime_A < this.timeLimitBeforeOfferDisplayAnAlert || a.offersStillAvailableCounter < this.qttLimitBeforeOfferDisplayAnAlert;
				const bCondition = remainingTime_B < this.timeLimitBeforeOfferDisplayAnAlert || b.offersStillAvailableCounter < this.qttLimitBeforeOfferDisplayAnAlert;
				if (aCondition && !bCondition) {
      			return -1; /* 'a' comes before 'b' */
				} else if (!aCondition && bCondition) {
      			return 1; /* 'b' comes before 'a' */
				} else {
      			return 0; /* Maintain the current order */
				}
			});
			this.putObsoleteOffersAtTheEnd();
		},

		sortWowicardsByQttRemaining(){
			this.wowicards.sort(a => {
				let remainingQtt_A = a.offersStillAvailableCounter;
				if(remainingQtt_A > this.qttLimitBeforeOfferDisplayAnAlert){
					return 1;
				}
				if(remainingQtt_A <= this.qttLimitBeforeOfferDisplayAnAlert){
					return -1;
				}
			});
		},

		backToProfile(){
			// Revert user choice if he don't save it.
			this.fieldsValues.optin = this.userInfos.optin;
			this.goto('profile');
		},

		sortWowicardsByTimeRemaining(){
			let currentDate = new Date();
			this.wowicards.sort((a, b)=>{
				let remainingTime_A = new Date(a.expireson).getTime() - currentDate.getTime();
				let remainingTime_B = new Date(b.expireson).getTime() - currentDate.getTime();
				if (remainingTime_A <= remainingTime_B) {
					return 1; 
				} else if (remainingTime_A > remainingTime_B) {
					return -1;
				}
			});
		},

		sortWowicardsByUserSelection(){
			this.wowicards = this.sortArrayByKeyDesc(this.wowicards, 'selected');
		},
		sortWowicardsByPriceAsc(){
			this.wowicards = this.sortArrayByKeyAsc(this.wowicards, 'price');
		},
		sortWowicardsByPriceDesc(){
			this.wowicards = this.sortArrayByKeyDesc(this.wowicards, 'price');
		},
		sortWowicardsByRandom(){
			this.wowicards = this.randomizeArray(this.wowicards);
		},
		defaultSortWowicards(){
			this.wowicards = this.sortArrayByKeyAsc(this.wowicards, 'id');
		},

	/* Takes an array, return the randomized array */
		randomizeArray(array){
			let m = array.length, t, i;
			while (m) {
				i = Math.floor(Math.random() * m--);
				t = array[m];
				array[m] = array[i];
				array[i] = t;
			}
			return array;
		},

		sortArrayByKeyDesc(array, key){
			this.log(`Sorting by ${key} DESC`, "low");
			array.sort((a, b) => {
				return b[key] - a[key];
			});
			return array;
		},

		sortArrayByKeyAsc(array, key){
			this.log(`Sorting by ${key} ASC`, "low");
			array.sort((a, b) => {
				return a[key] - b[key];
			});
			return array;
		},

		putObsoleteOffersAtTheEnd(){
			let currentDate = new Date();
			this.wowicards.sort((a, b)=>{
				if (new Date(a.expireson) <= currentDate && new Date(b.expireson) > currentDate) {
					return 1;
				}
				else if (new Date(a.expireson) > currentDate && new Date(b.expireson) <= currentDate) {
					return -1;
				}
			});
		},

		userForgotHisPassword(){
			this.closeAllModales();
			this.goto('resetPassword');
		},

		collectRegistreringData(){
			this.log("collectRegistreringData", 'function');
			let address = {};
			if(!!this.fullListOfPostalCode && !!this.fieldsValues.crmpostalcodeId){
				this.log("Computing address values", 'low');
				let filteredLocalite = this.fullListOfPostalCode.filter(item => item.id === this.fieldsValues.crmpostalcodeId);
				address.city = filteredLocalite[0].city;
				address.postalCode = filteredLocalite[0].postalcode;
				address.idCountryCode = this.fieldsValues.idCountryCode;
			}else{
				this.log("Missing data for collectRegistreringData()", 'alert');
			}
			let registeringData = {
				firstName: this.fieldsValues.firstName,
				lastName: this.fieldsValues.lastName,
				email: this.fieldsValues.email.toLowerCase(),
				optin: this.fieldsValues.optin,
				generalCondition: this.fieldsValues.generalCondition,
				phoneNumber1: this.fieldsValues.phoneNumber1,
				crmpostalcodeId: this.fieldsValues.crmpostalcodeId,
				password: this.fieldsValues.password,
				idLanguageCode: this.getIdOfLanguage(),
				...address
			};
			return registeringData;
		},

		collectLoginData(){
			return {
				email: this.fieldsValues.email.toLowerCase(),
				password: this.fieldsValues.password,
			};
		},

		displaySpinner(bool){
			this.pendingParticipationSent = bool;
		},

		removeReservedCashbacksFromUserSelection(){
			this.log("wowilogic > removeReservedCashbacksFromUserSelection()", 'function');
			this.selectedCashbacks.forEach(item => {
				if (this.listOfReservedCashbacks.includes(String(item))){
					let indexToDelete = this.selectedCashbacks.indexOf(item);
					this.selectedCashbacks.splice(indexToDelete, 1);
				}
			});
			this.log("Reserved cashback cleaned. Now in the selection:");
			this.log(this.selectedCashbacks, 'table');
		},

		removeConsumedCashbacksFromUserSelection(){
			this.log("wowilogic > removeConsumedCashbacksFromUserSelection()", 'function');
			this.selectedCashbacks.forEach(item => {
				if (this.listOfConsumedCashbacks.includes(String(item))){
					let indexToDelete = this.selectedCashbacks.indexOf(item);
					this.selectedCashbacks.splice(indexToDelete, 1);
				}
			});
			this.log("Consumed cashback cleaned. Now in the selection:");
			this.log(this.selectedCashbacks, 'table');
		},


		// WIP isUserLogged ? returns this.userIsLogged ?
		userAskedCashbackOperation(str){
			this.log(`User asked a cashback operation: ${str}`, 'wip');
			if(str === 'reserve'){
				this.operations.userAskedCashbacksReservation = true;
				this.participationCode = "RESERVAT";
			}
			if(str === 'order'){
				this.operations.userAskedCashbacksOrder = true;
				this.participationCode = "PART";
				// TODO could be a reparticipation
				// this.participationCode = "REPART";
				/* Pixie */
				pixie('event', 'Demander_cashback');
				fbq('trackCustom', 'DemanderCashback');
			}
			if(this.userIsLogged){
				this.continuePreviousOperation();
			}else{
				this.requireUserToLog();
			}
		},



		userTryToSendHisPrimes(){
			this.log("userTryToSendHisPrimes", 'function');
			this.operations.sendingPrimesInProgress = true;
			this.participationCode = "PART";
			this.requestInfoAboutDevice();
		},

		userValidateHisCashbackSelection(){
			pixie('event', 'Valider_selection_cashback');
			fbq('trackCustom', 'ValiderSelection');
			this.goto('proofOfPurchasePage');
		},

		userModifyItsSelection(){
			// User had a selection, but choose to modify it
			this.userAskedCashbacksOrder = false;
			this.goto('wowi');
		},

		userTryToSendHisCashbacks(){
			// user filled the form and send his participation
			this.log("userTryToSendHisCashbacks", 'function');
			this.operations.sendingCashbackInProgress = true;
			this.participationCode = "PART";
			pixie('event', 'Envoyer_cashback');
			fbq('trackCustom', 'EnvoyerCashback');
			this.requestInfoAboutDevice();
		},

		updateSelectedCashbacksList(data){
			this.log(`Update cashback ${data.id} with selected = ${data.selected}`, 'low');
			let id = parseInt(data.id);
			if(data.selected){
				// add to the list
				this.selectedCashbacks.push(id);
			}else{
				let indexOfElementToRemove = this.selectedCashbacks.indexOf(id);
				// 0 is a valid response. Cannot use !!indexOfElementToRemove
				if (indexOfElementToRemove != null && indexOfElementToRemove != undefined){
					this.selectedCashbacks.splice(indexOfElementToRemove, 1);
				}
			}
		},

		updateWowiCardList(data){
			/* User clicked on a card to (un)select it */
			this.log(`User clicked on a button. I must pass selected state of card ${data.id} to ${data.selected}.`, 'low');
			this.updateSelectionOfOneCard(data.id, data.selected);
			this.storeCardsSelectionInLocalStorage();
			/* ! trigger pixie */
			if(data.selected){
				/* condition => only if card is ADDED to the "cart" */
				pixie('event', 'Offre_Click');
				fbq('track', 'AddToCart');
			}
		},

		emptyFieldsValueAfterLogout(){
			this.log("emptyFieldsValueAfterLogout()", "low");
			this.userInfos = {};
			this.selectedCashbacks = [];
			/* this.fieldsValues = {}; // This is too radical and cause troubles */
			/* => Fine tuning of the cleanse */
			this.fieldsValues.firstName = null;
			this.fieldsValues.lastName = null;
			this.fieldsValues.crmpostalcode = null;
			this.fieldsValues.city = null;
			this.fieldsValues.postalCode = null;
			this.fieldsValues.idCountryCode = null;
			this.fieldsValues.phoneNumber1 = null;
			this.fieldsValues.email = null;
			this.fieldsValues.optin = 0;
			this.fieldsValues.password = null;
			// this.fieldsValues.wowicards = {};
		},

		convertApiPrimesToFormPrime(data){
			this.log('convertApiPrimesToFormPrime()', 'function');
			this.log(data);
			let modifiedData = data.map(item => {
				return {
					...item,
					//price : item.nobon.nvaleu,
					//offersleft : item.offersStillAvailableCounter,
					//expireson : item.onlineenddate,
					selected: false, /* must pre-exist or else, the reorder doesn't happens quite well */
				}
			});
			return modifiedData;
		},

		convertApiCouponsToFormCoupons(data){
			/* This add keys with the values I'm looking at to sort */
			let modifiedData = data.map(item => {
				return {
					...item,
					price : item.nobon.nvaleu,
					offersleft : item.offersStillAvailableCounter,
					expireson : item.onlineenddate,
					selected: false, /* must pre-exist or else, the reorder doesn't happens quite well */
				}
			});
			return modifiedData;
		},

		getLocalisationDetailsFromCrmPostalCode(id){
			// Search for an id in the data
			let theSelectedId = this.fullListOfPostalCode.filter(item => item.id === id);
			// Return the first (and only) result
			return theSelectedId[0];
		},

		collectCredentials(){
			this.log("collectCredentials", 'function');
			let data = {
				/* Default, must always be present */
				"idCRMParticipationOwner": this.userInfos.id
			};

			/* Loop in the fields from the form and detect changed values */
			// TODO quand le back sera prêt, ajouter la langue : idLanguageCode ici et dans le form !
			['firstName', 'lastName', 'crmpostalcodeId', 'optin', 'email', 'phoneNumber1', 'idLanguageCode', 'generalCondition'].forEach(item => {
				this.log(`Checking ${item}`, 'req');
				this.log(`Old value: ${this.userInfos[item]}`);
				this.log(`New value: ${this.fieldsValues[item]}`);
				// Converting to String to avoid !!0 that is false, and I want to test if not undefined and not null.
				let oldValue = "" + this.userInfos[item];
				let newValue = "" + this.fieldsValues[item];
				// if there IS a new value AND the newValue is different from the oldValue
				if(!!newValue && newValue !== oldValue){
					this.log("The difference is detected, the data updated", "green");
					data[item] = this.fieldsValues[item];
					if(item === "generalCondition"){
						data['generalcondition'] = this.fieldsValues[item];
					}
				}
				// If crmpostalcodeId changed, I must send each address element separately
				if(item === 'crmpostalcodeId' && !!newValue && newValue !== oldValue){
					let adresse = this.getLocalisationDetailsFromCrmPostalCode(this.fieldsValues[item]);
					data.postalCode = adresse.postalcode;
					data.idCountryCode = adresse.idcountrycode;
					data.city = adresse.city;
				}
			});
			/* PWD case (different var names), can't be checked as I can't decrypt it */
			if(this.fieldsValues.password){
				data.PWD = this.fieldsValues.password;
			}
			this.log("Data for updating credentials", 'wip');
			this.log(data, 'table');
			return data;
		},

		storeReceivedUserDataInStandardVariableName(data){
			this.log("convertVarNameToFormStandard", 'function');
			this.log("Données brutes avant traitement :");
			this.log(data);
			/* =============================== */
			this.userInfos.id = data.id;
			this.userInfos.email = data.email;
			this.userInfos.iban = data.iban;
			this.fieldsValues.iban = data.iban;
			this.userInfos.lastName = data.lastname;
			this.userInfos.firstName = data.firstname;
			// Whatever these two are, if it's not 1, it's 0.
			this.userInfos.optin = (data.optin === "1" || data.optin === 1) ? 1 : 0;
			this.userInfos.generalCondition = (data.generalcondition === "1" || data.generalcondition === 1) ? 1 : 0;
			this.userInfos.crmpostalcodeId = data.idpostalcode; // Different names
			this.userInfos.idLanguageCode = data.idlanguagecode;
			this.userInfos.phoneNumber1 = data.phonenumber1;
			this.userInfos.codeValidation = data.codevalidation; // ? what for, data.codevalidation is always null.
			/* Those could be deduced, or used as-is */
			this.userInfos.city = data.city;
			this.userInfos.postalCode = data.postalcode;
			this.userInfos.idCountryCode = data.idcountrycode;
			/* =============================== */
			this.log(this.userInfos, "table");
		},

		displayUserInfo(){
			/* From button on Admin panel */
			this.log("User Infos", 'info');
			this.log(this.userInfos, "table");
		},

		storeCardsSelectionInLocalStorage(){
			let list = JSON.stringify(this.fieldsValues.wowicards);
			sessionStorage.setItem("wowicards", list);
		},

		getCardsSelectionFromSessionStorage(){
			if( sessionStorage.getItem("wowicards") === null){
				this.log("No wowicards in LocalStorage", 'info');
				return {};
			}else{
				return JSON.parse(sessionStorage.getItem("wowicards"));
			}		
		},


		getListOfSelectedCashbacksOffersIds(){
			this.log("List of selected cashbacks", "sep");
			this.log(this.selectedCashbacks, 'table');
		},

		getListOfSelectedOffersIds(){
			this.log("List of selected coupons", "sep");
			let obj = this.fieldsValues.wowicards;
			this.log("Looking for Ids in fieldsValues.wowicards", "low");
			this.log(obj);
			let selectedOffersList = Object.keys(obj).filter(key => obj[key] === true);
			this.log("Selected offers found", 'low');
			this.log(selectedOffersList, 'table'); /* This is a list of IDs ! */
			this.log("transform [128, 321] to [{idLeadCoupon: 128, quantity: 1}]");
			let listOfCouponObject = [];
			selectedOffersList.forEach(id => {
				listOfCouponObject.push({idLeadCoupon: id, quantity: 1}); // ! HARDCODED QTT
			});
			return listOfCouponObject;
		},

		updatingFormWithStoredUserData(){
			this.updateLanguage();
			this.dispatchDataToFields();
			this.updateWowiCardListWithLocalStorageData();
		},

		logUser(){
			this.log("logUser", 'function');
			this.log("logUser is empty", 'alert');
			/* NEXT : see in getOwnerDetails callbacks */
		},

		/* From function.js when user use the special link in the mail */
		userHasClickedTheMailLinkToChangePassword(crm=null){
			this.log(`userHasClickedTheMailLinkToChangePassword(${crm})`, 'function');
			this.displaySpinner(true);
			this.log(`I got a CRM from the calling function: ${crm}`, 'wip');
			// I should already know the userInfos.id here

			if(crm){
				this.cryptedId = crm;
				this.log(`The cryptedId is ${this.cryptedId}`);
			}

			if(this.cryptedId){
				localStorage.setItem("cid", this.cryptedId);
				this.reloadOffersPage = false;
				this.operations.refreshUserData_request = true;
				this.operations.userChangingPassword_request = true;
				this.continuePreviousOperation();
			}else{
				let title = "Technical error";
				let errorDescriptionList = ["No CRM found"];
				this.displayModaleMessages(title, errorDescriptionList, 'alert');
			}
		},
		

		userRequestsNewCode(){
			/* This function is called when user click on "send code" button in the modale */
			this.log("userRequestsNewCode", 'function');
			this.isResendCodeButtonVisible = false;
			this.userAlreadySentThisNumberOfValidationCode = 0;

			/* TODO update here
	
	Plutôt que "default", envoyer ces codes selon le cas.
	// comment savoir quel cas ?
	// regarder les operations courantes ?

	cashback => quand un cashback est demandé
	cashback-reservation => quand un cashback est réservé
	promo-code => quand une prime / promo-code est demandé
	
			*/

			this.sendRequestForValidationCode('default');
		},

		openRegistrationPage(){
			this.emptyFieldsValueAfterLogout();
			this.goto('registration');
		},

		checkForCleanRequestInUrl(){
			/* ! Put in place for specific case when we delete user in DB and localStorage keep the ID */
			this.log("Clean request detection", 'wip');
			/* check l'url for "renewUser" */
			let urlParams = new URLSearchParams(window.location.search);
			if (urlParams.has('renewUser')){
				this.log("renewUser is present");
				this.clearLocalStorage();	
				/* and I can stop here */
			}else{
				this.log("renewUser is not present, nothing to do");
			}
		},

		checkIfLocalUserDataExists(){
			/* Context: form just started, I'm checking if I know the user already to connect him and load it's data */
			this.checkForCleanRequestInUrl();
			/* If user selected some coupon previously, reselect them */
			this.updateWowiCardListWithLocalStorageData();	
			/* If cryptedId exist in localStorage, user is logged */
			this.getUserDetailFromSavedCryptedCode();
			// Nothing more below this point, because call API will be made.
		},

		updateWowiCardListWithLocalStorageData(){
			let listToPutInForm = this.getCardsSelectionFromSessionStorage();
			if (listToPutInForm.length === 0){
				this.log("No wowicards where selected prior.", 'low');
				this.isThereAtLeastOneSelectedProduct = false; 
			}
			else {
				this.log("There WAS some card selected prior.", 'low');
				this.fieldsValues.wowicards = listToPutInForm; 
				this.wowicards.forEach(card => {
					if (this.fieldsValues.wowicards[card.id]){
						card.selected = true;
					}
				});
				this.log("Selected cards: ", 'low');
				this.log(this.fieldsValues.wowicards);
			}
		},





		getUserDetailFromSavedCryptedCode(){
			this.log("getUserDetailFromSavedCryptedCode", 'function');
			this.log(`Getting the crypted id from localStorage.`, 'info');
			let storedData = localStorage.getItem("cid");
			this.log("What was found: ");
			this.log(storedData);
			

			if(storedData){
				let item;
				try{
					item = JSON.parse(storedData);
				}catch(err){
					item = storedData;
				}
				this.log(`Type: ${typeof item}`);
				if(typeof item === 'string'){
					// old format, remove
					localStorage.removeItem("cid");
					this.cryptedId = null;
				}else{
					// new object format
					this.cryptedId = item.id;		
					let now = new Date();
					if(now.getTime() > item.expire){
					// time expired
						this.log("Time is expired", 'red');
						localStorage.removeItem("cid");
						this.cryptedId = null;
					}
				}			
			}else{
				this.cryptedId = null;
			}
			if(this.cryptedId){
				this.log("Crypted id found: " + this.cryptedId, "title");
				this.log("Auto relogging user", 'low');
				this.operations.login_request = true;
				this.continuePreviousOperation();
			}else{
				this.log("No user id found in LocalStorage / Time expired", "low");
			}
		},
		


		displayLocalStorageContent(){
			this.log("localStorage", 'sep');
			for (let i = 0; i < localStorage.length; i++) {
				const key = localStorage.key(i);
				const value = localStorage.getItem(key);
				this.log(`${key}: ${value}`);
			}
			this.log("END localStorage", 'sep');
		},




		collectCodeToSendData(){
			/* Collection if user is logged works */
			let data = {
				id: this.fieldsValues.CRMParticipationOwnerId,
				code: "" + this.fieldsValues.code,
				phoneNumber1: this.fieldsValues.phoneNumber1,
			};

			if(!data.id && !!this.userInfos.id){
				data.id = this.userInfos.id;
			}
			if(!data.phoneNumber1){
				data.phoneNumber1 = this.userInfos.phoneNumber1;
			}
			return data;
		},

		checkIfOneProductIsSelected(){
			let thereIsAtLeastOneSelectedProduct = false;
			this.wowicards.forEach(item=>{
				if (item.selected){
					thereIsAtLeastOneSelectedProduct = true;
				}
			});
			return thereIsAtLeastOneSelectedProduct;
		},

		tryToPrintCoupons(){
			this.operations.printCoupons_request = true;
			this.goto('warningBeforePrint');		
		},

		tryToReceivePrimes(){
			this.log("tryToReceivePrimes()", 'function');
			this.operations.primes_request = true;
			this.operations.userAskedPrimeOrder = true;
			this.codeForParticipation = "PROMOCODES";
			this.userAsksToReceivePrimes();			
		},

		tryToReceiveCoupons(){
			this.log("tryToReceiveCoupons()", 'function');

			pixie('event', 'Recevoir_coupon');
			fbq('trackCustom', 'RecevoirCoupons');

			this.operations.mailCoupons_request = true;
			this.userAsksToReceiveCoupons();
			// run script for Pixels
			try{
				gtag_report_conversion("https://www.wowideals.be");
			}catch(e){
				console.log(e);
			}
		},

		modifyMyProfile(){
			/* Close menu if open */
			this.setMenuVisibility(false);
			/* Reset value to avoid allowing to print coupons */
			this.operations.printCoupons_request = false;
			this.operations.mailCoupons_request = false;
			this.fieldsValues.password = undefined;
			this.goto('profile');
		},

		displayErrorNoCouponsOrdered(){
			this.log("There are no coupon to be ordered", "alert");
			this.refreshDataAndGoToOffersPage();
			this.errorModaleTitle = "Error";
			this.errorModaleMessage = this.$t('errors.noCouponsToOrder');
			this.turnThisModaleVisibility('error', true);
		},

		loggedUserTryToPrintCoupons(){
			this.removeAlreadyClaimedOffersFromUserOrder();
			/* ! I should remove those available no more too! */
			/* Case: Monday, I select all coupons. Thuesday, half coupons are sold out. Wednesday, I send my order… */
			/* ? are those coupons still in the selected list? */
			if(!this.isThereAtLeastOneSelectedProduct){
				this.displayErrorNoCouponsOrdered();
			}else{
				if(!this.userJustRegistered){
					// TODO run check device + param
					this.requestInfoAboutDevice();
					// => continuepreviousoperation
				}
				if(this.userJustRegistered){
					// no need to request code, send participation directly
					this.requestToSendCouponsAccepted();
				}
			}
		},

		/* User cancel the printing */
		userCancelPrintingCoupons(){
			this.operations.printCoupons_request = false;
			this.refreshDataAndGoToOffersPage();
		},
		

		/* User just clicked on the "Print coupons now" button on the wowi page… */
		userAsksToPrintCoupons(){
			if(this.userIsLogged){
				this.log("User is logged", 'green');
				this.loggedUserTryToPrintCoupons();
			}else{
				this.log("User is not logged", 'red');
				this.requireUserToLog();
			}		
		},

		displayErrorNoPrimeOrdered(){
			this.log("displayErrorNoPrimeOrdered", 'todo');
		},

		loggedUserTryToReceivePrimes(){
			// this.removeAlreadyClaimedOffersFromUserOrder();
			if(this.isThereAtLeastOneSelectedProduct){
				this.loggedUserTryToReceivePrimesWithSelectedProduct();
			}else{
				this.displayErrorNoPrimeOrdered();
			}
		},

		loggedUserTryToReceivePrimesWithSelectedProduct(){
			this.log("loggedUserTryToReceivePrimesWithSelectedProduct", 'function');
			if(this.userJustRegistered){
				this.requestToSendPrimesAccepted();
			}else{
				this.requestInfoAboutDevice();
			}
		},


		loggedUserTryToReceiveCoupons(){
			this.removeAlreadyClaimedOffersFromUserOrder();
			if(!this.isThereAtLeastOneSelectedProduct){
				this.displayErrorNoCouponsOrdered();
			}else{
				if(!this.userJustRegistered){
					// TODO run check device + param
					this.requestInfoAboutDevice();
					// this.sendRequestForValidationCode('email-and-print');
				}
				if(this.userJustRegistered){
					// no need to request code, send participation directly
					this.requestToSendCouponsAccepted();
				}
			}
		},

		/* User clicked on the "Receive coupons by mail" button on the wowi page… */
		userAsksToReceiveCoupons(){
			if(this.userIsLogged){
				this.log("User is logged", 'green');
				this.loggedUserTryToReceiveCoupons();					
			}else{
				this.log("User is not logged", 'red');
				this.requireUserToLog();
			}
		},

		userAsksToReceivePrimes(){
			if(this.userIsLogged){
				this.log("User is logged", 'green');
				this.loggedUserTryToReceivePrimes();					
			}else{
				this.log("User is not logged", 'red');
				this.requireUserToLog();
			}
		},


		updateLanguage(){
			// default values.
			let idLangNumber = 1;
			let idCountryNumber = 1;
			if (!!this.userInfos.idLanguageCode && !!this.userInfos.idCountryCode){
				idLangNumber = parseInt(this.userInfos.idLanguageCode);
				idCountryNumber = parseInt(this.userInfos.idCountryCode);
			}
			let countryItems = this.localizingData.filter(item =>{
				return parseInt(item.countrycodereference.id) === idCountryNumber;
			});
			let languageItems = this.localizingData.filter(item =>{
				return parseInt(item.languagecodereference.id) === idLangNumber;
			});
			let localeSuffix = countryItems[0].countrycodereference.code;
			let localePrefix = languageItems[0].languagecodereference.code;
			let finaleLocale = localePrefix.toLowerCase() + "_" + localeSuffix;
			this.log(`The i18n Locale is now: ${this.$i18n.locale}. Form will change it to: ${finaleLocale}`, 'low');
			this.$i18n.locale = finaleLocale;
			this.$store.dispatch('setIdLanguageCode', {id:idLangNumber}); // If for a reason user change his speaking language
			this.$store.dispatch('setLocale', {locale: finaleLocale});
		},


		removeAlreadyClaimedOffersFromWowiPage(){
			this.log("The function removeAlreadyClaimedOffersFromWowiPage is NOT obsolete", 'alert');
			/*
			this.log("removeAlreadyClaimedOffersFromWowiPage", 'function');
			// This should be enough to refresh the computed values by updating this.consumedCards
			this.getListOfAlreadyConsumedCoupons();
			*/
		},

		removeAlreadyClaimedOffersFromUserOrder(){
			if(this.consumedCards.length > 0){
				// Obtains IDs stored in fieldsValues.wowicards
				let keys = Object.keys(this.fieldsValues.wowicards);
				for (let key of keys){
					this.log(`Testing with key value: ${key}`, 'low');
					if (this.consumedCards.includes(parseInt(key))){
						this.log(`Unselecting already consumed item ${key}.`, 'low');
						this.fieldsValues.wowicards[key] = false;
					}
				}
			}
		},

		setCouponsCardsToUnselected(){
			this.log("Emptying coupons selection", 'low');
			this.wowicards.forEach(item => {
				this.fieldsValues.wowicards[item.id] = false;
			});
			this.sortedLiveWowiCards.forEach(item => item.selected = false);
		},

		setCashbacksCardsToUnselected(){
			this.log("Emptying cashbacks selection", 'low');
			this.selectedCashbacks = [];
		},

		cleanBehindLoggedUser(){
			this.userIsLogged = false;
			this.clearLocalStorage();
			this.emptyFieldsValueAfterLogout();
			this.setCouponsCardsToUnselected();
			this.setCashbacksCardsToUnselected();
		},

		userWantToLogout(){
			this.log("userWantToLogout", "low");
			this.displaySpinner(true);
			this.cleanBehindLoggedUser();

			// If user just deleted his account, special redirection.
			if(this.userJustDeletedHisAccount){
				this.userJustDeletedHisAccount = false; // just in case he want to re-register…
				this.currentPage = "confirmDelete";
			}else if(this.dateStatus === 'actionEnded'){
				this.currentPage = "actionEnded";
			}else{
				this.refreshDataAndGoToOffersPage();
			}

			this.displaySpinner(false);
		},

		userSubmitANewPassword(){
			this.log("userSubmitANewPassword()", 'function');
			this.operations.userChangingPassword_request = false;
			this.operations.resetPassword_request = true;
			this.sendRequestForValidationCode('password-changed');
		},

		dispatchDataToFields(){
			['firstName', 'lastName', 'email', 'phoneNumber1', 'postalCode', 'postal-code', 'optin', 'city', 'idCountryCode', 'crmpostalcodeId'].forEach(item => {
				if (item === 'optin'){
					this.fieldsValues[item] = parseInt(this.userInfos[item]);
				}else{
					this.fieldsValues[item] = this.userInfos[item];
				}
			});
			this.log("dispatchDataToFields()", "sep");
			this.log(this.fieldsValues);
		},


		displayModaleMessages(title, arrayMessages, type){
			this.log(`Message of type ${type}: ${title}`, 'title');
			this.log(arrayMessages);
			this.log("-".repeat(60));

			if(!title){
				title = this.$t('errors.error');
			}
			this.errorModaleTitle = title; 
			let html = "";
			arrayMessages.forEach(msg => {
				html += `<li>${msg}</li>`;
			})
			this.errorModaleMessage = html;
			this.turnThisModaleVisibility('error', true);
		},


		emptyUserData(){
			this.listOfReservedCashbacks = [];
			this.listOfConsumedCashbacks = [];
			this.listOfAllCashbacksStatus = [];
			this.consumedCards = [];
		},

		getNewUserData(){
			if(this.website === 'cashbacks'){
				// empty selected cashbacks
				this.selectedCashbacks = [];
				/* Refresh cashback data */
				// this.getAllCashbacksInfo(); // ! it's called in the wowiflow too !
			}
		},

		refreshDataAndGoToOffersPage(dest){
			this.log("refreshDataAndGoToOffersPage", 'function');
			fbq('trackCustom', 'RetourAuxOffres');
			
			// emptying list of previously reserved items
			this.justReserved = [];

			// In any case, when user interact with this menu 

			/* Reset flags to avoid continuing a previous action */
			this.operations.printCoupons_request = false;
			this.operations.mailCoupons_request = false;
			this.operations.userAskedCashbacksReservation = false;
			this.operations.userAskedCashbacksOrder = false;
			this.operations.sendingCashbackInProgress = false;
			this.operations.sendingPrimesInProgress = false;

			// If registration is not done, consequences (not effective in cashback mode)
			if(this.website === 'ecoupons'){
				let isRegistrationFinished = localStorage.getItem("registered");
				this.log(`Is registration finished? ${isRegistrationFinished}`, 'low');
				if(isRegistrationFinished !== "true"){
					this.clearLocalStorage();
				}
			}

			// In case user is logged
			if(this.userIsLogged){
				this.log("User is logged", 'low');
				this.getNewUserData();
				if(this.cryptedId){
					// operation: refresh user data
					this.reloadOffersPage = true;
					this.operations.refreshUserData_request = true;
					this.continuePreviousOperation();
				}else{
					this.log("User is logged BUT no crypted id available", "alert");
				}
			}

			// In case user is not/unlogged
			if(!this.userIsLogged){
				this.log("User is not logged", 'low');
				this.emptyUserData();
			}

			/* ! Pixie */
			pixie('event', 'retour_aux_offres');
			pixie('event', 'LandingPage');
			/* ! END Pixie */


			// Use the right redirection
			if(dest === 'offers'){
				this.goto('wowi');	
			}else{
				this.forceBackToPortail();
			}

			this.displaySpinner(false);
		},

		removeActiveStatusOfConsumedCards(){
			this.log('removeActiveStatusOfConsumedCards', 'function');
			this.log('This should removel all card selection', 'low');
			this.consumedCards.forEach(item => {
				// update the "selected" list
				this.fieldsValues.wowicards[item] = false;
			});
			this.log('Cards: selected status', 'group');
			this.log(this.fieldsValues.wowicards, 'table');
			this.log("", 'groupEnd');
		},

		userTryToReachThePageModifProfile(){
			this.log('userTryToReachThePageModifProfile', 'function');
			this.log('Unnecessary "step function"', 'red');
			this.goto('modifProfile');
		},

		resetAndContinue(){
			this.methodGetOwnerDetailsIsDone = false;
			this.operations.refreshUserData_request = false;
			// We can both close them now that they are checked already (in checkIfRefreshDataMethodsAreBothDone())
			this.methodGetListOfAlreadyConsumedCouponsIsDone = false;
			this.methodGetListOfAlreadyConsumedPrimesIsDone = false;
			this.methodGetListOfAlreadyConsumedCashbacksIsDone = false;
			this.methodGetListOfAlreadyReservedCashbacksIsDone = false;
			//
			this.closeAllModales();
			this.goto('wowi');
			this.continuePreviousOperation();
		},


		isChromiumBased() {
			const userAgent = navigator.userAgent;
			const isChrome = /Chrome/.test(userAgent) && /Google Inc/.test(navigator.vendor);
			const isEdge = /Edg/.test(userAgent);
			const isOpera = /OPR/.test(userAgent);
			const isBrave = !!window.navigator.brave;

			return isChrome || isEdge || isOpera || isBrave;
		},

		hasRestrictedPrintInIframe() {
			this.log("hasRestrictedPrintInIframe", 'function');
			try {
				const testiframe = document.createElement('iframe');
				document.body.appendChild(testiframe);
				testiframe.style.display = 'none';
                const printFunction = testiframe.contentWindow.print; // It exists ! But it cannot be triggered, I think…
                document.body.removeChild(testiframe);
                this.log(`Type de printFunction: ${typeof printFunction}`);
                return typeof printFunction !== 'function';
            } catch (e) {
            	return true;
            }
        },

        detectIfMobile(){
        	return /Mobi|Android/i.test(navigator.userAgent); 
        },



		/* CHECK IF USER IS ABLE TO PRINT COUPON */
        isUserAbleToPrintCoupon(){
			/* I must test three things */
        	let deviceIsChromiumBased = this.isChromiumBased();
        	let isMobileDevice = this.detectIfMobile();
        	let isIframePrintRestricted = this.hasRestrictedPrintInIframe();

        	if(deviceIsChromiumBased){
        		this.log("Browser is based on Chromium", 'red');
        	}else{
        		this.log("Browser not based on Chromium", 'green');
        	}

        	if(isMobileDevice){
        		this.log("User is on mobile device", 'red');
        	}else{
        		this.log("No mobile device found", 'green');
        	}

        	if(isIframePrintRestricted){
        		this.log("Restriction on iframe.print() function detected", 'red');
        	}else{
        		this.log("No restriction on iframe.print() function detected", 'green');
        	}

        	if( (deviceIsChromiumBased && isMobileDevice) || isIframePrintRestricted ){
        		this.log("Coupon not printable");
        		return false;
        	}
        	return true;
        },

		/* REDIRECTION IF USER CAN PRINT HIS COUPON OR NOT */
        redirectionForPrintingCoupon(){
			/* Before I can go to printcoupon, I must check if user is ABLE to print coupon (browser restriction or chromium mobile) */
        	if(this.isUserAbleToPrintCoupon()){
        		this.goto('printcoupon');
        	}else{
        		this.goto('cannotPrintCoupon');
        	}
        },


        checkIfRefreshDataMethodsAreBothDone(){
        	this.log("checkIfRefreshDataMethodsAreBothDone", 'function');
			// I have the user data, I have the list of already consumed coupons OR the list of reserved/consumed cashbacks
			// If there is an ope "printCoupon" in the url (stored in currentOpe)
        	if(this.currentOpe === "printCoupon" && this.requestedPage === "1"){
        		this.log("Coupon Printing requested", 'title');
        		this.redirectionForPrintingCoupon();
        	} else 
			// USER + PORTAIL
        	if (this.methodGetOwnerDetailsIsDone && this.website === "portail"){
        		this.log("Both refresh user data methods are done, continue…", 'success');
        		this.resetAndContinue();
        	} else
			// USER + PRIMES
        	if (this.methodGetOwnerDetailsIsDone && this.methodGetListOfAlreadyConsumedPrimesIsDone){
        		this.log("Both refresh user data methods are done, continue…", 'success');
        		this.resetAndContinue();
        	} else
			// USER + COUPONS
        	if (this.methodGetOwnerDetailsIsDone && this.methodGetListOfAlreadyConsumedCouponsIsDone){
        		this.log("Both refresh user data methods are done, continue…", 'success');
        		this.resetAndContinue();
        	} else
			// USER + CASHBACKS
        	if (this.methodGetOwnerDetailsIsDone && this.methodGetListOfAlreadyAllCashbacksIsDone){
        		this.log("both user details AND consumed cashbacks AND reserved cashbacks are known, continue…", 'wip');
        		this.log("Continue wowilogic > checkIfRefreshDataMethodsAreBothDone() for cashbacks", 'todo');

			// TODO USER RESERVED/ORDERED THEN LOGGED
			// TODO must clean the data then continue ordering/reserving

        		if( this.operations.userAskedCashbacksOrder ){
        			this.removeConsumedCashbacksFromUserSelection();
        		}else if( this.operations.userAskedCashbacksReservation ){
        			this.removeReservedCashbacksFromUserSelection();
        		}
        		this.resetAndContinue();
        	}
        },


        updateConsent(){
        	this.refreshUserData_request = true;
        	this.updateCredentials();
        },


        resetBothCashbackDataAndParticipationRefsFlags(){
        	this.cashbackDataBeforeParticipationAreReceived = false;
        	this.participRefsBeforeParticipationAreReceived = false;
        },

        prepareCashbackParticipation(){
        	this.log("prepareCashbackParticipation", 'function');
			// must run two API calls

			// Reset flags
        	this.resetBothCashbackDataAndParticipationRefsFlags();

        	this.getUsedCashbacksBeforeParticipation();
        	this.getParticipationTypeReferenceBeforeParticipation();
			// next step is in checkIfDataBeforParticipationAreBothArrived()
        },

        checkIfDataBeforParticipationAreBothArrived(){
        	if(this.cashbackDataBeforeParticipationAreReceived && this.participRefsBeforeParticipationAreReceived){
				/* Reset so I will not send participation right away next time! */
        		this.resetBothCashbackDataAndParticipationRefsFlags();
				/* Both API responded, I can proceed… */
        		this.cleanUsedCashbackBeforeSendingParticipation();
        	}
        },

        cleanUsedCashbackBeforeSendingParticipation(){
        	this.log("cleanUsedCashbackBeforeSendingParticipation", 'function');
			// this.participRefs is available for code ideification
			/* as well as the three above
			this.listOfAllCashbacksStatus
			this.listOfConsumedCashbacks
			this.listOfReservedCashbacks
			*/

        	this.log("The user selection before clean contains: ");
        	this.log(this.selectedCashbacks, 'table');

        	if(this.codeForParticipation === "PART"){
        		this.log("Remove CONSUMED cards form order", 'low');
        		this.removeConsumedCashbacksFromUserSelection();
        	}
        	if(this.codeForParticipation === "RESERVAT"){
        		this.log("Remove CONSUMED and RESERVED cards from order", 'low');
        		this.removeConsumedCashbacksFromUserSelection();
        		this.removeReservedCashbacksFromUserSelection();
        	}
        	if(this.codeForParticipation === "REPART"){
				// TODO clarify : In a reparticipation, user MUST send consumed cashback but COULD add new ones (maybe) ?
        	}
        	if(this.codeForParticipation === "HCDCPRINT"){
				// In theory, not possible in this action, but just in case of futur use…
        	}
			// No "else" possible
        	this.checkSelectionBeforeParticipation();
        },

        checkSelectionBeforeParticipation(){
        	this.log("checkSelectionBeforeParticipation", 'function');
        	if (this.selectedCashbacks.length > 0){
        		this.log("Envoi de la participation", 'step');
        		this.requestToSendFormParticipationAccepted();	
        	}else{
        		this.log("Redirection vers page commande empty", 'low');
        		this.backToTop();
        		this.turnThisModaleVisibility('emptyOrder', true);
				// TODO what did I meant by that?
        		this.log("Why is the participation still SENT!? (old code?)", 'alert');
        	}
        },


	}, // end of methods









/*

WATCH

*/

	watch: {
		'$store.getters.getLocale': function(newLocale, oldLocale){
			this.log(`${oldLocale} --> ${newLocale}`, 'low');
			if (this.website === 'ecoupons'){
				this.requestCouponsDetails();
			}
			if (this.website === 'primes'){
				// this.requestPrimesDetails();
			}

		},
		'fieldsValues.email': function(){
			this.loginErrorIsVisible = false;
		}, 
		'fieldsValues.password': function(){
			this.loginErrorIsVisible = false;
		}, 


	},

}
