<template>
	<div id="layoutGen">

		<!-- HEADER-PAGE: full width top header -->
		<div id="header-page"></div>

		<!-- =============== FORMWRAPPER: full width, stretching block =============== -->
		<div id="formWrapper">

			<!-- CONTENTCOLUMN: The central Column -->
			<div id="contentColumn">

				<!-- HEADER: same width as the central column -->
				<div id="header-form" role="banner"></div>

				<!-- LANG -->
				<!-- inHeader="true" the lang buttons are placed in the top right inside the header -->
				<!-- inHeader="false" the lang buttons are placed outside and below the header (bottom right) -->
				<lang-switcher :inHeader="true" role="region"></lang-switcher>

				<!-- ========== FORMPAPER: all the pages ========== -->
				<div id="formPaper" role="main">
					

					<router-view v-slot="{ Component }">
						<keep-alive>
							<component :is="Component" />
						</keep-alive>
					</router-view>

					
				</div>

				<!-- ========== FOOTER-FORM: width of the central column ========== -->
				<div id="footer-form"></div>
				<!-- ========== // ========== -->


			</div> <!-- End of #contentColumn -->
		</div> <!-- End of #formWrapper-->


		<!-- FOOTER-PAGE: full width bottom footer -->
		<div id="footer-page" role="contentinfo">
			<classic-footer></classic-footer>
		</div>

	</div> <!-- End of #layoutGen -->








	<!-- :client="$t('gdpr.client')" -->
	<gdpr
	role="alertdialog"
	aria-label="GDPR"
	client="HighCo Data Benelux"
	:client-adress="leadGDPR.gdprstreet?.trim() + ' ' + leadGDPR.gdprstreetnum?.trim() + ', ' + leadGDPR.gdprcountry?.trim() + '-' + leadGDPR.gdprcp?.trim() + ' ' + leadGDPR.gdprloc?.trim()"
	contact-phone=""
	:contact-fname="leadGDPR.gdprfname?.trim()"
	:contact-name="	leadGDPR.gdprname?.trim()"
	:contact-email="leadGDPR.gdprmail?.trim()"
	></gdpr>

</template>




<script>

	/* ======== COMPONENTS ======== */
	// import AppNav from '@/components/AppNav'; /* navigation between pages, not inside form */
	import GDPR from '@/components/GDPR.vue';
	import LangSwitcher from '@/components/LangSwitcher.vue';
	import ClassicFooter from '@/components/ClassicFooter.vue';

	/* ======== MIXINS ======== */
	import pixel from "./mixins/pixel";
	// import ga from "./mixins/ga";
	import cf from "./mixins/cf";
	import mtm from "./mixins/mtm";
	import openPDF from "./mixins/openpdf";
	import customLog from "./mixins/CustomLog";
	import NonAutomatedData from "./mixins/NonAutomatedData";

	/* ======== OTHERS ======== */
	import config from "./config";


	export default {
		name: 'App',
		
		data() {
			return {
				idLead : config.idlead,
				leadGDPR : {},
				isGDPRVisible : true,
				defaultLocale : "nl_BE",
				isThereLangInTheUrl: false,
				possibleLocales : [
					"fr_be", "nl_be", "fr_lu", "de_de", "en_be", 
					"en_uk", "fr_fr", "nl_nl", "fr_ch", "de_ch", 
					"de_lu", "it_it", "es_es", "pt_pt"],

				/* isPnGAction: false /* ! TRUE if this is a PNG actions */
			}
		},
		
		components: {
			LangSwitcher,
			ClassicFooter,
			'gdpr': GDPR,
		},
		
		mixins: [
			// ga,
			pixel,
			cf,
			mtm,
			customLog,
			openPDF,
			NonAutomatedData,
			],


		methods: {

			/* * Not used yet
			isFooterForCoupon(){
				// Footer must be hidden when user comes to print it's coupon
				let hide = false;
				let urlParams = new URLSearchParams(window.location.search);
				if(urlParams.has('ope')){
					let safeOpe = encodeURIComponent(urlParams.get('locale'));
					if (safeOpe === "printCoupon"){
						hide = true;
					}
				}
				return hide;
			},
			*/

			getLocaleValidatedOrDefault(locale) {
				this.log("Regex testing", "low");
				const parts = locale.toLowerCase().split('_');
				const regex = /^[a-z]{2}$/;
				if (parts.length !== 2 || !regex.test(parts[0]) || !regex.test(parts[1])) {
					this.log(`This locale "${locale}" has a incorrect format`, "red");
					return this.defaultLocale;
				}else if(!this.possibleLocales.includes(locale.toLowerCase())) {
					this.log(`The locale "${locale}" is unknown or unauthorized`, "red");
					return this.defaultLocale;
				}else{
					this.log(`This locale "${locale}" has been validated`,"green");
					this.isThereLangInTheUrl = true;
					return parts[0] + '_' + parts[1].toUpperCase();
				}
			},

			getLangFromUrl () {
				this.log("Checking URL for locale or lang", "sep");
				this.$i18n.locale = this.defaultLocale;

				let urlParams = new URLSearchParams(window.location.search);

				if (urlParams.has('locale')){
					this.log("Getting locale from url", 'low');
					let unsafeLocale = urlParams.get('locale');
					let locale = encodeURIComponent(unsafeLocale);
					this.$i18n.locale = this.getLocaleValidatedOrDefault(locale);
				}
				
				if (urlParams.has('lang')){
					this.log("Getting lang from url", 'low');
					let unsafeLang = urlParams.get('lang');
					let lang = encodeURIComponent(unsafeLang);
					if (lang.toLowerCase() === "fr" || lang.toLowerCase() === "fr_be" || lang.toLowerCase() === "be_fr"){
						console.log(`Lang was detected in the url, translating ${lang} to fr_BE`);
						this.$i18n.locale = "fr_BE";
					}else{
						console.log(`Lang was detected in the url, translating ${lang} to nl_BE`);
						this.$i18n.locale = "nl_BE";
					}
					this.isThereLangInTheUrl = true;
				}
			},

			getGDPRData(idLead){
				this.log("Getting Leads for " + idLead, 'api');
				this.axios.get(
					`/leads/${this.idLead}`
					)
				.then(res => {
					this.log("I managed to get the lead for the GDPR", 'success');
					this.iGotTheGDPRLead(res);
				})
				.catch(error => {
					this.log("I cannot get the lead for the GDPR", 'alert');
					this.iCannottGetTheGDPRLead(error);
				})
				.then(()=>{
				});
			},

			iGotTheGDPRLead(res){
				this.log(res.data);
				this.leadGDPR = res.data;
			},

			iCannottGetTheGDPRLead(error){
				console.log(error.response.data.errors);
				// this.displayMessage(error.response.data.errors, 'alert');
				/* ? probably a redirection here is better : to show a adequat message. */
			},

			getLangFromBrowser(){
			// ! It must be xx_BE as I don't have any of the local available for this action (no LEAD loaded yet).
				let browserLocale = window.navigator.language;
				let locale = browserLocale.slice(0,2) === 'fr' ? 'fr_BE' : 'nl_BE';
				this.log(`The browser locale is: ${browserLocale}. It is passed to ${locale} for the form.`, 'info');
				this.$i18n.locale = locale;
				this.$store.dispatch('setLocale', locale);
			},

			getLangToUse(){
				this.getLangFromUrl();
				if(!this.isThereLangInTheUrl){
					this.getLangFromBrowser();
				}		
			},

			setHtmlLangAttribute(){
				/* Set it at load. The lang buttons will change it later. */
				/* Sadly, the locale is not a valid language for Axe DevTool. So a lang it must be */
				let targetTag = document.querySelector("html");
				this.log(`setHtmlLangAttribute`, 'wip');
				targetTag.lang = this.$i18n.locale.slice(0,2);
			},

		},
		

		beforeMount(){
			this.log("APP.VUE", "title");
			this.getLangToUse();
			// when I got the lang, I set the html attribute
			this.setHtmlLangAttribute();
			this.$store.dispatch('setLeadId', this.idLead);
			this.log(`STORE SHOULD HAVE IDLEAD ${this.idLead}`, 'green');
			this.getGDPRData(this.idLead);
		},

		mounted(){
			// Added for vue devtool to work — don't remove
			window.postMessage({ devtoolsEnabled: true, vueDetected: true });
		}
	}

</script>


<style lang="scss">
</style>



