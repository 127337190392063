import TestCouponDetails from "./APITesters/TestCouponDetails";
import TestAllowedCountries from "./APITesters/TestAllowedCountries";

export default {

	mixins: [
		TestCouponDetails,
		TestAllowedCountries,
		],
	
	data () {
		return {
			callConfigs: {
				/* ======== GET REQUESTS ======== */
				getFingerprint: {
					title: 'GET getFingerprint',
					url: `/fingerprint/${this.storedIdLead}`,
					cbSuccess: this.whatIfIGotTheFingerPrint,
					cbError: this.whatIfIDontGetTheFingerprint
				},
				getLocalizedData : {
					title: 'GET localizing data',
					url: `/dropdown/${this.storedIdLead}/languages-code`,
					cbSuccess: this.localizingDataFound, 
					cbError: this.localizingDataNotFound
				},
				getLead : {                    
					title: 'GET Lead',
					url: `/leads/${this.storedIdLead}`,
					cbSuccess: this.whatIfIGetTheLead, 
					cbError: this.whatIfIDontGetTheLead,
				},
				getProductsIds: {
					title: 'GET Products IDs',
					url: `/dropdown/${this.storedIdLead}/popitems`,
					cbSuccess: this.whatIfIFoundProductsIds, 
					cbError: this.whatIfICantFoundProductId
				},
				getParticipationOwnerData: {
					title: 'GET participationOwner',
					url: `/participationOwner/participation/`,
					cbSuccess: this.whatIfIGetTheParticipationOwnerData,
					cbError: this.whatIfIDontGetTheParticipationOwnerData
				},
				getOwnerData: {
					title: 'GET OwnerData',
					url: `/participationOwner/`,
					cbSuccess: this.whatIfIGetTheOwnerData, 
					cbError: this.whatIfIDontGetTheOwnerData
				},
				getMetasIds: {
					title: 'GET Fields',
					url: `/lead/${this.storedIdLead}/fields`,
					cbSuccess: this.whatIfIGetTheFields, 
					cbError: this.whatIfIDontGetTheFields
				},
				getCountries: {
					title: "GET allowed Countries",
					url: `/dropdown/${this.storedIdLead}/countries-allowed`,
					cbSuccess: this.whatIfIGetTheCountries,
					cbError: this.whatIfICantGetTheCountries
				},
				getRetailers: {
					title: "GET Retailers list",
					url: `/dropdown/${this.storedIdLead}/retailers`,
					cbSuccess: this.whatIfIGetTheRetailersList,
					cbError: this.whatIfIDontGetTheRetailersList
				},

				getCouponsDetails: {
					title: 'GET Coupons details II',
					url: `/dropdown/${this.storedIdLead}/leadcoupons`,
					cbSuccess: this.cb_couponDetailsAreFound, 
					cbError: this.cb_couponDetailsAreNotFound
				},

				getOrderedItemList: {
					title: 'GET Ordered Item List',
					url: `/dropdown/${this.storedIdLead}/ordereditems`,
					cbSuccess: this.cb_orderedItemFound,
					cbError: this.cb_orderedItemNotFound		
				},

				getSynonyms: {
					title: "GET Synonyms",
					url: `/dropdown/${this.storedIdLead}/items-synonym`,
					cbSuccess: this.whatIfIGetTheSynonymsList,
					cbError: this.whatIfICantGetTheSynonymsList
				},

				/* INFO "wowi" in the two url below was {CRMCode} (to identify witch CRM to use) */
				/* INFO This call must receive a parameter : crmPartOwnId */
				getReservedCashbacks: {
					title: "GET Reserved Cashbacks",
					url: `/crm/wowi/lead/${this.storedIdLead}/cashback/reserved/crmParticipationOwner/`,
					cbSuccess: this.cb_crmReservedCashbacksInfosFound,
					cbError: this.cb_crmReservedCashbacksInfosNotFound
				},

				getConsumedCashbacks: {
					title: "GET Consumed Cashbacks",
					url: `/crm/wowi/lead/${this.storedIdLead}/cashback/consumed/crmParticipationOwner/`,
					cbSuccess: this.cb_crmConsumedCashbacksInfosFound,
					cbError: this.cb_crmConsumedCashbacksInfosNotFound
				},
				/* Use this to refresh all at once */
				// crm/{CRMCode}/lead/{idLeadManagement}/cashback/{type}/crmParticipationOwner/{crmPartOwnId}
				getAllCashbacks: {
					title: "GET All Cashbacks",
					url: `/crm/wowi/lead/${this.storedIdLead}/cashback/all/crmParticipationOwner/`,
					cbSuccess: this.cb_crmAllCashbacksInfosFound,
					cbError: this.cb_crmAllCashbacksInfosNotFound
				},
				getAllCashbacksBeforeParticipation: {
					title: "GET All Cashbacks Before Participation",
					url: `/crm/wowi/lead/${this.storedIdLead}/cashback/all/crmParticipationOwner/`,
					cbSuccess: this.cb_crmAllCashbacksInfosFoundBeforeParticipation,
					cbError: this.cb_crmAllCashbacksInfosNotFoundBeforeParticipation
				},


				getUnsubscribeReferencesList: {
					title: "GET unsubscribe reference for dropdown",
					url: `/dropdown/unsubscribe-reason-reference`,
					cbSuccess: this.getUnsubscribeReferencesListPassed,
					cbError: this.getUnsubscribeReferencesListFailed
				},



				/* ======== POST REQUESTS ======== */
				postUnsubscribeUser: {
					title: 'POST unsubscribe user',
					url: `/crm/wowi/crmParticipationOwner/unsubscribe`,
					cbSuccess: this.cb_postUnsubscribeUserPassed,
					cbError: this.cb_postUnsubscribeUserFailed,
				},


				postParticipationOwner: {
					title: 'POST Participation Owner',
					url: `/participationOwner`,
					cbSuccess: this.successInCreateParticipationOwner,
					cbError: this.failedInCreateParticipationOwner
				},
				/* Minisite participation */
				postParticipation: {
					title: 'POST Participation',
					url: `participation`,
					cbSuccess: this.participationSuccessful,
					cbError: this.participationFailed,
				},
				
				postWowiParticipation: {
					title: 'POST WOWI Participation',
					url: `/crm/wowi/participation`,
					cbSuccess: this.wowiParticipationSuccessful,
					cbError: this.wowiParticipationFailed,
				},

				postRegistration: {
					title: 'POST Registration Owner',
					url: `/crm/wowi/register`,
					cbSuccess: this.cb_userHasBeenRegistered,
					cbError: this.cb_userHasNotBeenRegistered
				},



				/* Ecoupons */
				postTriggerMailSendForUser: {
					title: 'POST Trigger Mail',
					url: `coupons/send-email/print-link`,
					cbSuccess: this.mailAfterSurveyWasSent,
					cbError: this.mailAfterSurveyWasNotSent
				},
				postPrintCouponAndGetPdf: {
					title: 'POST Print Coupon',
					url: `coupons/print`,
					cbSuccess: this.printableCouponIsReceived,
					cbError: this.printableCouponIsNotReceived
				},



				postIsDeviceKnown:{
					// requires idCRMParticipationOwner as param
					title: 'POST is Device Known',
					url: `/crm/wowi/device/identified`,
					cbSuccess: this.cb_theDeviceAnswerIsOk,
					cbError: this.cb_theDeviceAnswerIsNotOk
				},

				postSendCodeByMean:{
					title: 'POST Send code by mail',
					url: `/crm/wowi/validation/send/code`,
					cbSuccess: this.cb_codeWasSentByMean,
					cbError: this.cb_codeWasNotSentByMean
				},

				postSendMailReplacePassword: {
					title: 'POST Ask mail to reset password',
					url: `/crm/wowi/forgot/password`,
					cbSuccess: this.cb_passwordReplacerMailWasSent,
					cbError: this.cb_passwordReplacerMailWasNotSent
				},

				// delete user
				getUserDeleted: {
					title: "Delete current user",
					url: `/crm/wowi/delete/crmParticipationOwner/`,
					cbSuccess: this.cb_userDeleted,
					cbError: this.cb_userNotDeleted
				},

				getPostalCodeDropdownList : {
					title: 'GET postal code dropdown list',
					url: `/dropdown/postal-code`,
					cbSuccess: this.cb_formGetThePostalCodeList,
					cbError: this.cb_formDontGetThePostalCodeList
				},

				
				/* WIP ========== START WIP 210 ========== */
				
				getAlreadyConsumedPrimes: {
					// Returns a list of id : ['110', '116']
					title: "GET already consumed Primes",
					url: `/crm/wowi/ecoupons/consumed/crmParticipationOwner/`,
					cbSuccess: this.cb_formGetTheConsumedPrimesList,
					cbError: this.cb_formDontGetTheConsumedPrimesList,	
				},
				
				/* WIP ========== END WIP 210 ========== */

				
				getAlreadyConsumedCoupons: {
					// Returns a list of objects : [{idleadcoupon: '110'}, {idleadcoupon: '116'}]
					title: "GET already consumed coupons",
					url: `/crm/wowi/leadcoupons/consumed/crmParticipationOwner/`,
					cbSuccess: this.cb_formGetTheConsumedCouponsList,
					cbError: this.cb_formDontGetTheConsumedCouponsList,	
				},


				getSpecificCRMOwner: {
					// ! BAD NAME 
					// Returns details about the user coupon availability : [{1202433: {already: 0, left: 1}},]
					title: 'GET one Owner details',
					url: `/crm/wowi/ecoupons/lead/${this.storedIdLead}/crmParticipationOwner/`, 
					cbSuccess: this.cb_specificCrmOwnerDetailsAreFound,
					cbError: this.cb_specificCrmOwnerDetailsAreNotFound
				},

				getParticipationTypeRef: {
					title: "GET Participation Type Ref.",
					url: `/dropdown/participation-type-reference`,
					cbSuccess: this.cb_participationTypeRefFound,
					cbError: this.cb_participationTypeRefNotFound
				},
				getParticipationTypeRefBeforeParticipation: {
					title: "GET Participation Type Ref. Before Participation",
					url: `/dropdown/participation-type-reference`,
					cbSuccess: this.cb_participationTypeRefFoundBeforeParticipation,
					cbError: this.cb_participationTypeRefNotFoundBeforeParticipation
				},


				getCRMOwnerDetails: {
					title: 'GET Owner details',
					url: `/crm/wowi/crmParticipationOwner/`,
					cbSuccess: this.cb_crmOwnerDetailsAreFound,
					cbError: this.cb_crmOwnerDetailsAreNotFound
				},

				postLoginAndPass: {
					title: 'POST Email and Password',
					url: `/crm/wowi/login`,
					cbSuccess: this.cb_loginAndPassAreRecognized,
					cbError: this.cb_loginAndPassAreNotRecognized,
				},

				postLoginAndPassByForm: {
					title: 'POST Email and Password',
					url: `/crm/wowi/login`,
					cbSuccess: this.cb_userIsLoggedByForm,
					cbError: this.cb_userIsNotLoggedByForm,
				},

				postSMSCode: {
					title: 'POST send identification code',
					url: `/crm/wowi/validate/sms/code`,
					cbSuccess: this.cb_codeFromUserIsAccepted,
					cbError: this.cb_codeFromUserIsNotAccepted,
				},

				postRequestSMSCode: {
					title: 'POST request identification code to be sent',
					url: `/texting/send/validation`, 
					cbSuccess: this.cb_codeRequestSent,
					cbError: this.cb_codeRequestNotSent,
				},

				putCredentials: {
					title: 'PUT Credentials',
					url: `/crm/wowi/update/credentials`,
					cbSuccess: this.cb_credentialsHaveBeenUpdated,
					cbError: this.cb_credentialsHaveNotBeenUpdated,
				},
			}
		}
	},


	computed: {
		storedIdLead: function(){
			return this.$store.getters.getLeadId;
		},
	},


	methods: {

		/* One function to call them all */
		getFromAxios(call, arg=''){
			this.log(`${call.title}`, 'api');

			if(!arg && arg !=''){
				this.log(`Argument sent to this method is falsy: ${arg}`, 'alert');
			}else if(arg === ''){
				this.log(`No argument was communicated.`, 'low');
			}else{
				this.log(`Argument value to add at the end of API call url: ${arg}`, 'low');
			}
			
			this.axios.get(call.url + arg)
			.then(res => {
				this.log(`API call "${call.title}" is a success`, 'success');
				this.log(res.data);
				call.cbSuccess(res.data);
			})
			.catch(error => {
				this.log(`Axios "${call.title}" function`, 'error');
				this.log(error);
				this.checkErrorCodeIfAny(call, error);			
			})
		},


		checkErrorCodeIfAny(call, error){
			console.log("-----------------------------------------");
			console.log(call);
			console.log(error);
			console.log("-----------------------------------------");
			if(error.response.status){
				switch(error.response?.status){
				case 422 : 
					this.displayModaleMessages(`${error.response.status} - ${error.response.statusText}`, ["Something wrong happened ☹️"], 'alert');
					this.displaySpinner(false); 
					break;
				case 503 :
					this.displayModaleMessages(`${error.response.status} - ${error.response.statusText}`, ["Maintenance in progress"], 'alert');
					this.displaySpinner(false); 
					this.currentPage = "maintenance";
					break;
				default:
					call.cbError(error);
				}
			}else{
				console.log("A statuless error has arised.");
				console.log(error.response);
			}

		},

		promisePostToAxios(request){
			this.log("Sending this information to actionAPI (2)", 'sep');
			this.log(request);

			return new Promise((resolve, reject)=>{
				this.axios.post(request.url, request.data)
				.then(res => {
					this.log(`Success in posting ${request.title} data`,'success');
					resolve(res.data);
				})
				.catch(error => {
					this.log(`Failed attempt to post for ${request.title}`, 'alert');
					reject(error);
				});
			});

		},


		postToAxios(call, data){
			this.log(`Sending this information to actionAPI (1)`, 'sep');
			this.log(data);
			this.axios.post(call.url, data)
			.then(res => {
				this.log(`Success in posting ${call.title} data`,'success');
				this.log(res);	
				call.cbSuccess(res);
			})
			.catch(error => {
				this.log(`Failed attempt to post for ${call.title}`, 'alert');
				console.log(error);
				call.cbError(error);
			})
		},

		postToAxiosSuffixed(call, suffix, data){
			this.log(`Sending this information to actionAPI: ${suffix}`);
			this.axios.post(call.url + '/' + suffix, data)
			.then(res => {
				this.log(`Success in posting ${call.title} data`,'success');
				this.log(res);	
				call.cbSuccess(res);
			})
			.catch(error => {
				this.log(`Failed attempt to post for ${call.title}`, 'alert');
				console.log(error);
				call.cbError(error);
			})
		},
		
		putIntoAxios(call, data){
			this.log(`Sending this information to actionAPI`);
			this.log(data);
			this.axios.put(call.url, data)
			.then(res => {
				this.log(`Success in putting ${call.title} data`,'success');
				this.log(res);	
				call.cbSuccess(res);
			})
			.catch(error => {
				this.log(`Failed attempt to put for ${call.title}`, 'alert');
				console.log(error);
				call.cbError(error);
			})
		},



	},
}