import store from "../store/store";
import axios from "axios";
import config from "@/config";

/**
 * @class BearerTokenManager
 * @classdesc
 * Get a bearer token object from Action api Laravel Passport OAuth server.
 **/
export default class BearerTokenManager {
	constructor() {
		this.token = null;
	}

	/**
	 * @function getNewTokenFromApi
	 * @description Get a bearer token from the server.
	 * @returns {Promise<Token>}
	 **/
	getNewTokenFromApi() {
		return axios.get(`${config.tokenUrl}/token/${config.refhd}`)
		.then((res) => {
			const token = res.data;
			store.commit("SET_BEARER_TOKEN", token);
			return token;
		}).catch((err) => {
			console.log(err);
			console.log("-----------------------------------------");
			// WIP this should solve the token authentification problem (maybe)
			// WIP Force reload to force new token
			let flag = localStorage.getItem('stopReload');
			if(flag){
				console.log("No more reload");
				localStorage.setItem('stopReload', false);
				console.log("-----------------------------------------");
			}else{
				console.log("One reload");
				console.log("-----------------------------------------");
				localStorage.setItem('stopReload', true);
				location.reload(true);
			}            
			// WIP END
		});
	}
}
