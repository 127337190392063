export default {
    /* Note: There are also utm got in the AxiosClass.js file. This is separated by convenience */

    data () {
        return {
            listOfAwaitedUtm: ['source', 'medium', 'campaign', 'id', 'term', 'content'],
        }
    },

    methods: {

        storeUTM(utmName, utmValue) {
            this.log("storeUTM", 'function');
            this.$store.dispatch("setUTM", {'name': utmName, 'value': utmValue});
            try{
                localStorage.setItem('utm_'+utmName, utmValue);
            }catch(e){
                console.log(e);
            }            
        },

        takeCareOfUtmInUrl(){
            let urlParams = new URLSearchParams(window.location.search);
            this.listOfAwaitedUtm.forEach(item => {
                if(urlParams.has('utm_' + item)){
                    this.log(`UTM found: ${item}`, 'low');
                    let unsafeUtm = urlParams.get('utm_'+item);
                    let safeUtm = encodeURIComponent(unsafeUtm);
                    this.storeUTM(item, safeUtm);
                }
            });
        },

    },
}
